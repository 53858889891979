import React, { useState, useEffect } from "react";
import { Card, Col, Row, Typography } from "antd";
import { InputField, ReactSelectField } from "../../../../atoms/FormElement";

const { Title, Text } = Typography;
var _ = require("lodash");

export default (props) => {
  const {
    control,
    errors,
    usageTypeApi,
    maritalStatusApi,
    employSectorApi,
    incomeApi,
    nationalityApi,
    countryApi,
    cityApi,
  } = props;

  return (
    <Card title={<Title level={4}>Tenant Details</Title>} bordered={false}>
      <Row gutter={24}>
        <Col span={8}>
          <ReactSelectField
            label="Tenant Usage Type"
            fieldname="type"
            iProps={{ placeholder: "Tenant Type" }}
            initValue=""
            isRequired={true}
            control={control}
            selectOption={_.map(usageTypeApi, (item) => ({
              value: item.id,
              label: item.name,
            }))}
            rules={{ required: "Tenent Type Required" }}
            validate={errors.type && "error"}
            validMessage={errors.type && errors.type.message}
          />
        </Col>
        <Col span={8}>
          <InputField
            label="Tenant Name"
            fieldname="first_name"
            iProps={{ placeholder: "Tenant Name" }}
            initValue=""
            isRequired={true}
            control={control}
            rules={{ required: "Tenant Name Required" }}
            validate={errors.first_name && "error"}
            validMessage={errors.first_name && errors.first_name.message}
          />
        </Col>
        <Col span={8}>
          <InputField
            label="Tenant Surname"
            fieldname="last_name"
            iProps={{ placeholder: "Tenant Surname" }}
            initValue=""
            isRequired={true}
            control={control}
            rules={{ required: "Tenant Surname Required" }}
            validate={errors.last_name && "error"}
            validMessage={errors.last_name && errors.last_name.message}
          />
        </Col>
        <Col span={8}>
          <ReactSelectField
            label="Marital Status"
            fieldname="marital_status"
            iProps={{ placeholder: "Select Category" }}
            isRequired={true}
            control={control}
            selectOption={_.map(maritalStatusApi, (item) => ({
              value: item.id,
              label: item.name,
            }))}
            rules={{ required: "Marital Status Required" }}
            validate={errors.marital_status && "error"}
            validMessage={
              errors.marital_status && errors.marital_status.message
            }
          />
        </Col>
        <Col span={8}>
          <ReactSelectField
            label="Employment Sector"
            fieldname="employment_sector"
            iProps={{ placeholder: "Employment Sector" }}
            initValue=""
            isRequired={true}
            control={control}
            rules={{ required: "Employment Sector Required" }}
            validate={errors.employment_sector && "error"}
            validMessage={
              errors.employment_sector && errors.employment_sector.message
            }
            selectOption={_.map(employSectorApi, (item) => ({
              value: item.id,
              label: item.name,
            }))}
          />
        </Col>
        <Col span={8}>
          <InputField
            label="Designation as Per Visa"
            fieldname="visa_designation"
            iProps={{ placeholder: "Designation as Per Visa" }}
            initValue=""
            isRequired={true}
            control={control}
            rules={{
              required: "Designation as Per Visa Required",
            }}
            validate={errors.visa_designation && "error"}
            validMessage={
              errors.visa_designation && errors.visa_designation.message
            }
          />
        </Col>
        <Col span={8}>
          <ReactSelectField
            label="Income"
            fieldname="income"
            iProps={{ placeholder: "Income" }}
            initValue=""
            isRequired={true}
            control={control}
            rules={{ required: "Income Required" }}
            validate={errors.income_tenant && "error"}
            validMessage={errors.income && errors.income.message}
            selectOption={_.map(incomeApi, (item) => ({
              value: item.id,
              label: item.name,
            }))}
          />
        </Col>
        <Col span={8}>
          <InputField
            label="Address"
            fieldname="address"
            iProps={{ placeholder: "Address" }}
            initValue=""
            isRequired={true}
            control={control}
            rules={{ required: "Address Required" }}
            validate={errors.address && "error"}
            validMessage={errors.address && errors.address.message}
          />
        </Col>
        <Col span={8}>
          <ReactSelectField
            label="Nationality"
            fieldname="nationality"
            iProps={{ defaultValue: "Nationality" }}
            initValue=""
            isRequired={true}
            control={control}
            rules={{ required: "Nationality Required" }}
            validate={errors.nationality && "error"}
            validMessage={errors.nationality && errors.nationality.message}
            selectOption={_.map(nationalityApi, (item) => ({
              value: item.id,
              label: item.name,
            }))}
          />
        </Col>
        <Col span={8}>
          <ReactSelectField
            label="Country"
            fieldname="country"
            iProps={{ defaultValue: "Country" }}
            initValue=""
            isRequired={true}
            control={control}
            rules={{ required: "Country Required" }}
            validate={errors.country && "error"}
            validMessage={errors.country && errors.country.message}
            selectOption={_.map(countryApi, (item) => ({
              value: item.id,
              label: item.name,
            }))}
          />
        </Col>
        <Col span={8}>
          <ReactSelectField
            label="City"
            fieldname="city"
            iProps={{ defaultValue: "City" }}
            initValue=""
            isRequired={true}
            control={control}
            rules={{ required: "City Required" }}
            validate={errors.city && "error"}
            validMessage={errors.city && errors.city.message}
            selectOption={_.map(cityApi, (item) => ({
              value: item.id,
              label: item.name,
            }))}
          />
        </Col>
        <Col span={8}>
          <InputField
            label="Mobile"
            isRequired={true}
            fieldname="contact_number"
            control={control}
            validate={errors.contact_number && "error"}
            validMessage={
              errors.contact_number && errors.contact_number.message
            }
            initValue=""
            rules={{
              minLength: {
                value: 9,
                message: "Please enter valid mobile number, 9 digits required",
              },
              maxLength: {
                value: 9,
                message: "Please enter valid mobile number, 9 digits required",
              },
              required: "Please enter Mobile No.",
            }}
            iProps={{
              size: "medium",
              placeholder: "12445678",
              addonBefore: "+971",
              type: "number",
            }}
          />
        </Col>
        <Col span={8}>
          <InputField
            label="Email Address"
            fieldname="email"
            iProps={{ placeholder: "Email Address" }}
            initValue=""
            isRequired={true}
            control={control}
            rules={{
              required: "Email Address Required",
              pattern: {
                value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                message: "Enter valid Email Address",
              },
            }}
            validate={errors.email && "error"}
            validMessage={errors.email && errors.email.message}
          />
        </Col>
        <Col span={8}>
          <InputField
            isRequired={false}
            label="Zip Code"
            fieldname="zipcode"
            iProps={{ type: "number", placeholder: "12345" }}
            initValue=""
            control={control}
          />
        </Col>
      </Row>
    </Card>
  );
};
