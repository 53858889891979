import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Form,
  Spin,
  Typography,
  Space
} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useForm, useWatch } from "react-hook-form";
import { DateField, SelectField } from "../../../../../atoms/FormElement";



const { Paragraph } = Typography;

const AssignTechnician = props => {


  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false);
  const {onBack } = props;
  const { control, handleSubmit, formState:{errors} } = useForm();

  const ticketTypeApi = []
  const ticketSubTypeApi = []
  const [ticketsType, setTicketType] = useState([]);
  const [ticketsSubType, setTicketSubType] = useState([]);

  useEffect(() => {
    // dispatch(getTicketType());
  }, [])

  useEffect(() => {
    ticketTypeApi && setTicketType(ticketTypeApi);
  }, [ticketTypeApi])


  const checktype: any = useWatch({ control, name: 'tickettype' })

  useEffect(() => {
   
  }, [checktype])

  useEffect(() => {
    ticketSubTypeApi && setTicketSubType(ticketSubTypeApi);
  }, [ticketSubTypeApi])

  const AssignProcess = () => {
    setLoading(true);
    setTimeout(() => {
      setError(true)
    }, 2000)
  }

  return (
    <>
      {
        loading ?
          (<>
            {!error ?
              (<Row gutter={[24, 24]} >
                <Col span={24}>
                  <Space size={24} className="w-100 text-center" direction="vertical">
                    <Spin size="large" className="ag-fontSize32" />
                    <Paragraph className="ag-default fontWeight600 mb-0">Assigning technician according to availability and speciality...</Paragraph>
                  </Space>
                </Col>
              </Row>)
              : (
                <Row gutter={[24, 24]} >
                  <Col span={24}>
                    <Space size={24} className="w-100 text-center" direction="vertical">
                      <ExclamationCircleOutlined className="ag-fontSize32 ag-error" />
                      <Paragraph className="ag-default fontWeight600 mb-0">There are no technicians available, try again later.</Paragraph>
                    </Space>
                  </Col>
                </Row>
              )}
            <Row gutter={24}>
              <Col span={24}><Button className="ag-gray-button-outline" onClick={onBack}>Back</Button></Col>
            </Row>
          </>)
          :
          (<Form
            layout="vertical"
            onFinish={handleSubmit(AssignProcess)}
          >
            <Row gutter={24}>
              <Col span={12}>
                <SelectField
                  isRequired={true}
                  fieldname="tickettype"
                  label="Ticket Type"
                  control={control}
                  iProps={{ placeholder: 'Select type' }}
                  rules={{
                    required: "Please select ticket type",
                  }}
                  initValue=""
                  selectOption={ticketsType && ticketsType.map(item => ({ value: item.ChildCaseCategory.id, label: item.ChildCaseCategory.name }))}
                  validate={errors.tickettype && "error"}
                  validMessage={errors.tickettype && errors.tickettype.message}
                />

              </Col>
              <Col span={12}>
                <SelectField
                  isRequired={true}
                  fieldname="ticketsubtype"
                  label="Ticket Sub-Type"
                  control={control}
                  iProps={{ placeholder: 'Select Sub-Type', isDisabled: !ticketsSubType.length }}
                  rules={{
                    required: "Please select ticket sub type",
                  }}
                  initValue=""
                  selectOption={ticketsSubType && ticketsSubType.map(item => ({ value: item.ChildCaseCategory.id, label: item.ChildCaseCategory.name }))}
                  validate={errors.ticketsubtype && "error"}
                  validMessage={errors.ticketsubtype && errors.ticketsubtype.message}
                />
              </Col>
              <Col span={12}>
                <DateField
                  isRequired={true}
                  fieldname="date"
                  label="Date"
                  control={control}
                  iProps={{ placeholder: "DD-MM-YYYY", format: "DD-MM-YYYY" }}
                  rules={{
                    required: "Please enter date",
                  }}
                  initValue=""
                  validate={errors.date && "error"}
                  validMessage={errors.date && errors.date.message}
                />
              </Col>
              <Col span={12}>
                <SelectField
                  isRequired={true}
                  fieldname="areazone"
                  label="Area Zone"
                  control={control}
                  iProps={{ placeholder: 'Select Area Zone' }}
                  rules={{
                    required: "Please select Area Zone",
                  }}
                  initValue=""
                  selectOption={[{ value: '0', label: 'Deira' }, { value: '1', label: 'Al-Bursha' }]}
                  validate={errors.areazone && "error"}
                  validMessage={errors.areazone && errors.areazone.message}
                />
              </Col>
            </Row>
            <Row gutter={24} justify="space-between">
              <Col>
                <Button className="ag-gray-button-outline" htmlType="button" onClick={onBack}>Back</Button>
              </Col>
              <Col>
                <Button className="ag-green-button" htmlType="submit" >Assign</Button>
              </Col>
            </Row>
          </Form>)
      }
    </>)
}

export default AssignTechnician;
