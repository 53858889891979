import * as action_types from "./constants";
import axios from "../../../../utils/axiosInceptor";
import { baseContractService } from "../../../../configs/constants";

export const getEjariList = (limit, page, value, field) => {
  return async (dispatch) => {
    const url = `${baseContractService}/get-all-ejari?limit=${limit}&page=${page}${
      field && `&${field}=${value}`
    }`;
    const response = await axios.get(url);
    dispatch({
      type: action_types.GET_EJARI,
      data: response?.data?.result,
    });
  };
};
