import React, { Fragment, useEffect, useState } from "react";

import {
  Col,
  Row,
  Button,
  Typography,
  Radio,
  Form,
  Table,
  message,
  Switch,
} from "antd";

import moment from "moment";
import { useFieldArray, useForm, useWatch } from "react-hook-form";
import { CheckCircleFilled, PlusOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";


var _ = require("lodash");
const { Text } = Typography;

import { CommonCard } from "../../components";
import AdditionalPayments from "../AdditionalPayments";
import {
  createBooking,
  getBookingDetail,
  setBookingForm,
  setFormState,
  updateBooking,
} from "../../ducks/actions";
import {
  ApprovalHeader,
  PendingHeader,
  amount_type,
  apiDateFormat,
  billing_charges,
  dateFormat,
  filterDropdownValue,
  payment_modes,
} from "../utils";
import { useParams } from "react-router";
import AddPlanComm from "./AddPlanComm";
import { Popup, PopupConfirm } from "../../../../atoms/Popup";
import { leaseService } from "../../../../../configs/constants";
import axios from "axios";
import RejectPopup from "../modalContent/rejectPopup";

const initcharge = {
  itemId: null,
  type: "",
  intallments: { label: 1, value: 1 },
  mode: {
    label: "Cheque",
    value: "cheque",
  },
  amount: 0,
};

const initPlan = {
  //Adding empty because the data is not consistent for Years 
};

export default (props) => {
  const { parking } = props;
  const { Title } = Typography;
  const [billingType, setBillingType] = useState([]);
  const [isDirty, setIsDirty] = useState(false); //startIndex for delete in billings
  const [enableSendForApproval, setEnableSendForApproval] = useState(true);
  const [modalvisible, setModalvisible] = useState(false);
  const [modalTrigger, setModalTrigger] = useState(0);

  const dispatch: any = useDispatch();

  // redux states
  const { bookingForm, bookingDetails } = useSelector(
    (state: any) => state.newBooking
  );

  const yearDifference = moment(bookingForm?.leaseTerms?.leaseEndDate).diff(moment(bookingForm?.leaseTerms?.leaseStartDate), 'years') + 1;

  const isBooking = Object.keys(bookingDetails).length > 0;

  const { leaseId = bookingDetails.leaseId } = useParams();

  const {
    control,
    formState: { errors, dirtyFields },
    handleSubmit,
    setValue,
    getValues,
    trigger
  } = useForm({
    defaultValues: {
      plans: [],
      othercharges: [],
    }
  });

  const {
    fields: otherChargesFields,
    append: otherChargesAppend,
    remove: otherChargesRemove,
  } = useFieldArray({
    control,
    name: "othercharges",
  });

  const {
    fields: plansFields,
    append: plansAppend,
    remove: plansRemove,
  } = useFieldArray({
    control,
    name: "plans",
  });

  const plansWatch = useWatch({ control, name: "plans" });

  useEffect(() => {
    if (bookingForm?.bookingTerms?.billingCharges?.length > 0) {
      const billingLines = [];
      bookingForm?.bookingTerms?.billingCharges?.map((item) => {
        let type = filterDropdownValue(item["billingChargesType"], billingType);
        const obj = {
          billingChargesType: { ...type, isDisabled: true },
          intallments: {
            label: item?.numberOfInstallments || 1,
            value: item?.numberOfInstallments || 1,
          },
          dueDate: dateFormat(item?.dueDate),
          modeOfPayment: filterDropdownValue(
            item?.modeOfPayment || "cheque",
            payment_modes
          ),
          actualAmount: item?.actualAmount,
          vat: item?.vat,
          amountType: filterDropdownValue(item?.amountType, amount_type),
          total: item?.total,
        };
        type && billingLines.push(obj);
      });

      billingLines.length > 0 && setValue("othercharges", billingLines);
    }
  }, [bookingForm, billingType]);


  useEffect(() => {
    let billingTypeTemp = [];
    if (billing_charges.length > 0) {
      billing_charges?.map((item, index) => {
        let isDisabledTemp = false;
        if (
          item.key == "rent_installment" ||
          //   item.key == "ejari_fee" ||
          item.key == "security_deposit"
        ) {
          isDisabledTemp = true;
        }
        // if (item.key != "base_rent_ic") {
        billingTypeTemp.push({
          label: item.name,
          value: item.key,
          isDisabled: isDisabledTemp,
        });
        // }
      });
      setBillingType(billingTypeTemp);
    }
  }, []);

  useEffect(() => {
    const bookingPlans = bookingForm?.commercialPaymentPlan || bookingForm?.paymentPlan;
    if (bookingPlans?.length > 0) {
      const finalPlans = bookingPlans.map(item => ({
        ...item,
        installments: { label: item?.installments, value: item?.installments },
        oldInstallments: item?.oldInstallments

      }));
      setValue("plans", finalPlans);
    }
  }, [bookingForm]);

  const onSubmit = async (data?: any, type?) => {
    let dataCopy = { ...(data || getValues()) };
    const selectedPlans = dataCopy?.plans?.length > 0 ?
      dataCopy?.plans.map(item => ({ ...item, installments: item?.installments?.value })) : [];
    const billingChargesArray = [];
    if (dataCopy?.othercharges?.length > 0) {
      dataCopy?.othercharges?.map((item, index) => {
        const total: any = item?.total;
        billingChargesArray.push({
          billingChargesType: item?.billingChargesType?.value,
          modeOfPayment: item?.modeOfPayment?.value,
          dueDate: apiDateFormat(item?.dueDate),
          numberOfInstallments: item?.intallments?.value || 1,
          amountType: item?.amountType?.value,
          actualAmount: parseFloat(item?.actualAmount),
          vat: parseFloat(item?.vat),
          total: parseFloat(total),
        });
      });
    }

    if (selectedPlans.length == 0 && type != "sendPlan") {
      message.info("Please add at least one payment plan to proceed");
      return;
    }

    let body = {
      commercialPaymentPlan: selectedPlans,
      bookingTerms: {
        ...bookingForm?.bookingTerms,
        billingCharges: billingChargesArray,
      },
    };


    let valid = true;
    if (!await trigger("othercharges", { shouldFocus: true }))
      valid = false;
    if (!await trigger("plans", { shouldFocus: true }))
      valid = false;

    if (valid == false)
      return;

    if (type === "next") {

      if (valid) {
        dispatch(setFormState());
        dispatch(setBookingForm(body));
      }
    } else {
      body = { ...bookingForm, ...body };

      props.parentState({ load: true });

      try {
        const response = await dispatch(
          !isBooking ? createBooking(body) : updateBooking({ leaseId, body })
        );

        // !isBooking && (
        type != "sendPlan" && await dispatch(getBookingDetail(leaseId))
        // );

        props.parentState({ load: false });
        message.success(response.data.message);
      } catch (error) {
        props.parentState({ load: false });
        message.error(
          error.response?.data?.error || error.response?.data?.message
        );
      }
    }
  };

  useEffect(() => {
    let bool = enableSendForApproval && dirtyFields['plans']?.length > 0 ? true : false;
    setIsDirty(bool)
  }, [dirtyFields['plans']]);

  const sendPaymentPlan = () => {
    const addPayments = getValues("plans");
    PopupConfirm({
      title: `Are you sure you want to send plans for approval?`,
      content: "",
      onOk: () => {
        if (addPayments?.length > 0) {
          const finalPayments = addPayments.filter(p => p?.annualRent && p?.annualRent > 0);
          sendPlanApi([...finalPayments], [...(bookingDetails?.paymentplan?.length ? bookingDetails?.paymentplan : [])]);
        }
      },
      okText: "Confirm",
    });
  }


  const sendPlanApi = async (newPlans, oldPlans) => {
    await onSubmit(null, "sendPlan"); //added fix for bug: 8194
    props.parentState({ load: true });
    // console.log("plans OBJ:", newPlans,oldPlans);
    const finalData = newPlans?.map(item => {
      let old = oldPlans.find(plan => plan.noOfYear == item.noOfYear);
      item = {
        ...item,
        ...{
          "oldAnnualRent": old?.annualRent || 0,
          "oldSecurityDeposit": old?.securityDeposit || 0,
          "oldInstallments": old?.installments?.value || 1,
          "installments": item.installments?.value
        }
      }
      return item;

    });
    let body = {
      "paymentPlanSendForApproval": "yes",
      "paymentPlan": finalData
    }
    // console.log("plans to send", body);
    // return;
    try {
      const config: any = {
        data: body,
        method: "PATCH",
        url: `${leaseService}/booking/${leaseId}`,
      };
      const response = await axios(config);
      props.parentState({ load: false });
      message.success(response.data.message);
      await dispatch(getBookingDetail(leaseId));
      setIsDirty(false);
    } catch (error) {
      props.parentState({ load: false });
      message.error(
        error.response?.data?.error || error.response?.data?.message
      );
    }
  }

  const approvePlan = async () => {
    let body = { "paymentPlanSendForApproval": "approved" };
    PopupConfirm({
      title: `Are you sure you want to approve payment plans?`,
      content: "",
      onOk: () => approvalPlanApi(body),
      okText: "Confirm",
    });
  }


  const rejectPlan = async (data) => {
    let body = {
      "paymentPlanSendForApproval": "rejected",
      "rejectionReason": data?.comments ?? "",
    };
    setModalvisible(false);
    await approvalPlanApi(body);
  }


  const approvalPlanApi = async (body) => {
    props.parentState({ load: true });
    try {
      const config: any = {
        data: body,
        method: "PATCH",
        url: `${leaseService}/booking/${leaseId}`,
      };
      const response = await axios(config);
      props.parentState({ load: false });
      message.success(response.data.message);
      await dispatch(getBookingDetail(leaseId));
      setIsDirty(false);
    } catch (error) {
      props.parentState({ load: false });
      message.error(
        error.response?.data?.error || error.response?.data?.message
      );
    }
  }

  const popup = [
    {
      title: (
        <Title level={3} className="mb-0">
          Reject Reason
        </Title>
      ),
      width: 650,
      content: (
        <RejectPopup
          handleReject={rejectPlan}
          onClose={() => setModalvisible(false)}
        />
      ),
    },
  ];

  const popupProps = {
    closable: true,
    visibility: modalvisible,
    title: popup[modalTrigger]?.title,
    content: popup[modalTrigger].content,
    width: popup[modalTrigger].width,
    onCancel: () => setModalvisible(false),
  };


  const statusHeader = () => {
    // managersManager leaseManager landlord
    if (bookingDetails?.paymentPlanApproval == "sent" && bookingDetails?.loginUserType == "leaseManager") {
      return <PendingHeader />
    } else if (bookingDetails?.paymentPlanApproval == "sent" &&
      (bookingDetails?.loginUserType == "managersManager" ||
        bookingDetails?.loginUserType == "landlord")) {
      return <ApprovalHeader approve={approvePlan} reject={() => { setModalTrigger(0); setModalvisible(true) }} />
    }
    else return null;
  };

  return (
    <>
      <Form
        layout="vertical"
        name="bookingform"
        className="bf-main"
        scrollToFirstError
        onFinish={handleSubmit(onSubmit)}
      >
        <CommonCard
          label="Payment Plans dddd"
          leftBtnLabel="Back"
          rightBtnLabel="Next"
          handleNext={((!isDirty && bookingDetails?.paymentPlanApproval != "sent") || bookingDetails?.paymentPlanApproval == "disable") && onSubmit}
          handleBack={() => dispatch(setFormState("back"))}
          topButton={(isDirty && bookingDetails?.paymentPlanApproval != "disable") && { action: sendPaymentPlan, label: "Send for Approval" }}
          statusHeader={statusHeader()}
          parentState={props.parentState}
        >
          <Col span={24}>
            <Row gutter={24}>
              <Col span={24}>
                <AddPlanComm
                  viewOnly={bookingDetails?.paymentPlanApproval == "sent"}
                  fields={plansFields}
                  billingType={billingType}
                  control={control}
                  errors={errors}
                  remove={plansRemove}
                  setValue={setValue}
                  disableAll={props.disableAll}
                  getValues={getValues}
                />
              </Col>
              {bookingDetails?.paymentPlanApproval != "sent" && yearDifference > plansFields.length && (
                <Col span={24}>
                  <Button
                    type="dashed"
                    // className="p-0"
                    onClick={() => {
                      setEnableSendForApproval(true)
                      plansAppend(initPlan)
                    }}
                    htmlType="button"
                    icon={<PlusOutlined />}
                    block={true}
                  >
                    <Text
                      className="ag-primary fontWeight600"
                      style={{ fontSize: "14px" }}
                    >
                      Add Payment Plans
                    </Text>
                  </Button>
                </Col>
              )}
              <Col span={24} style={{ marginTop: "20px" }}>
                <p className="ft-main-label">Additional Payments</p>

                <AdditionalPayments
                  fields={otherChargesFields}
                  billingType={billingType}
                  control={control}
                  errors={errors}
                  remove={otherChargesRemove}
                  setValue={setValue}
                  disableAll={props.disableAll}
                  getValues={getValues}
                  parking={parking}
                  totalAnnualRent={plansWatch?.reduce((a, c) => +(a + c?.annualRent), 0) || null}
                  depositPayments={bookingDetails?.depositPayments}
                />

                {!props.disableAll && (
                  <Row gutter={24}>
                    <Col span={24}>
                      <Button
                        type="dashed"
                        // className="p-0"
                        htmlType="button"
                        icon={<PlusOutlined />}
                        block={true}
                        onClick={() => {
                          setEnableSendForApproval(false)
                          otherChargesAppend(initcharge)
                        }}
                      >
                        <Text
                          className="ag-primary fontWeight600"
                          style={{ fontSize: "14px" }}
                        >
                          Add Another Payment
                        </Text>
                      </Button>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          </Col>
        </CommonCard>
      </Form>
      <Popup destroyOnClose={true} {...popupProps} />
    </>
  );
};
