import { GET_LEAD_DETAILS, GET_LEAD_STATUS } from "./constants";

const initialState = {
  getLeadDetails: {},
  status: {},
};
export default (state = initialState, action) => {
    const { type, data } = action;
    switch (type) {
        case GET_LEAD_DETAILS: return {...state, getLeadDetails:data}
        case GET_LEAD_STATUS: return { ...state, status: data }
        default: return state
    }
}

