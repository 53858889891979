import axios from "axios";
import { mediaService } from "../configs/constants";
import { LeaseStatuses } from "../configs/statuses";
import { paymentTypes } from "../configs/paymentTypes";
import { formatString } from "./formatString";
const presignedImageUrl = `${mediaService}/document/getDocument`; //maintenance/getDocuments  OLD API URL
// /api/v1/document  LATEST URL FOR GET DOCUMENT API with flag ?preview=false for download
// By default preview=true
export const getPresignedImage = async (documentIdArray, preview = true, medialObj = false) => {
  const heads = {
    "organization-unit": "AGP",
    "bussiness-unit": "AUTO",
    channel: "MOBILE",
    "accept-language": "EN",
    source: "Orion",
    accept: "application/json",
    "Content-Type": "multipart/form-data",
    // 'Access-Control-Allow-Origin': 'no-cors', // Add the no-cors header
  };
  const mediaUrls = [];
  for (let documentId of documentIdArray) {
    await axios
      .get(`${presignedImageUrl}/${documentId}?preview=${preview}`, {
        headers: heads,
      })
      .then((x) => {
        if (medialObj) {
          mediaUrls.push(x.data?.result);
        } else {
          mediaUrls.push(x.data?.result?.location);
        }
      })
      .catch((e) => {
        const { response } = e;
        console.log("Error", response?.message);
      });
  }
  return mediaUrls;
};

export const uploadDocumentMedia = async (payload) => {
  const url = `${mediaService}/document/uploadFile`;
  return await axios.post(url, payload, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const currencyFormat = (num, symbol?) => {
  if (num)
    return (
      (symbol ?? "AED ") +
      num?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
    );
  else return "";
};

export const statusLabel = (statusKey: String, statusArray?: any[],bookingStatus = null) => {


  if (statusArray?.length > 0 && statusKey == "kyc_submitted") {
    
      if (statusArray[0]?.status == "draft") return "Tenant Invitation Sent";
      else if (statusArray[0]?.status == "opportunity") return "Opportunity Created";
      else return formatString(statusKey);
    
    
  }
  
  const status = LeaseStatuses?.find((item) => item.value === statusKey);
  if (status) return status?.label;

  return formatString(statusKey);
};

export const paymentLabel = (key: String, statusArray?: []) => {
  const type = paymentTypes?.find((item) => item.value === key);
  if (type) return type?.label;
  return key;
};

export const openFullscreen = () => {
  var elem: any = document.getElementById("myvideo");
  if (elem.requestFullscreen) {
    elem.requestFullscreen();
  } else if (elem?.webkitRequestFullscreen) {
    /* Safari */
    elem?.webkitRequestFullscreen();
  } else if (elem?.msRequestFullscreen) {
    /* IE11 */
    elem?.msRequestFullscreen();
  }
};
