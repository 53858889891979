// Add General Info Page
export const ADD_PROPERTY_GENERALINFO_DATA = "ADD_PROPERTY_GENERALINFO_DATA";
export const ADD_PROPERTY_PROPERTY_DETAILS_DATA =
  "ADD_PROPERTY_PROPERTY_DETAILS_DATA";
export const ADD_PROPERTY_PROPERTY_FEATURES_DATA =
  "ADD_PROPERTY_PROPERTY_FEATURES_DATA";
export const GET_FEATURES_AMENITIES = "GET_FEATURES_AMENITIES";
export const GET_GEO_DATA = "GET_GEO_DATA";
export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const GET_NEW_NOTIFICATIONS = "GET_NEW_NOTIFICATIONS";
export const GET_PROPERTY_BY_ID = "GET_PROPERTY_BY_ID";
export const GET_USAGE_DETAIL = "GET_USAGE_DETAIL";
export const GET_UNIT_DETAIL = "GET_UNIT_DETAIL";
export const GET_SUBUNIT_TYPE = "GET_SUBUNIT_TYPE";

export const GET_LEADS_LISTS = "GET_LEADS_LISTS";
export const STORE_SINGLE_IMAGE_KEY = "STORE_SINGLE_IMAGE_KEY";
export const STORE_MULTI_IMAGE_KEY = "STORE_MULTI_IMAGE_KEY";
export const GET_SINGLE_PROPERTY = "GET_SINGLE_PROPERTY";
export const PRESIGNED_IMAGE = "PRESIGNED_IMAGE";
export const GET_SINGLE_PROPERTY_EMPTY = "GET_SINGLE_PROPERTY_EMPTY";

// Payment Page

export const PAYMENT_PAGE_DATA = "PAYMENT_PAGE_DATA";
export const GET_PAYMENT_CONFIG = "GET_PAYMENT_CONFIG";

// TC Page

export const TC_PAGE_DATA = "TC_PAGE_DATA";
export const GET_TEMPLATES = "GET_TEMPLATES";

export const ACTIVATE_SPINNER = "ACTIVATE_SPINNER";
