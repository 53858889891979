import React from 'react';
import Icon from '@ant-design/icons';

const ScheduleSvg = () => {
  return (
    <svg width="1em" height="1em" fill="currentColor" viewBox="0 0 64 64">
      <path d="M47.1,29.2c0.1,0,0.2,0,0.3,0l0,0c0.6,0,1.2,0,1.8,0.1V7.6c0-4.2-3.4-7.6-7.6-7.6H8.9C4.7,0,1.3,3.4,1.3,7.6V51
	c0,4.2,3.4,7.6,7.6,7.6h21.9c-1.9-3-2.9-6.5-2.9-10.1C27.9,37.8,36.5,29.2,47.1,29.2z M28.4,12.3h11.2c1,0,1.9,0.8,1.9,1.9
	c0,1-0.8,1.9-1.9,1.9H28.4c-1,0-1.9-0.8-1.9-1.9C26.5,13.2,27.4,12.3,28.4,12.3z M28.4,19.8h11.2c1,0,1.9,0.8,1.9,1.9
	c0,1-0.8,1.9-1.9,1.9H28.4c-1,0-1.9-0.8-1.9-1.9C26.5,20.7,27.4,19.8,28.4,19.8z M22.1,46.2H10.9c-1,0-1.9-0.8-1.9-1.9
	c0-1,0.8-1.9,1.9-1.9h11.2c1,0,1.9,0.8,1.9,1.9C24,45.4,23.2,46.2,22.1,46.2z M22.1,38.6H10.9c-1,0-1.9-0.8-1.9-1.9
	c0-1,0.8-1.9,1.9-1.9h11.2c1,0,1.9,0.8,1.9,1.9C24,37.7,23.2,38.6,22.1,38.6z M22.1,31.1H10.9c-1,0-1.9-0.8-1.9-1.9
	c0-1,0.8-1.9,1.9-1.9h11.2c1,0,1.9,0.8,1.9,1.9C24,30.2,23.2,31.1,22.1,31.1z M22.1,23.6H10.9c-1,0-1.9-0.8-1.9-1.9
	c0-1,0.8-1.9,1.9-1.9h11.2c1,0,1.9,0.8,1.9,1.9C24,22.7,23.2,23.6,22.1,23.6z M22.1,16.1H10.9c-1,0-1.9-0.8-1.9-1.9
	c0-1,0.8-1.9,1.9-1.9h11.2c1,0,1.9,0.8,1.9,1.9C24,15.2,23.2,16.1,22.1,16.1z M26.5,29.2c0-1,0.8-1.9,1.9-1.9h5.4
	c1,0,1.9,0.8,1.9,1.9c0,1-0.8,1.9-1.9,1.9h-5.4C27.4,31.1,26.5,30.2,26.5,29.2z M58.2,37.5c-2.9-2.9-6.7-4.6-10.8-4.7l0,0
	c-0.1,0-0.2,0-0.3,0c-8.6,0-15.6,7-15.6,15.6c0,3.4,1.1,6.7,3.1,9.4c3,3.9,7.5,6.2,12.4,6.2c8.6,0,15.6-7,15.6-15.6
	C62.7,44.3,61.1,40.4,58.2,37.5z M53.7,45.9l-5.9,7.4c-0.4,0.5-0.9,0.7-1.5,0.7c-0.4,0-0.8-0.1-1.1-0.4l-4.4-3.2
	c-0.8-0.6-1-1.8-0.4-2.6c0.6-0.8,1.8-1,2.6-0.4l3,2.2l4.8-6c0.6-0.8,1.8-0.9,2.6-0.3C54.3,43.9,54.4,45.1,53.7,45.9z"/>
    </svg>
  );
};




const ScheduleIcon = props => <Icon component={ScheduleSvg} {...props} />

export default ScheduleIcon;

