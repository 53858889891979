import React, { Fragment,useEffect } from "react";
import { Row, Col, Typography, Button } from "antd";
import {
  InputField,
  InputNumberField,
} from "../../../../../../atoms/FormElement";
import { numberWithoutDecimals } from "../../../../../BookingForm/components/utils";


const { Text } = Typography;

export default (props) => {
  const { control, setValue, addPlans } = props; //fees

const userData:any = localStorage?.getItem('login-response');
let jsonUserData = JSON.parse(userData);

  return (
    <>
      <Row gutter={[24, 24]}>
        

        <Col span={12}>
          <Text className="fontWeight600 ag-default mb-1">NO OF PAYMENTS</Text>
        </Col>
        <Col span={4}>
          <Text className="fontWeight600 ag-default mb-2">ANNUAL RENT</Text>
        </Col>
        <Col span={4}>
          <Text className="fontWeight600 ag-default mb-2">NUMBER OF YEARS</Text>
        </Col>
        <Col span={4}>
          <Text className="fontWeight600 ag-default mb-2">SECURITY DEPOSIT</Text>
        </Col>
      </Row>
      <Row gutter={[24, 24]} className="bf-main">
        <Col span={24} className="ft-divider" />
        {addPlans?.map((item, index) => (
          <Fragment key={index+item?.installments}>
            <Col span={12}>
              <Row>
                <Col span={6}>
                  <Text
                    className="ag-dark fontWeight600"
                    style={{ fontSize: "14px" }}
                  >
                    {item.installments} Cheques
                  </Text>
                </Col>
                <Col span={6}>
                  <InputField
                    classes="fm-input-hidden"
                    fieldname={`plan[${index}]`}
                    control={control}
                    initValue={item}
                    iProps={{ readOnly: true, type: "hidden" }}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={4}>
              <InputField
                label=""
                fieldname={`plan[${index}].annualRent`}
                setValue={setValue}
                valueGot={Number(item?.annualRent)}
                control={control}
                // onChange={(e) => {
                //     calculateTotal(index);
                // }}
                initValue={Number(item?.annualRent) || 0}
                iProps={{
                  // readOnly: true,
                  type: "number",
                  parser: (value: any) =>
                    numberWithoutDecimals(value),
                  formatter: (value: string): any =>
                    numberWithoutDecimals(value),
                
                }}
                isRequired={true}
                rules={{ required: "Please enter annual rent" }}
              
              />
            </Col>
            <Col span={4}>
              <InputField
                label=""
                fieldname={`plan[${index}].noOfYear`}
                setValue={setValue}
                valueGot={Number(item?.noOfYear)}
                control={control}
                initValue={Number(item?.noOfYear) || 0}
                iProps={{
                  // readOnly: true,
                  type: "number",
                  // disabled: props.disableAll
                  //   ? true
                  //   : item.type == "ejari_fee" ||
                  //     item.type == "security_deposit"
                  //     ? true
                  //     : false,
                }}
                isRequired={true}
                rules={{ required: "Please enter total amount" }}
             
              />
            </Col>
            <Col span={4}>
              <InputField
                label=""
                fieldname={`plan[${index}].securityDeposit`}
                setValue={setValue}
                valueGot={Number(item?.securityDeposit)}
                control={control}
                initValue={Number(item?.securityDeposit) || 0}
                iProps={{
                  // readOnly: true,
                  type: "number",
                  parser: (value: any) =>
                    numberWithoutDecimals(value),
                  formatter: (value: string): any =>
                    numberWithoutDecimals(value),
                 
                }}
                isRequired={true}
                rules={{ required: "Please enter total amount" }}
            
              />
            </Col>
          </Fragment>
        ))}
      </Row>
      <Row justify="end" style={{ marginBottom: 20 }}>
        <Col>
          {/* {props?.handleDone && ( */}
          <InputField
            classes="fm-input-hidden"
            fieldname="furnished"
            control={control}
            initValue="yes"
            iProps={{ readOnly: true, type: "hidden" }}
          />
         {
          jsonUserData?.usertype?.includes('institutionalLandlord') &&
           <Button
            className="bf-button"
            htmlType="submit"
          >
            Save
          </Button>
          }
          {/* )} */}
        </Col>
      </Row>
    </>);
};
