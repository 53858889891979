import axios from "axios";
import LocalStorageService from "./localStorageService";
import { authService } from "../configs/constants";
import {message} from 'antd'
// LocalstorageService
const localStorageService = LocalStorageService.getService();
// Flag to check if message is already shown
let isSessionExpiredMessageShown = false;
// Add a request interceptor
axios.interceptors.request.use(
  (config) => {
    const token = localStorageService.getAccessToken();
    if (token && !config.url.includes("translation.googleapis.com")) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    // config.headers['Content-Type'] = 'application/json';
    if (!config.url.includes("translation.googleapis.com"))
      config.headers["appname"] = "institutionalLandlordPortal";
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {

    return response;
  },
  function (error) {
    const originalRequest = error.config;
   
    // console.log(error?.response,error.config?._retry,'21111111111111111111111111')
    if (error?.response?.status === 401 && !originalRequest._retry) {
       // Redirect to login page
       if (!isSessionExpiredMessageShown) {
        isSessionExpiredMessageShown = true;
        message.info('Your Session is Expired');
      }
      setTimeout(()=>{
        isSessionExpiredMessageShown = false; // Reset the flag on redirect
        localStorage?.clear();
        window.location.href = '/login';
      },2000)
      originalRequest._retry = true;
      const userInformation = JSON.parse(
        localStorage.getItem("login-response")
      );
      return axios
        .post(`${authService}/user/auth/refresh`, {
          refreshToken: userInformation.refreshToken,
          username: userInformation.data.username,
        })
        .then((res) => {
          if (res.status === 200) {
            const updatedInfo = res?.data.result;
            const updateUserInfo = {
              ...userInformation,
              exp: updatedInfo.exp,
              iat: updatedInfo.iat,
              refreshToken: updatedInfo.refreshToken,
              token: updatedInfo.token,
            };
            const data = JSON.stringify(updateUserInfo);
            localStorage.setItem("login-response", data);
            axios.defaults.headers.common["Authorization"] =
              "Bearer " +
              JSON.parse(localStorage.getItem("login-response")).token;
            return axios(originalRequest);
          }
        });
    }
    return Promise.reject(error);
  }
);

export default axios;
