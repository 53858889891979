import React, { useEffect, useState } from "react";
import { Row, Col, Typography, Select } from "antd";
import { InputField, SelectField } from "../../../../atoms/FormElement";
import { propertyService } from "../../../../../configs/constants";
import axios from "../../../../../utils/axiosInceptor";

const { Option } = Select;
const { Title } = Typography;

export default (props) => {
  const {
    control,
    errors,
    setValue,
    useWatch,
    paymentConfig,
    getValues,
  } = props;

  const [installment, setInstallment] = useState([]);
  const [securityDeposit, setSecurityDeposit] = useState(0);
  const [counter, setCounter] = useState(0);
  let checkrent = useWatch({ control, name: "rentpermonth", defaultValue: 0 });
  let checkTenure = useWatch({
    control,
    name: "installmentplan",
    defaultValue: "",
  });
  // let checkinstallment = useWatch({ control, name: 'installment' });

  const appendInstallment = () => {
    setInstallment([]);
    for (let i = 0; i < parseInt(checkTenure); i++) {
      setInstallment((prevIndexes) => [...prevIndexes, counter]);
    }
  };

  // useEffect(() => {
  //   if (installment) {
  //     setValue("installmentplan", installment.length.toString())
  //   }
  // }, [installment])

  useEffect(() => {
    if (checkTenure) {
      !props.installmentPlan
        ? appendInstallment()
        : setInstallment(props.installmentPlan);
      setValue(
        "rentpermonth",
        Math.floor(getValues("marketrent") / getValues("installmentplan")),
      );
    }
  }, [checkTenure]);

  useEffect(() => {
    checkrent && calculateSecurity();
  }, [checkrent]);

  const calculateSecurity = async () => {
    let mrate = getValues("marketrent");
    if (mrate) {
      const securityDespositUrl = `${propertyService}/property-landlord/payment/security-deposit/${mrate}`;
      const headers = {
        Authorization:
          "Bearer" +
          " " +
          JSON.parse(window.localStorage.getItem("login-response")).token,
      };
      const secDeposit = await axios.get(securityDespositUrl, { headers });
      setSecurityDeposit(secDeposit?.data?.result?.security_deposit);
      setValue(
        "rentpermonth",
        Math.floor(mrate / getValues("installmentplan")),
      );
    }
  };

  return (
    <>
      <Row gutter={24}>
        <Col span={6}>
          <InputField
            isRequired={true}
            label="Market Rent"
            fieldname="marketrent"
            control={control}
            initValue=""
            onBlur={() => calculateSecurity()}
            iProps={{ type: "number", prefix: "AED" }}
            rules={{ required: "Enter Proposed rent amount" }}
            validate={errors.marketrent && "error"}
            validMessage={errors.marketrent && errors.marketrent.message}
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12}>
          <Row gutter={24}>
            <Col span={24}>
              <Title level={5} className="mb-1">
                Rent Details:
              </Title>
            </Col>
            <Col span={12}>
              <SelectField
                isRequired={true}
                label="Installment Plan"
                fieldname="installmentplan"
                control={control}
                validate={errors.installmentplan && "error"}
                validMessage={
                  errors.installmentplan && errors.installmentplan.message
                }
                rules={{ required: "Select Installment Plan" }}
                initValue=""
                iProps={{ disabled: !getValues("marketrent") }}
                selectOption={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(
                  (item, index) => (
                    <React.Fragment key={index}>
                      <Option value={item}>{item} Month(s)</Option>
                    </React.Fragment>
                  ),
                )}
              />
            </Col>
            <Col span={12}>
              <InputField
                label="Rent/Month"
                fieldname="rentpermonth"
                control={control}
                initValue={0}
                iProps={{ type: "number", prefix: "AED", readOnly: true }}
              />
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24}>
              <Title level={5} className="mb-1">
                Processing Fees:
              </Title>
            </Col>
            <Col span={12}>
              <InputField
                label="Ejari Fee"
                fieldname="ejarifee"
                control={control}
                initValue={0}
                iProps={{ type: "number", prefix: "AED", readOnly: true }}
                setValue={setValue}
                valueGot={paymentConfig?.ejari ? paymentConfig.ejari : 0}
              />
            </Col>
            <Col span={12}>
              <InputField
                label="VAT"
                fieldname="ejarifeevat"
                control={control}
                initValue={0}
                iProps={{ type: "number", prefix: "AED", readOnly: true }}
                setValue={setValue}
                valueGot={
                  paymentConfig?.ejarivat
                    ? paymentConfig.ejarivat
                    : Math.floor(
                        (getValues("ejarifee") * paymentConfig.vat_rate) / 100,
                      )
                }
              />
            </Col>

            <Col span={12}>
              <InputField
                label="Security Deposit"
                fieldname="securitydeposit"
                control={control}
                initValue={0}
                iProps={{ type: "number", prefix: "AED", readOnly: true }}
                setValue={setValue}
                valueGot={
                  paymentConfig?.security
                    ? paymentConfig.security
                    : securityDeposit
                }
              />
            </Col>
            <Col span={12}>
              <InputField
                label="VAT"
                fieldname="securitydepositvat"
                control={control}
                initValue={0}
                iProps={{ type: "number", prefix: "AED", readOnly: true }}
                setValue={setValue}
                valueGot={
                  paymentConfig?.securityvat
                    ? paymentConfig.securityvat
                    : Math.floor(
                        (getValues("securitydeposit") / 100) *
                          paymentConfig.vat_rate,
                      )
                }
              />
            </Col>
            <Col span={12}>
              <InputField
                label="Processing Fee"
                fieldname="processingfee"
                control={control}
                initValue={0}
                iProps={{ type: "number", prefix: "AED", readOnly: true }}
                setValue={setValue}
                valueGot={
                  paymentConfig?.processing ? paymentConfig.processing : 0
                }
              />
            </Col>
            <Col span={12}>
              <InputField
                label="VAT"
                fieldname="processingfeevat"
                control={control}
                initValue={0}
                iProps={{ type: "number", prefix: "AED", readOnly: true }}
                setValue={setValue}
                valueGot={
                  paymentConfig?.processingvat
                    ? paymentConfig.processingvat
                    : Math.floor(
                        (getValues("processingfee") * paymentConfig.vat_rate) /
                          100,
                      )
                }
              />
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24}>
              <Title level={5} className="mb-1">
                Installment Plan:
              </Title>
            </Col>
            {installment &&
              installment.map((item, index) => (
                <React.Fragment key={index}>
                  <Col span={12}>
                    <InputField
                      label={`${index + 1}th Installment`}
                      fieldname={`installment[${index}].installment`}
                      control={control}
                      initValue={0}
                      iProps={{ type: "number", prefix: "AED", readOnly: true }}
                      setValue={setValue}
                      valueGot={
                        item.installment
                          ? item.installment
                          : checkTenure
                          ? Math.floor(
                              getValues("marketrent") / parseInt(checkTenure),
                            )
                          : 0
                      }
                    />
                  </Col>
                  <Col span={12}>
                    <InputField
                      label="VAT"
                      fieldname={`installment[${index}].installmentvat`}
                      control={control}
                      initValue={0}
                      iProps={{ type: "number", prefix: "AED", readOnly: true }}
                      setValue={setValue}
                      valueGot={
                        item.installmentvat
                          ? item.installmentvat
                          : checkTenure && paymentConfig.vat_rate
                          ? Math.floor(
                              (getValues(`installment[${index}].installment`) /
                                100) *
                                paymentConfig.vat_rate,
                            )
                          : 0
                      }
                      // valueGot={checkinstallment ? Math.round(
                      //   (paymentConfig.vat_percentage / 100) * getValues(`installment[${index}].installment`))
                      //   : 0
                      // }
                    />
                  </Col>
                </React.Fragment>
              ))}
          </Row>
        </Col>
      </Row>
    </>
  );
};
