import * as action_types from "../constants";

const initialState = {
  // Paymane page
  paymentPageData: [],
  getPaymentConfig:{}
};

export default (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    // Payment page data
    case action_types.PAYMENT_PAGE_DATA:
      return { ...state, paymentPageData: data };
    case action_types.GET_PAYMENT_CONFIG:
      return { ...state, getPaymentConfig: data };
    default:
      return state;
  }
};
