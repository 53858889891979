import React, { useState, useEffect } from "react";
import { Row, Col, Card, Tabs } from "antd";
import BreadCrumb from "../../../atoms/BreadCrumb";
import DashboardLayout from "../../../molecules/DashboardLayout";
import Overview from "./components/Overview";
import Dependants from "./components/Dependants";
import Documents from "./components/Documents";
import Lease from "./components/Lease";
import { useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getLease, getTenantDetails, emptyTenantDetail } from "./ducks/actions";

const { TabPane } = Tabs;

export default (props) => {
  const [tabKey, setTabkey] = useState("0");
  const { id } = useParams();
  const [disableLease, setDisableLease] = useState(false);
  const location = useLocation();
  const dispatch:any = useDispatch();
  const tenantData = useSelector(
    (state: any) => state.tenantsSummary.tenantData,
  );

  
  //@ts-ignore
  useEffect(() => {
    getTenants()
    if (location.pathname.includes("prospect-detail")) {
      setDisableLease(true);
    }
    return () => dispatch(emptyTenantDetail());
  }, []);

  const getTenants = () => {
    dispatch(getTenantDetails(id));
  }
  
  const tabs = [
    {
      label: "Overview",
      children: <Overview getTenants={getTenants} />,
      key:"0"
    },    
    {
      label: "Documents",
      children: <Documents tenantData={tenantData} />,
      key:"1"
    },
    {
      label: "Lease",
      children: <Lease tenantData={tenantData}/>,
      key:"2"
    },
    {
      label: "Co-Occupants",
      children: <Dependants tenantData={tenantData}/>,
      key:"3"
    },
  ];

  const breadcrumbList: any = [
    {
      label: "Tenants",
      route: "/tenants",
    },
    {
      label:
        tenantData?.userDetail?.name ?? "", //first_name && !tenantData?.last_name
          // ? ""
          // : `${tenantData?.first_name} ${tenantData?.last_name}`,
      route: "",
    },
  ];

  return (
    <DashboardLayout>
      <Row gutter={[24, 24]} className="mb-2">
        <Col span={24}>
          <BreadCrumb list={breadcrumbList} />
        </Col>
      </Row>

      <Row gutter={24} className="managers_tenants_detail">
        <Col span={24}>
          <Card
            bordered={false}
            className="ag-nospace-head ag-nospace-body h-auto"
          >
            <Tabs
              defaultActiveKey={tabKey}
              onChange={setTabkey}
              tabBarGutter={16}
              items={tabs}
            />

          </Card>
        </Col>
      </Row>
    </DashboardLayout>
  );
};
