import React, { useEffect } from "react";
import { Col } from "antd";

import { InputField, ReactSelectField } from "../../../atoms/FormElement";

const TenantDetails = ({
  setValue,
  control,
  errors,
  details,
  bookingTerms,
  bookingFormDetails,
}) => {
  const getMobile = () => {
    if (details?.contact_info?.contact_number) {
      if (details?.contact_info?.contact_number.charAt(0) === "+") {
        return details?.contact_info?.contact_number.slice(4);
      }
    }
    return details?.contact_info?.contact_number;
  };

  // useEffect(() => {
  //   if (details?.contacts?.length) {
  //     details?.contacts?.map((e) => {
  //       if (e.party_id === bookingTerms?.selectedContact) {
  //         let obj = {
  //           label: e.first_name,
  //           value: e.first_name,
  //           partId: e.party_id,
  //         };
  //         setValue("contacts", obj);
  //       }
  //     });
  //   }
  // }, [details, bookingTerms]);

  return (
    <>
      <Col span={8}>
        <InputField
          label="Tenant Name"
          fieldname="tenantName"
          control={control}
          valueGot={`${details?.first_name} ${details?.last_name}`}
          setValue={setValue}
          initValue=""
          iProps={{ placeholder: "Tenant Name", disabled: true }}
          rules={{ required: "Please enter Tenant Name" }}
          validate={errors.tenantName && "error"}
          validMessage={errors.tenantName && errors.tenantName.message}
        />
      </Col>
      <Col span={8}>
        <InputField
          // isRequired={true}
          label="Nationality"
          fieldname="nationality"
          control={control}
          valueGot={details?.contact_info?.nationality?.name}
          setValue={setValue}
          // validate={errors.nationality && "error"}
          // validMessage={errors.nationality && errors.nationality.message}
          initValue=""
          // rules={{ required: "Please enter Nationality" }}
          iProps={{ placeholder: "Select Nationality", disabled: true }}
        />
      </Col>
      <Col span={8}>
        <InputField
          // isRequired={true}
          label="Mobile number"
          fieldname="mobile"
          control={control}
          valueGot={getMobile()}
          setValue={setValue}
          // validate={errors.mobile && "error"}
          // validMessage={errors.mobile && errors.mobile.message}
          initValue=""
          // rules={{
          //   pattern: {
          //     value: /^[0-9]*$/,
          //     message: "Value should be a Number",
          //   },
          //   minLength: { value: 9, message: "Minimum 9 digits" },
          //   maxLength: { value: 9, message: "Maximum 9 digits" },
          //   required: "Please enter valid Mobile No.",
          //   setValueAs: (value) => "+".concat(value),
          // }}
          iProps={{ addonBefore: "+971", disabled: true }}
        />
      </Col>
      <Col span={8}>
        <InputField
          // isRequired={true}
          label="Marital Status"
          fieldname="maritalStatus"
          control={control}
          valueGot={details?.marital_status?.name}
          setValue={setValue}
          // validate={errors.maritalStatus && "error"}
          // validMessage={errors.maritalStatus && errors.maritalStatus.message}
          initValue=""
          iProps={{ placeholder: "Select Marital Status", disabled: true }}
          // rules={{ required: "Please select Marital Status" }}
        />
      </Col>
      <Col span={8}>
        <InputField
          // isRequired={true}
          label="Email Address"
          fieldname="email"
          control={control}
          valueGot={details?.contact_info?.email}
          setValue={setValue}
          // validate={errors.email && "error"}
          // validMessage={errors.email && errors.email.message}
          iProps={{ placeholder: "Enter Email Address", disabled: true }}
          initValue=""
          // rules={{
          //   pattern: {
          //     value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
          //     message: "Enter valid Email Address",
          //   },
          //   required: "Please enter Email Address",
          // }}
        />
      </Col>

      <Col span={8}>
        <InputField
          isRequired={true}
          label="No. of occupants"
          fieldname="occupants"
          control={control}
          valueGot={
            bookingTerms?.no_of_occupants ||
            bookingFormDetails?.numberOfOccupants
          }
          setValue={setValue}
          validate={errors.occupants && "error"}
          validMessage={errors.occupants && errors.occupants.message}
          iProps={{ type: "number" }}
          rules={{ required: "Please enter occupants" }}
        />
      </Col>
      {/* <Col span={8}>
        <ReactSelectField
          label="Contacts Name"
          fieldname="contacts"
          control={control}
          validate={errors.contacts && "error"}
          validMessage={errors.contacts && errors.contacts.message}
          iProps={{ placeholder: "Select contacts" }}
          selectOption={details?.contacts?.map((e) => {
            return {
              label: e.first_name,
              value: e.first_name,
              partId: e.party_id,
            };
          })}
        />
      </Col> */}
    </>
  );
};

export default TenantDetails;
