import axios from "../../../../utils/axiosInceptor";
import * as actions from "./constants";
import {
  baseContractService,
  propertyService,
} from "../../../../configs/constants";

export const getTenantIncomeRanges = () => {
  return async (dispatch) => {
    const url = `${baseContractService}/income-ranges`;
    const response = await axios.get(url);

    dispatch({
      type: actions.GET_INCOME_RANGES,
      data: response?.data?.result,
    });
  };
};

export const getTenantType = () => {
  return async (dispatch) => {
    const url = `${baseContractService}/get-tenant-types`;
    const response = await axios.get(url);

    dispatch({
      type: actions.GET_TENANT_TYPE,
      data: response?.data?.result,
    });
  };
};

export const getTenantNationalities = () => {
  return async (dispatch) => {
    const url = `${baseContractService}/nationalities`;
    const response = await axios.get(url);

    dispatch({
      type: actions.GET_NATIONALITIES,
      data: response?.data?.result,
    });
  };
};

export const getMaritalStatus = () => {
  return async (dispatch) => {
    const url = `${baseContractService}/marital-status`;
    const response = await axios.get(url);

    dispatch({
      type: actions.GET_MARITAL_STATUS,
      data: response?.data?.result,
    });
  };
};

export const getTenantCities = () => {
  return async (dispatch) => {
    const url = `${propertyService}/property/cities`;
    const response = await axios.get(url);

    dispatch({
      type: actions.GET_TENANT_CITIES,
      data: response?.data?.result,
    });
  };
};

export const getTenantCountires = () => {
  return async (dispatch) => {
    const url = `${propertyService}/property/countries`;
    const response = await axios.get(url);

    dispatch({
      type: actions.GET_TENANT_COUNTRIES,
      data: response?.data?.result,
    });
  };
};

export const getTenantDetails = (partyId, empty) => {
  return async (dispatch) => {
    if (!empty) {
      const url = `${baseContractService}/${partyId}/tenant-details`;
      const response = await axios.get(url);

      dispatch({
        type: actions.GET_TENANT_DETAILS,
        data: response?.data?.result,
      });
    } else {
      dispatch({
        type: actions.GET_TENANT_DETAILS,
        data: {},
      });
    }
  };
};

export const getTenantEmploymentSector = () => {
  return async (dispatch) => {
    const url = `${baseContractService}/employment-sectors`;
    const response = await axios.get(url);

    dispatch({
      type: actions.GET_EMPLOYMENT_SECTOR,
      data: response?.data?.result,
    });
  };
};

export const getTenantRejectionReason = () => {
  return async (dispatch) => {
    const url = `${baseContractService}/reject-reasons`;
    const response = await axios.get(url);

    dispatch({
      type: actions.GET_REJECT_REASONS,
      data: response?.data?.result,
    });
  };
};

export const getContactRoles = () => {
  return async (dispatch) => {
    const url = `${baseContractService}/get-tenant-role-types`;
    const response = await axios.get(url);

    dispatch({
      type: actions.GET_CONTACT_ROLES,
      data: response?.data?.result,
    });
  };
};

export const getApprovalHistory = (leadId) => {
  return async (dispatch) => {
    const url = `${baseContractService}/fetch-document-approval-history/${leadId}`;
    const response = await axios.get(url);
    dispatch({
      type: actions.GET_APPROVAL_HISTORY_DOCS,
      data: response?.data?.result,
    });
  };
};
