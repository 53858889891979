import { RESET_STATE } from "../../Properties/ducks/constants";
import * as actions from "./constants";

const initialState = {
  getIncomRanges: [],
  getEmploymentSector: [],
  getNationalities: [],
  getTenantDetils: {},
  getCities: [],
  getCountries: [],
  getRejectReasons: [],
  getContactRoles: [],
  getTenantContacts: [],
  getTenantType: [],
  getMarital: [],
  docHistory: [],
};

export default (state = initialState, action) => {
  const { data, type } = action;
  switch (type) {
    case RESET_STATE: {
      return {
        ...state,
        getTenantDetils: {},
        getContactRoles: [],
        getTenantContacts: [],
      };
    }
    case actions.GET_INCOME_RANGES: {
      return {
        ...state,
        getIncomRanges: data,
      };
    }
    case actions.GET_EMPLOYMENT_SECTOR: {
      return {
        ...state,
        getEmploymentSector: data,
      };
    }
    case actions.GET_NATIONALITIES: {
      return {
        ...state,
        getNationalities: data,
      };
    }
    case actions.GET_TENANT_DETAILS: {
      return {
        ...state,
        getTenantDetils: data,
      };
    }
    case actions.GET_TENANT_COUNTRIES: {
      return {
        ...state,
        getCountries: data,
      };
    }
    case actions.GET_TENANT_CITIES: {
      return {
        ...state,
        getCities: data,
      };
    }
    case actions.GET_REJECT_REASONS: {
      return {
        ...state,
        getRejectReasons: data,
      };
    }
    case actions.GET_CONTACT_ROLES: {
      return {
        ...state,
        getContactRoles: data,
      };
    }
    case actions.GET_TENANT_CONTACTS: {
      return {
        ...state,
        getTenantContacts: data,
      };
    }
    case actions.GET_TENANT_TYPE: {
      return {
        ...state,
        getTenantType: data,
      };
    }
    case actions.GET_MARITAL_STATUS: {
      return {
        ...state,
        getMarital: data,
      };
    }
    case actions.GET_APPROVAL_HISTORY_DOCS: {
      return {
        ...state,
        docHistory: data,
      };
    }

    default:
      return state;
  }
};
