export const GET_INCOME_RANGES = "GET_INCOME_RANGES";
export const GET_NATIONALITIES = "GET_NATIONALITIES";
export const GET_TENANT_CITIES = "GET_TENANT_CITIES";
export const GET_TENANT_COUNTRIES = "GET_TENANT_COUNTRIES";
export const GET_TENANT_DETAILS = "GET_TENANT_DETAILS";
export const GET_EMPLOYMENT_SECTOR = "GET_EMPLOYMENT_SECTOR";
export const GET_REJECT_REASONS = "GET_REJECT_REASONS";
export const GET_CONTACT_ROLES = "GET_CONTACT_ROLES";
export const GET_TENANT_CONTACTS = "GET_TENANT_CONTACTS";
export const GET_TENANT_TYPE = "GET_TENANT_TYPE";
export const GET_MARITAL_STATUS = "GET_MARITAL_STATUS";
export const GET_APPROVAL_HISTORY_DOCS = "GET_APPROVAL_HISTORY_DOCS";
