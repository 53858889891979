import { GET_LEAD_DETAILS, GET_LEAD_STATUS } from "./constants";
import axios from "../../../../../utils/axiosInceptor";
import { leaseService } from "../../../../../configs/constants";

export const getLeadDetails = (leadId) => {
  return async (dispatch) => {
    const url = `${leaseService}/contract/get-lead-details/${leadId}`;
    const response = await axios.get(url);
    dispatch({
      type: GET_LEAD_DETAILS,
      data: response?.data?.result,
    });
  };
};

export const getLeadStatus = (leadId) => {
  return async (dispatch) => {
    const url = `${leaseService}/contract/get-tasks/${leadId}`;
    const response = await axios.get(url);
    dispatch({
      type: GET_LEAD_STATUS,
      data: response?.data?.result,
    });
  };
};