import { SIGNUP_INIT, SIGNUP_FAILURE, UPDATE_VALUE } from "./constants";
import { SignUpActions } from "./types";
import { AuthModel } from "./model";

const initialState: AuthModel = {
  username: "",
  email: "",
  password: "",
  confirmPassword: "",
  isLoading: false,
};

const SignUp = (
  state: AuthModel = initialState,
  action: SignUpActions,
): AuthModel => {
  switch (action.type) {
    case SIGNUP_INIT: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case SIGNUP_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case UPDATE_VALUE: {
      return {
        ...state,
        [action.key]: action.value,
      };
    }

    default:
      return state;
  }
};
export default SignUp;
