import React, { useState, useEffect } from "react";

// others
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import DashboardLayout from "../../molecules/DashboardLayout";
import {
  commercialDocs,
  configKeys,
  filterPropertyName,
  filterUnitCode,
  filterUsageType,
  residentialDocs
} from "./components/utils";
import {
  getConfigData,
  getLandlordUsers,
  getBookingDetail,
  clearBookingForm,
  getBookingUnitDetail,
  getParkingBays,
} from "./ducks/actions";
import {
  // Documents,
  LeaseTerms,
  FormTracker,
  ActionHeader,
  // PaymentPlans,
  TenantDetails,
  LeaseTermsComm,
  PaymentPlansComm,
} from "./components";
import Documents from "./components/Documents";
import PaymentPlans from "./components/PaymentPlans";

let initialState = {
  load: false,
};

function BookingForm() {
  // hooks initialization
  const { state } = useLocation();
  const dispatch: any = useDispatch();
  const { leaseId = "" } = useParams();

  // redux states
  const { formState, bookingDetails } = useSelector((state: any) => state.newBooking);

  // states
  const [{ load }, setState] = useState(initialState);

  const updateState = (state: {}) => setState((prevState: any) => ({ ...prevState, ...state }));

  useEffect(() => {
    if (leaseId) dispatch(getBookingDetail(leaseId));

    dispatch(getConfigData(configKeys));

    return () => {
      dispatch(clearBookingForm());
    };
  }, []);

  useEffect(() => {
    const unitId = state?.selectedUnit?.unitId || bookingDetails?.unitId;
    const propertyId =
      state?.selectedProperty?.propertyId || bookingDetails?.propertyId;

    dispatch(getLandlordUsers(propertyId));
    dispatch(getBookingUnitDetail(unitId));
    dispatch(getParkingBays({propertyId:propertyId}));
  }, [bookingDetails]);

  return (
    <DashboardLayout load={load} className="bf-main-content">
      <ActionHeader label="Booking Form" />

      <FormTracker label={filterPropertyName(state, bookingDetails)} />

      {
      filterUsageType(state, bookingDetails) === "Commercial" ? (<>
        {/* COMMEERCIAL */}
        {formState == 1 && <TenantDetails usageType="commercial" parentState={updateState} />}
        {formState == 2 && <LeaseTermsComm parentState={updateState} />}
        {formState == 3 && <PaymentPlansComm parentState={updateState} unitCode={filterUnitCode(state, bookingDetails)} />}
        {formState == 4 && <Documents defaultDocumentsList={commercialDocs} parentState={updateState} />}
      </>) 
      :
       filterUsageType(state, bookingDetails) === "Residential" ? (<>
        {/* RESIDENTIIIAL */}
        {formState == 1 && <TenantDetails usageType="residential" parentState={updateState} />}
        {formState == 2 && <LeaseTerms parentState={updateState} />}
        {formState == 3 && <PaymentPlans parentState={updateState} unitCode={filterUnitCode(state, bookingDetails)} />}
        {formState == 4 && <Documents defaultDocumentsList={residentialDocs} parentState={updateState} />}
      </>) : <></>
      }

    </DashboardLayout>
  );
}

export default BookingForm;
