import React, { useState, useEffect } from "react";
import { Row, Col, Card, Image, Typography, Space, Avatar, Button } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import SatisfiedIcon from "../../../assets/img/satisfied.svg";
import DisatisfiedIcon from "../../../assets/img/disatisfied.svg";
import NeutralIcon from "../../../assets/img/neutral.svg";
import { UserOutlined } from "@ant-design/icons";
import moment from "moment";

const { Title, Paragraph, Text } = Typography;

const ReviewChip = (props) => {
  const [icon, setIcon] = useState();
  const { data, deleteReview } = props;
  const {
    visit_review_id,
    technician_first_name,
    technician_last_name,
    property_name,
    review_text,
    review_type_name,
    date,
  } = data;

  const findDaysAgo = (date: string) => {
    return moment(date).fromNow();
  };

  useEffect(() => {
    review_type_name === "satisfied" && setIcon(SatisfiedIcon);
    review_type_name === "no-satisfied" && setIcon(DisatisfiedIcon);
    review_type_name === "worry" && setIcon(NeutralIcon);
  }, [review_type_name]);

  return (
    <Card bordered={false} bodyStyle={{ padding: 32 }}>
      <Row gutter={32} align="middle">
        <Button
          type="link"
          className="cornerbtn"
          danger
          icon={<DeleteOutlined />}
          onClick={() => deleteReview(visit_review_id)}
        />
        <Col span={8}>
          <Space size={30} align="center">
            <Avatar
              style={{ marginLeft: 16 }}
              size={72}
              icon={<UserOutlined />}
            />
            {/* <Image width={72} height={72} style={{ marginLeft: 16 }} src={imgObj} preview={false} /> */}
            <Space direction="vertical" size={8}>
              <Text className="m-0 defaultFont">{findDaysAgo(date)}</Text>
              <Title level={3} className="m-0 defaultFont sentanceCase">
                {`${technician_first_name} ${technician_last_name}`}
              </Title>
              <Text className="m-0 ag-primary defaultFont">
                {review_type_name != "not_visit"
                  ? `Visited ${property_name}`
                  : "Did not visit"}
              </Text>
            </Space>
          </Space>
        </Col>
        <Col span={16}>
          <Space size={16} direction="vertical">
            <Space size={[16, 0]} align="center">
              {review_type_name != "not_visit" && (
                <Image src={icon} width={32} height={32} preview={false} />
              )}
              <Title level={5} className="ag-primary m-0 sentanceCase">
                {review_type_name != "not_visit"
                  ? review_type_name === "worry"
                    ? "Neutral"
                    : review_type_name
                  : "Reason:"}
              </Title>
            </Space>
            <Paragraph className="m-0 ag-default defaultFont">
              {review_text}
            </Paragraph>
          </Space>
        </Col>
      </Row>
    </Card>
  );
};

export default ReviewChip;
