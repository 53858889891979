

const Permissions = (function () {

    function _isAllowed(moduleName: String, operations: any[]) {
        
        const permits = JSON.parse(window.localStorage.getItem("login-response"))?.permissions?.permissions;
        // console.log(permits[0],'permits')        
        // permits.push({
        //     moduleName :'users',
        //     _id:'12323@',
        //     operations:[{   
        //         read:true,
        //     }]
        // })
        if (permits) {
            const module = permits.find(element => element.moduleName === moduleName);
            if (!module)
                return false;
            const allow = operations.reduce((am, cv) => {
                return am && module.operations[0][cv];
            }, true);           
            return allow;
        }
        return false;
    }
    return {
        isAllowed: _isAllowed,
    }
})();


export default Permissions;
