import { RESET_STATE } from "../../../Properties/ducks/constants";
import {
  GET_TENANT_DETAILS,
  GET_UNIT_DETAILS,
  GET_LEASE_NATURE,
  GET_PARKING_SLOTS,
  GET_BOOKING_TERMS,
  GET_TENANT_CONTACTS,
  GET_TERMS_CONDITIONS,
  GET_APPROVAL_HISTORY,
  GET_BOOKING_DOCS,
  GET_BOOKING_DETAILS,
  GET_BOOKING_REJECT_REASONS,
} from "./constants";

const initialState = {
  tenantDetails: {},
  unitDetails: {},
  leaseNature: [],
  parkingSlots: [],
  bookingTerms: {},
  termsConditions: [],
  tenantContacts: [],
  getAprovalHistory: [],
  getBookingFormDocs: {},
  getBookingFormDetails: {},
  getBookingRejectReason: [],
};

export default (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case GET_TENANT_DETAILS:
      return { ...state, tenantDetails: data };
    case GET_UNIT_DETAILS:
      return { ...state, unitDetails: data };
    case GET_LEASE_NATURE:
      return { ...state, leaseNature: data };
    case GET_PARKING_SLOTS:
      return { ...state, parkingSlots: data };
    case GET_BOOKING_TERMS:
      return { ...state, bookingTerms: data };
    case GET_TERMS_CONDITIONS:
      return { ...state, termsConditions: data };
    case GET_TENANT_CONTACTS:
      return { ...state, tenantContacts: data };
    case GET_APPROVAL_HISTORY:
      return { ...state, getAprovalHistory: data };
    case GET_BOOKING_DOCS:
      return { ...state, getBookingFormDocs: data };
    case GET_BOOKING_DETAILS:
      return { ...state, getBookingFormDetails: data };
    case GET_BOOKING_REJECT_REASONS:
      return { ...state, getBookingRejectReason: data };
    case RESET_STATE:
      return { state: initialState };
    default:
      return state;
  }
};
