import axios from "../../../../../../utils/axiosInceptor";
import * as action_types from "../constants";
import { propertyService } from "../../../../../../configs/constants";

// Payment Page Action
const configUrl = `${propertyService}/property-landlord/payment/config`;

export const getPaymentConfig = () => {
  return async (dispatch) => {
    const configData = await axios.get(configUrl);
    dispatch({
      type: action_types.GET_PAYMENT_CONFIG,
      data: configData?.data?.result,
    });
  };
};
export const retianPaymentData = (data) => {
  return (dispatch) => {
    dispatch({
      type: action_types.PAYMENT_PAGE_DATA,
      data,
    });
  };
};
