import * as action_types from "./constants";

const initialState = {
  tenantList: {
    count: 0,
    results: [],
  },
  prospectList: {
    count: 0,
    results: [],
  },
};

export default (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case action_types.GET_TENANTS:
      return { ...state, tenantList: data };
    case action_types.GET_PROSPECTS:
      return { ...state, prospectList: data };
      case action_types.EMPTY_TENANTS:
        return { ...state, tenantList: data };
    default:
      return state;
  }
};
