import React from 'react';
import Icon from '@ant-design/icons';

const MaintenanceSvg = () => {
  return (
    <svg width="1em" height="1em" fill="currentColor" viewBox="0 0 64 64">
      <path d="M17.7,18.9V22c0,1.1,0.9,2.1,2.1,2.1h0.8c0.1,3,1.3,5.9,3.6,7.9c1.8,1.6,4.4,3,7.8,3c3.4,0,6-1.4,7.8-3
	c2.3-2,3.5-4.9,3.6-7.9h0.8c1.1,0,2.1-0.9,2.1-2.1v-3.1L17.7,18.9z"/>
      <path d="M32.6,0h-1.2c-7.3,0-13.2,5.9-13.2,13.2v1.9h27.5v-1.9C45.8,5.9,39.9,0,32.6,0z" />
      <path d="M25.9,37.5l-5,1c0.3,2.8,2.7,5.2,5.3,7.1c0.7,0.5,1.7,0.6,2.4,0.1l3.4-2.1C32,43.6,25.9,40.9,25.9,37.5z" />
      <path d="M38.1,37.5c0,3.4-6.1,6.1-6.1,6.1l3.4,2.1c0.8,0.5,1.7,0.4,2.4-0.1c2.5-1.9,5-4.4,5.3-7.1L38.1,37.5z" />
      <path d="M51.4,40l-13.3-2.6l0-4.3c-3.9,2.3-8,2.4-12.3,0l0,4.3L12.6,40c-5,1.5-8.5,4.1-8.5,9.3v11.2
	c0,1.9,1.5,3.4,3.4,3.4h48.9c1.9,0,3.4-1.5,3.4-3.4V49.4C59.9,44.1,56.4,41.5,51.4,40z M32,64H13.3v-8.1c0-4.3-0.6-8.6-1.8-12.8
	l-0.7-2.5l0.8-0.3c0.7-0.2,1.4-0.4,2.2-0.6l7.1-1.4l3.6,5.2c2,2.9,4.3,6.3,5.9,8.5c1.1,1.5,1.7,3.4,1.7,5.2L32,64z M15.1,62.2h15.1
	l0-4.9c0-1.5-0.5-3-1.3-4.2c-1.6-2.2-3.9-5.6-5.9-8.5l-2.9-4.2l-5.9,1.2c-0.4,0.1-0.8,0.2-1.1,0.3l0.2,0.8c1.3,4.3,1.9,8.8,1.9,13.3
	V62.2z M50.7,64H32v-6.7c0-1.9,0.6-3.7,1.7-5.2h0h0c1.6-2.2,3.9-5.6,5.9-8.5l3.6-5.2l7.1,1.4c0.7,0.1,1.5,0.3,2.2,0.6l0.8,0.3
	l-0.8,2.5c-1.2,4.1-1.8,8.4-1.8,12.8V64z M33.8,62.2h15.1v-6.2c0-3.6,0.4-7.2,1.2-10.7L33.8,57.8V62.2z M35.1,53.2l-0.5,1.6l6.5-5
	L35.1,53.2z M43.9,40.4L41,44.6c-1.2,1.7-2.4,3.5-3.6,5.2l13.5-7.6l0.1-0.4c-0.4-0.1-0.7-0.2-1.1-0.3L43.9,40.4z"/>
      <path d="M44.5,58.5H38c-0.4,0-0.8-0.4-0.8-0.8v-2.2c0-0.4,0.4-0.8,0.8-0.8h6.5c0.4,0,0.8,0.4,0.8,0.8v2.2
	C45.3,58.1,45,58.5,44.5,58.5z"/>
    </svg>
  );
};

const MaintenanceIcon = props => <Icon component={MaintenanceSvg} {...props} />

export default MaintenanceIcon;

