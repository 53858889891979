import * as action_types from "./constants";

const initialState = {
  allNotify: [],
  allNewNotify: [],
  notifyBydate: [],
};

export default (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case action_types.GET_NOTIFICATIONS_ALL:
      return { ...state, allNotify: data };
    case action_types.GET_NEW_NOTIFICATIONS_ALL:
      return { ...state, allNewNotify: data };
    case action_types.GET_NOTIFICATIONS_BYDATES:
      return { ...state, notifyBydate: data };

    default:
      return state;
  }
};
