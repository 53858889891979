import React from "react";

// others
import dayjs from "dayjs";
import moment from "moment";
import { Button, Col, Row, Space, Tooltip, Typography, message } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { LeaseStatuses } from "../../../../configs/statuses";
import { statusLabel } from "../../../../utils/media";

const { Text } = Typography;

export const radioOptions = [
  {
    label: "Yes",
    value: "yes",
  },
  {
    label: "No",
    value: "no",
  },
];

export const COLUMN_WIDTH: number = 180;
export const datePlaceholder = "MM/DD/YYYY";
export const datePlaceholderddmmyyFormat = "DD-MM-YYYY";

export const configKeys = [
  "nationalityList",
  "requiredBookingDocumentsList",
  "leaseNatureList",
];

export const signatoryFields = [
  "company",
  "designation",
  "signatoryName",
  "signatoryEmail",
  "signatoryMobile",
  "signatoryArabicName",
];

export const signatoryPayload = (data: any) => {
  let payload: any = {};

  for (let key in data) {
    payload["name"] = data["signatoryName"];
    payload["email"] = data["signatoryEmail"];
    payload["arabicName"] = data["signatoryArabicName"];
    payload["mobile"] = data["signatoryMobile"];
    payload["designation"] = data["designation"];
    payload["company"] = data["company"];
  }

  return payload;
};

export const filterPayload = (data: any, propertyType?: string) => {
  const dataCopy = { ...data };
  for (let key in signatoryFields) {
    delete dataCopy[signatoryFields[key]];
  }

  if (propertyType == "commercial") {
    dataCopy["designation"] = dataCopy.tenantDesignation;
    dataCopy["company"] = dataCopy.tenantCompany;

    delete dataCopy["tenantDesignation"];
    delete dataCopy["tenantCompany"];
    delete dataCopy["arabicName"];

    if (dataCopy["signatoryUser"] && dataCopy["signatoryUser"]["arabicName"])
      delete dataCopy["signatoryUser"]["arabicName"];

    delete dataCopy["nationality"];
    delete dataCopy["maritalStatus"];
  }

  if (dataCopy["isSignatory"] === "yes") dataCopy["signatoryUser"] = {};

  return dataCopy;
};

export const handleKycFields = (data: any) => {
  let payload = {};
  const dataCopy = { ...data };

  for (let key in dataCopy) {
    if (key === 'kyc-employmentSector') { //additionally added fix for: 8297 since, label as value was coming already from app
      payload[key.split("-")[1]] = dataCopy[key].label;
      delete dataCopy[key];
    } else if (dataCopy[key]?.value) {
      payload[key.split("-")[1]] = dataCopy[key].value;
      delete dataCopy[key];
    } else if (key.includes("date")) {
      payload[key.split("-")[1]] = apiDateFormat(dataCopy[key]);
      delete dataCopy[key];
    } else if (key.startsWith("kyc-")) {
      payload[key.split("-")[1]] = dataCopy[key];
      delete dataCopy[key];
    }
  }

  return { ...dataCopy, ...{ kycDetails: payload } };
};

export const kycInitValue = (key: string, data: any) => {
  if (key && data && data?.[key] && !key.includes("date")) return data[key];
  else if (data && data[key] && key.includes("date"))
    return dayjs(data[key])?.isValid() ? dayjs(data[key]) : "";
  //added fix for invalid date
  else return "";
};

export const rangePickerInitialValue = (booking: any, type: string) => {
  let range = [];

  switch (type) {
    case "booking_period":
      range[0] = booking?.bookingTerms?.bookingStartDate ? dayjs(booking?.bookingTerms?.bookingStartDate) : "";
      range[1] = booking?.bookingTerms?.bookingEndDate ? dayjs(booking?.bookingTerms?.bookingEndDate) : "";
      break;
    case "lease_period":
      range[0] = booking?.leaseTerms?.leaseStartDate ? dayjs(booking?.leaseTerms?.leaseStartDate) : "";
      range[1] = booking?.leaseTerms?.leaseEndDate ? dayjs(booking?.leaseTerms?.leaseEndDate) : "";
      break;
    case "rent_period":
      if (booking?.leaseTerms?.rentFreeStartDate) {
        range[0] = dayjs(booking?.leaseTerms?.rentFreeStartDate);
        range[1] = dayjs(booking?.leaseTerms?.rentFreeEndDate);
      }
      break;
    case "fitout_period":
      if (booking?.leaseTerms?.fitOutStartDate) {
        range[0] = dayjs(booking?.leaseTerms?.fitOutStartDate);
        range[1] = dayjs(booking?.leaseTerms?.fitOutEndDate);
      }
      break;
  }

  return range || [];
};

export const apiDateFormat = (date: any, format: string = "YYYY-MM-DD") => {
  return date ? dayjs(date).format(format) : "";
};

export const apiDateTimeFormat = (date: any, format: string = "YYYY-MM-DD HH:mm:ss") => {
  return date ? dayjs(date).toISOString() : ""; //handling from BE in ISO
};

export const dateFormat = (date: any) => {
  return date ? dayjs(date, "YYYY-MM-DD") : "";
};

export const dateBookingFormat = (date: any) => {
  return date ? moment(date, "YYYY-MM-DD") : "";
};

export const dateBookingDDMMYYFormat = (date: any) => {
  return date ? dayjs(date, "YYYY-MM-DD") : "";
};

export const excludedLeaseTermsKeys = [
  // "rentFreeEndDate",
  // "rentFreeStartDate",
  "externalAgent",
  "fitOutStartDate",
  "fitOutEndDate",
  "rentFreePeriod"
];

export const marital_status = [
  { label: "Single", value: "single" },
  { label: "Married", value: "married" },
  { label: "Widowed", value: "widowed" },
  { label: "Divorced", value: "divorced" },
];

const leaseNatures = [
  "Family",
  "Executive",
  "Staff Accommodation",
  "Shell and Core",
  "Fitted Office",
  "Serviced Office",
];

export const lease_nature = leaseNatures.map((item) => ({
  label: item,
  value: item,
}));

export const rentFreePeriodValues = () => {
  const numbers: Array<object> = [];

  for (let i = 0; i <= 62; i++) {
    numbers.push({ label: String(i), value: String(i) });
  }

  return numbers
}

const termsConditions = [
  "Family",
  "Executive",
  "Staff Accommodation",
  "Shell and Core",
  "Fitted Office",
  "Serviced Office",
];

export const terms_conditions = termsConditions.map((item) => ({
  label: item,
  value: item,
}));

export const amount_type = [
  {
    label: "Percentage of Rent",
    value: "percent",
    default: true,
  },
  {
    label: "Fixed Amount",
    value: "amount",
  },
];

export const designations = [
  { label: "ALPHA", value: "alpha" },
  { label: "BETA", value: "beta" },
  { label: "GAMMA", value: "gamma" },
];

export const no_of_installments = [
  { label: 1, value: 1 },
  { label: 2, value: 2 },
  { label: 4, value: 4 },
  { label: 6, value: 6 },
  { label: 12, value: 12 },
];

export const payment_modes = [
  {
    label: "Cash",
    value: "cash",
  },
  {
    label: "Cheque",
    value: "cheque",
  },
  {
    label: "Credit Card",
    value: "credit_card",
  },
  {
    label: "Bank Transfer",
    value: "bank_transfer",
  },
  {
    label: "Direct Debit",
    value: "directDebit",
  },
];

export const payment_dates = ["dueDate", "chequeDate"];

export const add_payment_dropdown_keys = ["modeOfPayment", "description"];

export const add_payment_int_keys = ["vat", "totalAmount", "actualAmount"];

export const KYC_CONTENT = `The tenant will be asked to enter additional\nKYC (Know Your Customer) information\nduring the booking process.`;

export const payment_types = [
  { label: "Ejari fee", value: "ejari_fee" },
  { label: "Renewal Fee", value: "renewal_fee" },
  { label: "Parking Charges", value: "parking_charges" },
  { label: "Service Charges", value: "service_charges" },
  { label: "Rental Commission", value: "rental_commission" },
  { label: "Furniture Charges", value: "furniture_charges" },
  { label: "Administration Charges", value: "administration_charges" },
];

export const billing_charges = [
  { name: "Ejari fee", key: "ejari_fee" },
  { name: "Parking Charges", key: "parking_charges" },
  { name: "Service Charges", key: "service_charges" },
  { name: "Rent Installment", key: "rent_installment" },
  { name: "Security Deposit", key: "security_deposit" },
  { name: "Rental Commission", key: "rental_commission" },
  { name: "Furniture Charges", key: "furniture_charges" },
  { name: "Administration Charges", key: "administration_charges" },
];

export const payment_plans = [1, 2,3,4,6,12];

export const validateEmail = (email: string) => {
  const expression = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,3}$/;
  return expression.test(email?.toLowerCase());
};

export const filterDropdownValue = (
  currentvalue: string,
  data
) => {
  return (
    data?.filter(
      (item: any) => item?.value?.toLowerCase() === currentvalue?.toLowerCase() || item?.label?.toLowerCase() === currentvalue?.toLowerCase()
    )[0] || undefined
  );
};

export const disablePreviousDate = (current) => {
  let today = dayjs().format("YYYY-MM-DD");
  return current && dayjs(current).format("YYYY-MM-DD") < today;
};

export const disableBookingEndDate = (
  currentDate: any,
  startDate: any,
  endDate: any
) => {
  return (
    dayjs(currentDate).format("YYYY-MM-DD") <
    dayjs(startDate).format("YYYY-MM-DD") ||
    dayjs(currentDate).format("YYYY-MM-DD") >
    dayjs(endDate).format("YYYY-MM-DD")
  );
};

export const disableLeaseStartDate = (
  currentDate: any,
  priorLeaseExpiry: any,
  startDate: any,
  bookingStartDate: any
) => {
  if (priorLeaseExpiry) {
  } else {
    return (
      currentDate.valueOf() < dayjs(bookingStartDate, "YYYY-MM-DD") ||
      currentDate.valueOf() >=
      dayjs(bookingStartDate, "YYYY-MM-DD").add(30, "day")
    );
  }
};

export const handleLeaseEndDate = (
  endDate: any,
  startDate: any,
  callback: any
) => {
  const diff = dayjs(endDate).diff(dayjs(startDate), "month", true);

  if (diff > 12) {
    callback("rentFreeStartDate", dayjs(startDate).add(12, "month"));
    callback("rentFreeEndDate", dayjs(endDate));
  } else {
    callback("rentFreeStartDate", null);
    callback("rentFreeEndDate", null);
  }
};

export const disableLeaseEndDate = (currentDate: any, startDate: any) => {
  return (
    dayjs(startDate).add(1, "year").subtract(1, "day").format("YYYY-MM-DD") >
    dayjs(currentDate).format("YYYY-MM-DD") ||
    dayjs(startDate).add(10, "year").subtract(1, "day").format("YYYY-MM-DD") <
    dayjs(currentDate).format("YYYY-MM-DD")
  );
};

export const disableResidentialLeaseEndDate = (
  currentDate: any,
  startDate: any
) => {
  return (
    dayjs(startDate).add(1, "year").subtract(1, "day").format("YYYY-MM-DD") !==
    dayjs(currentDate).format("YYYY-MM-DD")
  );
};

export const disableRentStartDate = (
  currentDate: any,
  endDate: any,
  rentFreeEndDate?: any
) => {
  return (
    dayjs(currentDate).format("YYYY-MM-DD") !==
    dayjs(endDate).add(1, "day").format("YYYY-MM-DD") ||
    dayjs(rentFreeEndDate).format("YYYY-MM-DD") ===
    dayjs(endDate).format("YYYY-MM-DD")
  );
};

export const disableRentEndDate = (currentDate: any, endDate: any) => {
  const leaseFinalEndDate = dayjs(dayjs(endDate).add(1, "day")).add(62, "days");

  return (
    currentDate.valueOf() < dayjs(dayjs(endDate).add(1, "day"), "YYYY-MM-DD") ||
    currentDate.valueOf() >= dayjs(leaseFinalEndDate, "YYYY-MM-DD")
  );
};

export const disableMoveInDate = (
  currentDate: any,
  startDate: any,
  bookingStartDate: any
) => {
  return (
    currentDate.valueOf() <
    dayjs(startDate, "YYYY-MM-DD").subtract(14, "day") ||
    currentDate.valueOf() < dayjs(bookingStartDate, "YYYY-MM-DD")
    // || currentDate.valueOf() >= dayjs(endDate, "YYYY-MM-DD")
  );
};

export const disableMoveInBookingDate = (
  currentDate: any,
  startDate: any,
  endDate: any
) => {
  return (
    currentDate.valueOf() < dayjs(startDate, "YYYY-MM-DD") ||
    currentDate.valueOf() >= dayjs(endDate, "YYYY-MM-DD")
  );
};

export const disableFitOutStartDate = (
  currentDate: any,
  priorLeaseExpiry: any,
  startDate: any,
  bookingStartDate: any
) => {
  if (priorLeaseExpiry) {
  } else {
    return (
      currentDate.valueOf() < dayjs(bookingStartDate, "YYYY-MM-DD")
      // || currentDate.valueOf() >=
      //   dayjs(bookingStartDate, "YYYY-MM-DD").add(30, "day")
    );
  }
};

export const disableFitOutEndDate = (
  currentDate: any,
  priorLeaseExpiry: any,
  startDate: any,
  fitoutStartDate: any
) => {
  if (priorLeaseExpiry) {
  } else {
    return (
      currentDate.valueOf() < dayjs(fitoutStartDate, "YYYY-MM-DD")
      // || currentDate.valueOf() >=
      //   dayjs(bookingStartDate, "YYYY-MM-DD").add(30, "day")
    );
  }
};

const formate = "YYYY-MM-DD";

export const defaultMaintenancePreferredDateDays = 60;

export const disableBookingPeriodDates = (
  current: any,
  range: Array<string>
) => {
  let today = dayjs().format(formate);
  if (range?.length > 0)
    return (
      (current && dayjs(current).format("YYYY-MM-DD") < today) ||
      dayjs(range[0]).add(14, "day").isBefore(current)
    );
};

export const disableFitoutPeriodDates = (
  current: any,
  range: Array<string>
) => {
  let today = dayjs().format(formate);
  if (range?.length > 0)
    return (
      current &&
      dayjs(current).format("YYYY-MM-DD") < dayjs(range[0]).format("YYYY-MM-DD")
    );
};

export const disableLeasePeriodDates = (current: any, range: Array<string>, bookingPeriod: Array<string>) => {
  // let today = dayjs().format(formate);

  // if (range?.length > 0 && range[0])
  //   return (
  //     (current &&
  //       dayjs(current).format("YYYY-MM-DD") <
  //       dayjs(range[0]).format(formate)) ||
  //     dayjs(range[0]).subtract(1, 'day').add(12, "month").isBefore(current)
  //   );
  // else 
  let yearEnd = (range?.length > 0 && range[0]) ? dayjs(range[0]).subtract(1, 'day').add(12, "month").isBefore(current) :
    bookingPeriod?.length > 0 && dayjs(bookingPeriod[1]).add(12, "month").isBefore(current);
  //upper code removed for bug: ref 8403
  if (bookingPeriod?.length > 0) return (
    (current &&
      dayjs(current).format("YYYY-MM-DD") <
      dayjs(bookingPeriod[1]).add(1, 'day').format(formate)) || yearEnd
  );
};

export const disableLeasePeriodEditDates = (
  current: any,
  range: Array<string>,
  usagesType: any
) => {
  let today = dayjs().format(formate);
  if (range?.length > 0 && usagesType?.toLowerCase() === "residential")
    return (
      (current &&
        dayjs(current).format("YYYY-MM-DD") <
        dayjs(range[0]).format(formate)) ||
      dayjs(range[1]).add(12, "month").isBefore(current)
    );

  if (range?.length > 0 && usagesType?.toLowerCase() === "commercial")
    return (
      current &&
      dayjs(current).format("YYYY-MM-DD") < dayjs(range[0]).format(formate) &&
      // dayjs(range[1]).add(12, "month").isBefore(current)
      dayjs(range[1]).add(1, "year").subtract(1, "day").format("YYYY-MM-DD") !==
      dayjs(current).format("YYYY-MM-DD") &&
      dayjs(range[1])
        .add(1, "years")
        .add(6, "months")
        .subtract(1, "day")
        .format("YYYY-MM-DD") !== dayjs(current).format("YYYY-MM-DD") &&
      dayjs(range[1])
        .add(2, "years")
        .subtract(1, "day")
        .format("YYYY-MM-DD") !== dayjs(current).format("YYYY-MM-DD") &&
      dayjs(range[1])
        .add(2, "years")
        .add(6, "months")
        .subtract(1, "day")
        .format("YYYY-MM-DD") !== dayjs(current).format("YYYY-MM-DD") &&
      dayjs(range[1])
        .add(3, "years")
        .subtract(1, "day")
        .format("YYYY-MM-DD") !== dayjs(current).format("YYYY-MM-DD") &&
      dayjs(range[1])
        .add(3, "years")
        .add(6, "months")
        .subtract(1, "day")
        .format("YYYY-MM-DD") !== dayjs(current).format("YYYY-MM-DD") &&
      dayjs(range[1])
        .add(4, "years")
        .subtract(1, "day")
        .format("YYYY-MM-DD") !== dayjs(current).format("YYYY-MM-DD") &&
      dayjs(range[1])
        .add(4, "years")
        .add(6, "months")
        .subtract(1, "day")
        .format("YYYY-MM-DD") !== dayjs(current).format("YYYY-MM-DD") &&
      dayjs(range[1])
        .add(5, "years")
        .subtract(1, "day")
        .format("YYYY-MM-DD") !== dayjs(current).format("YYYY-MM-DD") &&
      dayjs(range[1])
        .add(5, "years")
        .add(6, "months")
        .subtract(1, "day")
        .format("YYYY-MM-DD") !== dayjs(current).format("YYYY-MM-DD") &&
      dayjs(range[1])
        .add(6, "years")
        .subtract(1, "day")
        .format("YYYY-MM-DD") !== dayjs(current).format("YYYY-MM-DD") &&
      dayjs(range[1])
        .add(6, "years")
        .add(6, "months")
        .subtract(1, "day")
        .format("YYYY-MM-DD") !== dayjs(current).format("YYYY-MM-DD") &&
      dayjs(range[1])
        .add(7, "years")
        .subtract(1, "day")
        .format("YYYY-MM-DD") !== dayjs(current).format("YYYY-MM-DD") &&
      dayjs(range[1])
        .add(7, "years")
        .add(6, "months")
        .subtract(1, "day")
        .format("YYYY-MM-DD") !== dayjs(current).format("YYYY-MM-DD") &&
      dayjs(range[1])
        .add(8, "years")
        .subtract(1, "day")
        .format("YYYY-MM-DD") !== dayjs(current).format("YYYY-MM-DD") &&
      dayjs(range[1])
        .add(8, "years")
        .add(6, "months")
        .subtract(1, "day")
        .format("YYYY-MM-DD") !== dayjs(current).format("YYYY-MM-DD") &&
      dayjs(range[1])
        .add(9, "years")
        .subtract(1, "day")
        .format("YYYY-MM-DD") !== dayjs(current).format("YYYY-MM-DD") &&
      dayjs(range[1])
        .add(9, "years")
        .add(6, "months")
        .subtract(1, "day")
        .format("YYYY-MM-DD") !== dayjs(current).format("YYYY-MM-DD")
    );
};

export const disableRentPeriodDates = (current: any, range: Array<string>) => {
  let today = dayjs().format(formate);

  if (range?.length > 0)
    return (
      (current &&
        dayjs(current).format("YYYY-MM-DD") <
        dayjs(dayjs(range[1]).add(1, "day")).format(formate)) ||
      dayjs(range[1]).add(3, "month").isBefore(current)
    );
};

export const filterPropertyName = (state: any, booking: any) => {
  let propertyName: string = "";

  if (state)
    propertyName = `${state?.selectedUnit?.unit?.unitNumber} ${state?.selectedProperty?.property_name}`;
  else
    propertyName = `${booking?.propertyDetails?.unitNumber} ${booking?.propertyDetails?.propertyName}`;

  return propertyName;
};

export const filterUnitCode = (state: any, booking: any) => {
  let unitCode: string = "";

  if (state) unitCode = `${state?.selectedUnit?.unit?.unitCode}`;
  else unitCode = `${booking?.propertyDetails?.unitCode}`;

  return unitCode;
};

export const filterUsageType = (state: any, booking: any) => {
  let usagesType: string = "";

  if (state) usagesType = `${state?.selectedUnit?.unit?.usagesType}`;
  else usagesType = `${booking?.propertyDetails?.usagesType}`;

  return usagesType;
};

export const filterParkingUnits = (data: any[], watch?: any[]) => {
  const bays = watch?.map(i => i?.bayNumber?.label || i?.bayNumber); //Added for bug: 8139
  return data?.length > 0
    ? data.map((item) => ({
      label: item?.unit?.unitNumber,
      value: item?.unitId,
    }))?.filter(i => !bays?.includes(i?.label)) //Added for bug: 8139
    : [];
};

export const parkingPayload = (data: any[]) => {
  return data?.length > 0
    ? data.map((item) => ({ ...item, unitId: item?.unitId?.value }))
    : [];
};

export const numberWithCommas = (number: number) =>
  number?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ","); ///\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g

export const numberWithoutDecimals = (value: string) => {
  return value?.toString()?.replace(/\.\d+/g, '');
}

export const leaseExecutiveName = (name: string) => {
  if (!name) return "";

  let label: string = "";
  let splitName = name?.split(" ");

  switch (splitName.length) {
    case 1:
      label = splitName[0][0] + splitName[0][1];
      break;
    case 2:
      label = splitName[0][0] + splitName[1][0];
      break;
    case 3:
      label = splitName[0][0] + splitName[1][0];
      break;
  }

  return label;
};

// change status as per previous design "status mapping"
export const filterLeaseStatus = (status: string) => status.replace("_", " ");
export const changeFieldParser = (value: string, type: string) => {
  if (type === "amount") return value!.replace(/\$\s?|(,*)/g, "");
  else return value!.replace("%", "");
};

export const removeKeyFromArray = (obj, key) => {
  const { [key]: _, ...rest } = obj;
  return rest;
};

export const changeFieldFormatter = (value: string, type: string) => {
  if (type === "amount") return value!.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  // return `AED ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  else return `${value}%`;
};
export const filteLandlordUserLabel = (status: string) => {
  let label: string = "";

  switch (status) {
    case "landlord":
      label = "Landlord";
      break;
    case "leaseManager":
      label = "Lease Executive";
      break;
    case "managersManager":
      label = "Lease Manager";
      break;
  }

  return label;
};

export const columns = [
  {
    colId: 1,
    width: COLUMN_WIDTH,
    field: "descriptionLabel",
    headerName: "Description",
  },
  {
    colId: 2,
    width: COLUMN_WIDTH,
    field: "actualAmount",
    headerName: "Amount",
  },
  {
    colId: 3,
    width: COLUMN_WIDTH,
    field: "vat",
    headerName: "Vat",
  },
  {
    colId: 4,
    width: COLUMN_WIDTH,
    field: "totalAmount",
    headerName: "Total Amount",
  },
  {
    colId: 5,
    width: COLUMN_WIDTH,
    field: "modeOfPayment",
    headerName: "Payment Mode",
  },
  {
    colId: 6,
    width: COLUMN_WIDTH,
    field: "documentNo",
    headerName: "Document No.",
  },
  {
    colId: 7,
    width: COLUMN_WIDTH,
    field: "bankName",
    headerName: "Bank Name",
  },
  {
    colId: 8,
    width: COLUMN_WIDTH,
    field: "dueDate",
    headerName: "Due Date",
  },
  {
    colId: 9,
    width: COLUMN_WIDTH,
    field: "chequeDate",
    headerName: "Cheque Date",
  },
];

export const filterDescriptionLabel = (
  paymentSchedule: any,
  bool?: boolean,
  booking?: any,
  renewal?: boolean
) => {
  if (!paymentSchedule) return [];

  let installmentNumber = 0;
  let paymentScheduleCopy = [...paymentSchedule];

  for (let key in paymentScheduleCopy) {
    const descriptionLabel = paymentScheduleCopy[key].description.replace(
      "_",
      " "
    );

    if (paymentScheduleCopy[key].description === "rent_installment") {
      installmentNumber = installmentNumber + 1;

      paymentScheduleCopy[key] = {
        ...paymentScheduleCopy[key],
        ...{ descriptionLabel: `${descriptionLabel} ${installmentNumber}` },
      };
    } else
      paymentScheduleCopy[key] = {
        ...paymentScheduleCopy[key],
        ...{ descriptionLabel },
      };

    if (bool) {
      const mode = payment_modes.find(
        (item) => item.value === paymentScheduleCopy[key].modeOfPayment
      );

      paymentScheduleCopy[key] = {
        ...paymentScheduleCopy[key],
        modeOfPayment: mode?.label || "Cheque",
        dueDate: apiDateFormat(paymentScheduleCopy[key].dueDate, "DD-MM-YYYY"),
        chequeDate: apiDateFormat(
          paymentScheduleCopy[key].chequeDate || paymentScheduleCopy[key].dueDate,
          "DD-MM-YYYY"
        ),
      };
    }
  }

  if (renewal && !booking?.renewalStatus)
    return paymentScheduleCopy?.filter(
      (item) => item?.description === "security_deposit"
    );

  return paymentScheduleCopy;
};

export const filterPaymentPayload = (
  data: any,
  paymentSchedule: Array<any>
) => {
  let index = 0;
  let payload = {};
  let newArray: Array<any> = [];
  const length = paymentSchedule.length;
  // console.log("PDC FORM--->",data,paymentSchedule);

  while (index < length) {
    for (let key in data) {
      const splitKey = key.split("-");

      if (index === parseInt(splitKey[1])) {
        payload["pdcId"] = paymentSchedule[index].pdcId;
        if (add_payment_int_keys.includes(splitKey[0]))
          if (typeof data[key] === "string" && data[key]?.includes(",")) {
            let val = data[key].replace(",", "");
            payload[splitKey[0]] = parseFloat(val);
          } else payload[splitKey[0]] = parseFloat(data[key]);
        else if (payment_dates.includes(splitKey[0]))
          payload[splitKey[0]] = apiDateFormat(data[key], "YYYY-MM-DD");
        else if (add_payment_dropdown_keys.includes(splitKey[0]))
          payload[splitKey[0]] =
            data[key].value || paymentSchedule[index]?.[splitKey[0]];
        else payload[splitKey[0]] = data[key];
      }
    }

    newArray.push({ ...payload, ...{} });
    index = index + 1;
  }

  return newArray;
};

export const changeValueOptions = [
  {
    label: "No change",
    value: "no_change",
  },
  {
    label: "Increase",
    value: "increase",
  },
  {
    label: "Decrease",
    value: "decrease",
  },
];

export const options = [
  {
    label: "Amount",
    value: "amount",
  },
  {
    label: "Percentage",
    value: "percentage",
  },
];

export const commaFormatter = (value: string) =>
  value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const installmentsList = [
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
  { label: "6", value: 6 },
  { label: "12", value: 12 },
];

export const filterChangeValue = (type: string) => {
  switch (type) {
    case "no_change":
      return "No Change";
    default:
      return type;
  }
};

export const disableEditButton = (booking: any, renewal: boolean) => {
  let disable: boolean = true;
  let status: string = booking?.renewalStatus || booking?.leaseStatus;

  if (booking && Object.keys(booking || {})?.length > 0) disable = false;

  switch (status) {
    case "active":
      if (!renewal) disable = true;
      break;
    case "kyc_approved":
      disable = true;
      break;
    case "booking_approved":
      disable = true;
      break;
    case "sent_for_lease_approval":
      disable = true;
      break;
    case "sent_for_booking_approval":
      disable = true;
      break;

    //renewal statuses
    case "sent":
      disable = true;
      break;
    case "renewal_sent":
      disable = true;
      break;
    case "ready_to_send":
      disable = true;
      break;
    case "renewal_accepted":
      disable = true;
      break;
    case "sent_for_approval":
      disable = true;
      break;
    case "awaiting_renewal_fee":
      disable = true;
      break;

    default:
      for (let key in booking?.leaseStatusHistory)
        if (
          ["booking_approved", "booking_cancelled"].includes(
            booking?.leaseStatusHistory[key]?.status
          )
        ) {
          disable = true;
          break;
        }

      if (booking?.paymentPlanApproval === "sent") disable = true;
  }

  return disable;
};

export const disablePaymentSchedule = (booking: any, bool?: boolean) => {
  let disable: boolean = true;
  if (booking?.leaseId && booking?.leaseStatus !== "booking_cancelled")
    disable = false;

  if (booking?.paymentPlanApproval === "sent" && bool != true) disable = true; //FIx: 8327
  if (bool == true && booking?.leaseStatusHistory?.find(i => i?.status == "deposit_paid")) disable = true;
  if (booking?.leaseStatus == "sent_for_lease_approval") disable = true;
  if (booking?.leaseStatus == "sent_for_booking_approval") disable = true; 
  if(booking?.leaseStatus === "deposit_paid" && booking?.leaseStatus == "renewal_sent") disable = true;
  if(booking?.renewalStatus === 'sent_for_approval' ||  booking?.renewalStatus === "renewal_accepted")
     disable = true;
  if(booking?.leaseStatus === 'deposit_paid' && booking?.renewalStatus === "renewal_accepted")   disable = false;
  return disable;
};

export const unitBookingStatus = (statusKey: string) => {
  const status = LeaseStatuses?.find((item) => item.value === statusKey);
  if (status) {
    if (status.order >= 150) return "Paid"
    else return "Unpaid";
  } else return statusKey;
}

export const filterKycSelectList = (data: Array<any>) =>
  Object.entries(data).map(([value, label]) => ({ value, label }));

export const disableSingleField = (
  chequeStatus: string,
  description?: string,
  renewal?: boolean
) =>
  chequeStatus?.toLowerCase() === "paid" || chequeStatus?.toLowerCase() === "cancelled" ||  //added for bug: 8461
  (renewal && ["ejari_fee", "security_deposit"].includes(description?.toLowerCase()));


export const autoFillAdditionalLineItems = (
  key: string = "",
  depositPayments: Array<any> = []
) => {
  if (key && depositPayments.length > 0)
    return depositPayments.find((item) => key === item?.type || null);
};

export const historyColumns = [
  {
    colId: 1,
    width: 200,
    field: "createdAt",
    headerName: "Created At",
    cellRenderer: ({ data }) =>
      apiDateFormat(data?.createdAt, "DD-MM-YYYY HH:mm A"),
  },
  {
    colId: 2,
    width: 200,
    field: "updatedAt",
    headerName: "Updated At",
    cellRenderer: ({ data }) =>
      apiDateFormat(data?.updatedAt, "DD-MM-YYYY HH:mm A"),
  },
  {
    colId: 3,
    width: 200,
    field: "updatedBy",
    headerName: "Created By",
  },
  {
    colId: 4,
    width: 250,
    field: "status",
    headerName: "Status",
    cellRenderer: ({ data }) => {
      return (
        <div
          style={{
            margin: "0",
            padding: "0",
            height: "32px",
            display: "flex",
            borderRadius: "4px",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#DDF6E8",
            marginTop: "6px",
          }}
        >
          <p style={{ color: "#29C76F" }}>{statusLabel(data?.status, [data?.firstStatus])}</p>
        </div>
      );
    },
  },
  {
    colId: 5,
    width: 200,
    field: "comments",
    headerName: "Comment",
    cellRenderer: ({ data }) => {
      return (
        data?.comments && (
          <Tooltip title={data?.comments} trigger="hover" showArrow={false}>
            <Text className="m-0 ag-primary smallFont12">{data?.comments}</Text>
          </Tooltip>
        )
      );
    },
  },
  {
    colId: 6,
    width: 200,
    field: "reason",
    headerName: "Reason",
    cellRenderer: ({ data }) => {
      return (
        data?.reason && (
          <Tooltip title={data?.reason} trigger="hover" showArrow={false}>
            <Text className="m-0 ag-primary smallFont12">{data?.reason}</Text>
          </Tooltip>
        )
      );
    },
  },
];

export const mappedStatusHistory = (bookingData) => {
  const data = bookingData?.leaseStatusHistory?.length > 0 ? bookingData?.leaseStatusHistory : [];

  return data.map(i => {
    if (i?.status == "courier_pickup_selected") {
      i['reason'] = "Payment ID: " + (bookingData?.aramexData?.paymentOrderId || "");
      i['comments'] = "AWB Number: " + (bookingData?.aramexData?.shipmentNumber || "");
    } 
    return { ...i, firstStatus: data[0] ,status:i.status.replace(/_/g, ' ')};
    
  })

 
}

export const PendingHeader = () => (
  <>
    <InfoCircleOutlined /> &nbsp; Your payment plan changes are pending approval
  </>
);

export const ApprovalHeader = ({ approve, reject }) => (
  <Row>
    <Col
      span={24}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Space size={5}>
        <InfoCircleOutlined /> &nbsp; Approval required for below payment plan
        changes
        <Space size={10} className="mx-2">
          <Button
            type="primary"
            onClick={() => approve()}
            className="ef-approve-btn"
            htmlType="button"
          >
            Approve
          </Button>
          <Button
            onClick={() => reject()}
            className="ef-reject-btn"
            htmlType="button"
          >
            Reject
          </Button>
        </Space>
      </Space>
    </Col>
  </Row>
);

export const OldAmount = ({ amount }) =>
  amount ? <s className="old-amount-text">{amount}</s> : <></>;

export const findLeaseStatus = {
  draft: "Draft",
  active: "Active",
  created: "Created",
  booking_created: "Draft – Submitted", //CHANGED AS PER FLOW -> booking_created will not be there
  tenant_invitation_sent: "Tenant Invitation Sent",
  tenant_information_submitted: "Tenant Information Submitted",
  tenant_documents_uploaded: "Tenant Documents Uploaded",
  tenant_documents_approved: "Tenant Documents Approved",
  tenant_documents_rejected: "Tenant Documents Rejected",
  booking_confirmed: "Booking Confirmed",
  tenant_information_approved: "Tenant Information Approved",
  landlord_pdc_updated: "Landlord PDC Updated",
  booking_approved: "Booking Approved",
  payment_successful: "Payment Successful",
  tenant_information_incomplete: "Tenant Information_incomplete",
  draft_ejari: "Draft Ejari",
  tenant_signed_ejari_contract: "Tenant Signed Ejari Contract",
  landlord_signed_ejari_contract: "Landlord Signed Ejari Contract",
  ejari_contract_downloaded: "Ejari Contract Downloaded",
  tenant_signed_ejari_contract_downloaded:
    "Tenant Signed Ejari Contract Downloaded",
  ejari_contract_activated: "Ejari Contract Activated",
  ejari_certificate_downloaded: "Ejari Certificate Downloaded",
  booking_reject: "Booking Rejected",
  contract_send_to_tenant: "Contract Sent To Tenant",
  contract_signed_by_tenant: "Contract Signed By Tenant",
  contract_created: "Contract Created",
  lease_expired: "Lease Expired",
  booking_in_progress: "Booking In Progress",
  booking_form_sent: "Booking Form Sent",
  awaiting_documents: "Awaiting Documents",
  lease_approved: "Lease Approved",
  lease_rejected: "Lease Rejected",
  sent_for_booking_approval: "Sent For Booking Approval",
  payment_initiated: "Payment Initiated",
  booking_cancelled: "Booking Cancelled",
  booking_rejected: "Booking Rejected",
  awaiting_kyc_information: "Tenant Invitation Sent", //Awaiting KYC Information
  kyc_submitted: "Opportunity Created", //KYC Submitted
  documents_submitted: "KYC Submitted",
  documents_approved: "Documents Approved",
  kyc_approved: "KYC Approved",
  kyc_rejected: "KYC Rejected",
  awaiting_landlord_signature: "Awaiting Landlord Signature",
  contract_signed: "Contract Signed",
  awaiting_deposit: "Awaiting Deposit",
  deposit_paid: "Deposit Paid",
  rent_payments_received: "Rent Payments Received",
  sent_for_lease_approval: "Sent For Lease Approval",
  awaiting_tenant_signature: "Awaiting Tenant Signature",
  renewed: "Renewed",
  vacated: "Vacated",
  renewal: "Renewal",
  new: "New",
  payment_plan_approved: "Payment Plan Approved",
  courier_pickup_selected: "Courier Pickup Selected",
  self_delivery_selected: "Self Delivery Selected",
  active_renewing: "Active – Renewing",
  active_vacating: "Active – Vacating",
  renewal_sent: "Renewal Sent",
  renewal_accepted: "Renewal Accepted",
  awaiting_renewal_fee: "Awaiting Renewal Fee",
  to_review: "To Review",
  ready_to_send: "Ready To Send",
  sent: "Sent to tenant",
  sent_for_approval: "Sent For Approval",
  uploaded: "Uploaded",
  renewal_declined: "Renewal Declined",
  opportunity: "Opportunity Created",
  payment_plan_rejected: "Payment Plan Rejected",
  moveout_confirmed:"Confirmed Move Out"
};

export const residentialDocs = [
  {
    type: "passport",
    documentLabel: "",
    status: "",
    checked: true,
  },
  {
    type: "emiratesIdFront",
    documentLabel: "",
    status: "",
    checked: true,
  },
  {
    type: "emiratesIdBack",
    documentLabel: "",
    status: "",
    checked: true,
  },
];

export const commercialDocs = [
  {
    type: "companyTradeLicense",
    documentLabel: "",
    status: "",
    checked: true,
  },
  {
    type: "bankStatement",
    documentLabel: "",
    status: "",
    checked: true,
  },
];

export const getKycLabel = (key: string, kycDetails: Array<object>) => {
  let label: string = "";
  if (kycDetails?.length > 0)
    kycDetails?.find((e: any) => {
      if (e.name === key) label = e?.label;
    });
  return label;
};

export const validateChecks = (payments: Array<any>) => {
  const data = payments?.filter((i) =>
    i?.description === "rent_installment"
    && i?.modeOfPayment === "cheque"
    && (!i?.bankName || !i.documentNo));

  if (data?.length > 0) {
    message.error("Please add cheque and bank details against rent instalments and try again.");
    return true;
  }
  return false;
}

// convert data in label and value for react-select 

function getUniqueArray(arr, key) {
  const unique = arr
      .map(item => item[key])
      .map((value, index, self) => self.indexOf(value) === index && index)
      .filter(index => arr[index])
      .map(index => arr[index]);

  return unique;
}
export const  convertDataForReactSelect = (paramData,type=false,value=null) => {
    
   let data = [];

  if(!type && !value){
    paramData?.forEach((item)=>{
  
      data.push({
        value:item?.categoryId,
        label:item?.categoryName
       })
    
   })

   return  getUniqueArray(data,'value');
  }else{
    const ifHasSubCat = paramData?.find((item)=>item?.categoryId === value);
    ifHasSubCat?.subCategories?.length > 0 && 
     ifHasSubCat?.subCategories?.forEach((item)=>{
  
      data.push({
        value:item?.subCategoryId,
        label:item?.name
       })
    
   })

return data;
  }
}