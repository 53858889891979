import { baseContractService, contractService } from "../../../../configs/constants";
import axios from "../../../../utils/axiosInceptor";
import {
  GET_LEASEFORMS,
  ADD_UNITS_VISIBLE,
  SET_LEAD_ID,
  GET_CURRENT_PAGE,
} from "./constants";

export const getLeaseForms = (limit, page = 1, value = "", field,setIsLoading=null,leaseStatus=null) => async (
  dispatch,
  
) => {
  const url = `${contractService}/booking?unitstatus=occupied&limit=${limit}&page=${page}&searchby=${`${field}&text=${value}&leaseStatus=${leaseStatus}`}`;
  const {
    data: { result },
  } = await axios.get(url);
  if (result) {
    setIsLoading && setIsLoading(false);
    dispatch({
      type: GET_LEASEFORMS,
      data: result,
    });
  }
};
export const visiblePopup = () => (dispatch) => {
  dispatch({
    type: ADD_UNITS_VISIBLE,
    data: true,
  });
};

export const getLeadID = (leadID) => (dispatch) => {
  dispatch({
    type: SET_LEAD_ID,
    data: leadID,
  });
};

export const getCurrentPage = (currentPage) => (dispatch) => {
  dispatch({
    type: GET_CURRENT_PAGE,
    data: currentPage,
  });
};
