import React, { useState } from 'react';
import {
  Row,
  Col,
  Button,
  Form,
  Input,
  Divider,
  Typography,
  message,
  Space,
  Spin
} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { maintenanceService } from '../../../../../../configs/constants';
import axios from '../../../../../../utils/axiosInceptor'

const { Paragraph } = Typography;

const RejectTechnician = props => {

  const [submited, setSubmited] = useState(false);
  const [isError, setisError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [loader, setLoader] = useState(false);
  const submituUrl = `${maintenanceService}/maintenance/technician/assign-building/`;



  const onFinish = async (values) => {
    setLoader(true);
    setSubmited(true);
    const body = {
      comment: values.reason
    }
    try {
      await axios.put(`${submituUrl}${props.data.data.id}/reject`, body);
      setTimeout(() => {
        setLoader(false);
        setSubmited(false);
        message.success(`Technician Rejected`);
        props.onReject();
      }, 3000);
    } catch (e) {
      setLoader(false);
      setisError(true);
      const { response } = e;
      // message.error(response?.data?.message);
      setErrorMessage(response?.data?.message)
    }
  }

  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
    >
      {loader && !isError &&
        (<Row gutter={[24, 24]} >
          <Col span={24}>
            <Space size={24} className="w-100 text-center" direction="vertical">
              <Spin size="large" className="ag-fontSize32" />
              {/* <Paragraph className="ag-default fontWeight600 mb-0">Loading</Paragraph> */}
            </Space>
          </Col>
        </Row>)}
      {!submited ? <>
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Form.Item name="reason" label="Enter your reason for rejecting" rules={[{ required: true }]}>
              <Input.TextArea maxLength={50} placeholder="Rejected due to the redundancy" rows={1} showCount />
            </Form.Item>
          </Col>
        </Row>
        <Divider className="no-border m-1" />
        <Row gutter={24} justify="space-between">
          <Col>
            <Button className="ag-gray-button-outline" onClick={props.onBack}>Back</Button>
          </Col>
          <Col>
            <Button className="ag-green-button" htmlType="submit">Submit</Button>
          </Col>
        </Row>
      </>
        :
        isError &&
        (<>
          <Row gutter={[24, 24]} >
            <Col span={24}>
              <Space size={24} className="w-100 text-center" direction="vertical">
                <ExclamationCircleOutlined className="ag-fontSize32 ag-error" />
                <Paragraph className="ag-error fontWeight600 mb-0">{errorMessage}</Paragraph>
              </Space>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24}><Button className="ag-gray-button-outline" htmlType="button" onClick={() => { setSubmited(false); setisError(false) }}>Back</Button></Col>
          </Row>
        </>)
      }
    </Form>
  );
};

export default RejectTechnician;
