import React, { useEffect, useState } from "react";
import { Col } from "antd";
import { InputField } from "../../../atoms/FormElement";

const initUnitDetailState: any = {
  buildingName: "",
  unitCode: "",
  type: "",
  size: "",
  unitType: "",
  subType: { value: "", name: "" },
  dewa: "",
};

import { bedroomCategory } from "../../Properties/ducks/constants";

var _ = require("lodash");

export default ({
  control,
  errors,
  unitDetails,
  setValue,
  bookingFormDetails,
}) => {
  const [unitState, setUnitState] = useState(initUnitDetailState);

  useEffect(() => {
    if (unitDetails) {
      const detail = {
        buildingName: unitDetails?.name,
        unitCode: unitDetails?.unit_no,
        type: unitDetails?.usage_type,
        size: unitDetails?.size,
        unitType: unitDetails?.type,
        subType: unitDetails?.sub_type,
        dewa: bookingFormDetails?.dewaDetails,
      };
      setUnitState(detail);
    }
  }, [unitDetails, bookingFormDetails]);

  return (
    <>
      <Col span={8}>
        <InputField
          // isRequired={true}
          label="Building Name"
          fieldname="buildingName"
          valueGot={unitState?.buildingName}
          setValue={setValue}
          control={control}
          initValue=""
          // validate={errors.buildingName && "error"}
          // validMessage={errors.buildingName && errors.buildingName.message}
          // rules={{ required: "Please enter building name" }}
          iProps={{ placeholder: "Al-Ghurair Residence", disabled: true }}
        />
      </Col>
      <Col span={8}>
        <InputField
          // isRequired={true}
          // rules={{ required: "Please enter unit code" }}
          label="Unit Code"
          fieldname="unitCode"
          valueGot={unitState?.unitCode}
          setValue={setValue}
          control={control}
          initValue=""
          // validate={errors.unitCode && "error"}
          // validMessage={errors.unitCode && errors.unitCode.message}
          iProps={{ placeholder: "191506-1-111", disabled: true }}
        />
      </Col>
      <Col span={8}>
        <InputField
          // isRequired={true}
          // rules={{ required: "Please enter type" }}
          label="Usage Type"
          fieldname="type"
          //@ts-ignore
          valueGot={unitState?.type?.name}
          setValue={setValue}
          control={control}
          initValue=""
          // validate={errors.type && "error"}
          // validMessage={errors.type && errors.type.message}
          iProps={{ placeholder: "", disabled: true }}
        />
      </Col>
      <Col span={8}>
        <InputField
          // isRequired={true}
          label="Size, Sqft"
          // validate={errors.size && "error"}
          // validMessage={errors.size && errors.size.message}
          // rules={{ required: "Please enter size" }}
          fieldname="size"
          valueGot={unitState?.size}
          setValue={setValue}
          control={control}
          initValue={""}
          iProps={{ placeholder: "", disabled: true }}
        />
      </Col>
      <Col span={8}>
        <InputField
          // isRequired={true}
          // validate={errors.unitType && "error"}
          // validMessage={errors.unitType && errors.unitType.message}
          //@ts-ignore
          valueGot={unitState?.unitType?.name}
          setValue={setValue}
          // rules={{ required: "Please enter unit type" }}
          label="Unit Type"
          fieldname={`unitType`}
          control={control}
          initValue=""
          iProps={{ placeholder: "", disabled: true }}
        />
      </Col>
      <Col span={8}>
        <InputField
          valueGot={unitState?.subType?.name}
          setValue={setValue}
          label="Sub-Type"
          fieldname={`subType`}
          control={control}
          initValue=""
          iProps={{ placeholder: "", disabled: true }}
        />
      </Col>
      <Col span={8}>
        <InputField
          label="DEWA"
          fieldname={`dewa`}
          valueGot={unitState?.dewa}
          setValue={setValue}
          control={control}
          initValue=""
          iProps={{ placeholder: "Enter details" }}
        />
      </Col>
    </>
  );
};
