import React from "react";

// others
import { Item } from "../view";
import { Col, Row, Spin } from "antd";
import { getKycLabel } from "../utils";
import { apiDateFormat } from "../utils";
interface IProps {
  booking: any;
  kycDetails: Array<object>;
}

const index = (props: IProps) => {
  // variables
  const format = "DD-MMM-YYYY";
  const isBooking = props?.booking && Object.keys(props.booking).length > 0;

  const valueStyle = { textTransform: "capitalize" };

  const usagesType = props?.booking?.propertyDetails?.usagesType;

  console.log(props?.kycDetails,'kycDetails')
  return (
    <Col span={24} className="view-booking">
      {!isBooking && (
        <Row gutter={24} className="loading">
          <Spin size="large" />
        </Row>
      )}

      {isBooking && (
        <>
          <Row gutter={24} className="bd-item">
            <Item
              label="Tenant Name"
              value={props.booking?.tenantDetails?.tenantNameEN}
            />
            {(usagesType?.toLocaleLowerCase() === "residential") && (
              <Item
                label="Tenant Name Arabic"
                value={props.booking?.tenantDetails?.tenantNameAR}
              />
            )}
            <Item
              label="Email Address"
              value={props.booking?.tenantDetails?.email}
            />
            <Item
              label="Mobile Number"
              value={`+971 ${props.booking?.tenantDetails?.mobileNumber}`}
            />

            {usagesType === "Commercial" ? (
              <>
                <Item
                  label="Designation"
                  valueStyle={valueStyle}
                  value={
                    props.booking?.tenantDetails?.designation ||
                    props.booking?.tenantDetails?.designationAsPerVisa
                  }
                />
                <Item
                  label="Company"
                  valueStyle={valueStyle}
                  value={props.booking?.tenantDetails?.company}
                />
              </>
            ) : (
              <>
                <Item
                  label="Nationality"
                  valueStyle={valueStyle}
                  value={props.booking?.tenantDetails?.nationality}
                />
                <Item
                  label="Marital Status"
                  valueStyle={valueStyle}
                  value={props.booking?.tenantDetails?.maritalStatus}
                />
              </>
            )}

            <Item
              valueStyle={valueStyle}
              label="Is the tenant the authorized signatory?"
              value={props.booking?.isSignatory}
            />
          </Row>

          {props.booking?.isSignatory === "no" && (
            <Row gutter={24} className="bd-item">
              <Item
                label="Signatory Email"
                value={props.booking?.signatoryUser?.email}
              />
              <Item
                label="Signatory Name"
                value={props.booking?.signatoryUser?.name}
              />
              {(usagesType?.toLocaleLowerCase() === "residential") && (
                <Item
                  label="Signatory Arabic Name"
                  value={props.booking?.signatoryUser?.arabicName}
                />
              )}
              <Item
                label="Signatory Mobile Number"
                value={`+971 ${props.booking?.signatoryUser?.mobile}`}
              />
              <Item
                label="Designation"
                value={props.booking?.signatoryUser?.designation}
              />
              <Item
                label="Company"
                value={props.booking?.signatoryUser?.company}
              />
            </Row>
          )}

          {props?.kycDetails?.length > 0 &&
            Object.keys(props.booking?.kycDetails || {})?.length > 0 && (
              <Row gutter={24} className="bd-item">
                {props?.kycDetails?.length > 0 &&
                  Object.keys(props.booking?.kycDetails)?.map((key) => {
                    if (props?.booking?.kycDetails?.[key])
                      return (
                        <Item
                          key={key}
                          label={getKycLabel(key, props?.kycDetails)}
                          value={key === 'dateofjoining' ?  apiDateFormat(props?.booking?.kycDetails?.[key],format):props?.booking?.kycDetails?.[key]}
                        />
                      );
                  })}
              </Row>
            )}
        </>
      )}
    </Col>
  );
};

export default index;
