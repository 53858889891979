export const GET_TENANT_DETAILS = "GET_TENANT_DETAILS";
export const GET_UNIT_DETAILS = "GET_UNIT_DETAILS";

export const GET_BOOKING_TERMS = "GET_BOOKING_TERMS";

export const GET_LEASE_NATURE = "GET_LEASE_NATURE";
export const GET_PARKING_SLOTS = "GET_PARKING_SLOTS";

export const SAVE_BOOKING_DRAFT = "SAVE_BOOKING_DRAFT";
export const GET_TENANT_CONTACTS = "GET_TENANT_CONTACTS";

export const GET_TERMS_CONDITIONS = "GET_TERMS_CONDITIONS";
export const GET_APPROVAL_HISTORY = "GET_APPROVAL_HISTORY";

export const GET_BOOKING_DOCS = "GET_BOOKING_DOCS";
export const GET_BOOKING_DETAILS = "GET_BOOKING_DETAILS";
export const GET_BILLING_CHARGES = "GET_BILLING_CHARGES";
export const GET_PAYMENT_MODES = "GET_PAYMENT_MODES";
