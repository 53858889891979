import React, { useEffect } from "react";
import { Form, Row, Col, Button, message } from "antd";
import { ReactSelectField, TextAreaField } from "../../../../atoms/FormElement";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

const RejectPopup = (props) => {

  const { onClose, reasonsData, handleReject,label,type } = props;
  const { control, formState: { errors }, setValue, handleSubmit } = useForm();

  const handleRejection = async (data) => {
    let payload = {
      "reason": data?.reason?.value,
      "comments": data?.rejectReason
    };

    handleReject && await handleReject(payload);
  };

  const buttonTitle = ()=>{
    if(props?.type == "cancel"){
      return "Cancel";
    }else if(props?.type === "decline_renewal"){
      return "Submit";
    }else{
         return "Reject";
    }
  
  }
  return (
    <Form layout="vertical" onFinish={handleSubmit(handleRejection)}>
      <Row gutter={24}>
        {reasonsData?.length > 0 && (
          <Col span={props?.type === "decline_renewal" ? 24 : 12}>
            <ReactSelectField
              label={label ? label : "Select a reason"}
              fieldname="reason"
              iProps={{ placeholder: "Select reasons" }}
              initValue=""
              setValue={setValue}
              isRequired={true}
              control={control}
              rules={{ required: "Required" }}
              validate={errors.reason && "error"}
              validMessage={errors.reason && errors.reason.message}
              selectOption={reasonsData}
            />
          </Col>
        )}
       {props?.type !== "decline_renewal" && 
       <Col span={24}>
          <TextAreaField
            label={`Enter a comment for ${props?.type == "cancel" ? "Cancellation" : "rejection"}:`}
            fieldname="rejectReason"
            iProps={{ placeholder: "Some reason...." }}
            initValue=""
            setValue={setValue}
            control={control}
          />
        </Col>}
      </Row>
      <Row gutter={24} justify="space-between">
        <Col>
          <Button size="large" onClick={onClose}>
            Back
          </Button>
        </Col>
        <Col>
          <Button size="large" className="ag-red-button" htmlType="submit">
      {      buttonTitle()}
          
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default RejectPopup;
