export const normalizeTenantDetails = (data) => ({
  tenantName: data?.first_name || "",
  nationality: data?.contact_info?.nationality?.name || "",
  number: data?.contact_info?.contact_number || "",
  maritalStatus: data?.marital_status || "",
  email: data?.contact_info?.email,
  pobox: data?.contact_info?.zipCode || "",
});

export const normalizeTenantContacts = (data = []) => {
  let result = [];
  if (Array.isArray(data) && data && data?.length) {
    result = data?.map((item) => ({
      label: item.first_name,
      value: item.first_name,
    }));
  }
  return result;
};

export const normalizeTermsConditions = (data = []) => {
  let result = [];
  if (data.length) {
    result = data.map((item) => ({
      label: item.name,
      value: item.name,
    }));
  }
  return result;
};

export const normalizeDraftData = (data) => ({
  lead_id: data.leadId,
  lease_terms: {
    lease_nature: {
      value: data.leaseNature,
    },
    paid_parking_slots: {
      value: data.paidParkingSlot,
    },
    free_parking_slots: {
      value: data.freeParkingSlot,
    },
    lease_duration: {
      commencement_date: data.leaseStartDate,
      expiry_date: data.leaseExpiryDate,
    },
    rent_free: {
      start_date: data.rentFreeStartDate,
      end_date: data.rentFreeEndDate,
    },
  },
  tenant_details: {
    number_of_occupants: {
      value: data.numberOccupants,
    },
    contacts_name: {
      value: data.contactsName,
    },
  },
  unit_details: {
    lease_id: data.leaseId,
    dewa_details: data.dewa,
  },
  booking_terms: {
    annual_base_rent_amount: {
      amount: data.baseRent,
    },
    numberOfInstallments: {
      amount: data.numberInstallements,
    },
    non_refundable_amount: {
      amount: data.nonrefundableAmount,
    },
    booking_duration: {
      start_date: data.bookingStartDate,
      end_date: data.bookingEndDate,
    },
    move_in_date: {
      value: data.moveInDate,
    },
    terms_and_condition_entity: {
      value: data.termsCondition,
    },
  },
});
