import React from "react";

// others
import { ItemCard } from "../components";
import { Col, Row, Spin, Space } from "antd";
import {
  apiDateFormat,
  findLeaseStatus,
  numberWithCommas,
  leaseExecutiveName,
} from "./utils";
import { formatString } from "../../../../utils/formatString";
import {
  Bed,
  Measure,
  History,
  Apartment,
  Residential,
} from "../../../../assets/svg";
import { statusLabel } from "../../../../utils/media";

interface IProps {
  booking: any;
  renewal?: boolean;
  handleChangeHistory: (key: string) => void;
}

var _ = require("lodash");

const index = (props: IProps) => {
  // variables
  const isBooking = props?.booking && Object.keys(props?.booking)?.length > 0;

  const rowStyle = { marginLeft: 0, marginRight: 0 };
  const rowStyleWithTopmargin = { ...rowStyle, ...{ marginTop: "16px" } };
  const isLeaseManager =
    Object.keys(props.booking?.leaseManager || {}).length > 0;
  const isLeaseAdmin =
    Object.keys(props.booking?.adminManager || {}).length > 0;

  const diffLeaseStatus = () => {
    if (props.booking?.renewalStatus === "renewal_accepted")
      return props.booking?.leaseStatus;
    return props.booking?.renewalStatus || props.booking?.leaseStatus;
  };

  const label = () => {
    if (props?.renewal || props?.booking?.leaseType === "renewal") return "Renewal Id";
    else return "Booking Id";
  }


  
  // Example usage:
  // const formattedString = formatString('moveout_confirm');
  return (
    <Col span={24} className="cc-main view-booking">
      {!isBooking && (
        <Row gutter={24} className="loading">
          <Spin size="large" />
        </Row>
      )}

      {isBooking && (
        <>
          <Row gutter={12} style={rowStyle} className="row">
            <p className="ft-main-label">
              {`${props.booking?.propertyDetails?.unitNumber} ${props.booking?.propertyDetails?.propertyName} | ${props.booking?.propertyDetails?.unitCode}${props?.booking?.bookingId ? ` | ${label()} - ${props.booking?.bookingId}` : ``}`}
            </p>
            <div>
              <p className="date">
                {apiDateFormat(
                  props?.booking?.leaseTerms?.leaseStartDate,
                  "MMM-DD-YYYY"
                )}
                {" - "}
                {apiDateFormat(
                  props?.booking?.leaseTerms?.leaseEndDate,
                  "MMM-DD-YYYY"
                )}
              </p>
              {/* according to story 8455 if vacating date have */}
              {
                props?.booking?.vacatingDate &&

                <p className="date">
                  ( Vacating {apiDateFormat(props?.booking?.vacatingDate, "MMM-DD-YYYY")} )
                </p>
              }

            </div>

          </Row>

          <Row gutter={12} style={rowStyleWithTopmargin} className="row">
            <Row>
              <ItemCard
                label={props?.booking?.propertyDetails?.usagesType}
                icon={Residential}
              />
              <ItemCard
                label={props?.booking?.propertyDetails?.unitType}
                icon={Apartment}
              />
              {props?.booking?.propertyDetails?.unitSubType?.includes("BR") && (
                <ItemCard
                  label={`${props?.booking?.propertyDetails?.unitSubType?.split("BR")[0]
                    } Bedroom`}
                  icon={Bed}
                />
              )}
              <ItemCard
                label={`${props?.booking?.propertyDetails?.areaSqft} sqft`}
                icon={Measure}
              />
            </Row>

            {props?.booking?.bookingTerms?.annualBaseRent && (
              <Row gutter={12} className="row">
                <p className="amount">{`AED ${numberWithCommas(
                  props?.booking?.bookingTerms?.renewalAmount ||
                  props?.booking?.bookingTerms?.annualBaseRent
                )} / `}</p>
                <p className="year">Year</p>
              </Row>
            )}
          </Row>

          <Col span={24} className="ft-divider" />

          <Row gutter={16} style={rowStyle} className="row">
            <Row style={{ alignItems: "center" }}>
              {props.booking?.leaseManager?.name && (
                <>
                  <Row className="user">
                    <p className="user-label">
                      {leaseExecutiveName(props.booking?.leaseManager?.name)}
                    </p>
                  </Row>

                  <p className="user-name">
                    {props.booking?.leaseManager?.name}
                  </p>
                  <p className="user-executive">{`( Lease Executive )`}</p>
                </>
              )}
              {props.booking?.adminManager?.name && (
                <>
                  <Row className="user">
                    <p className="user-label">
                      {leaseExecutiveName(props.booking?.adminManager?.name)}
                    </p>
                  </Row>

                  <p className="user-name">
                    {props.booking?.adminManager?.name}
                  </p>
                  <p className="user-executive">{`( Property Admin )`}</p>
                </>
              )}


              <Space
              
                className="history-container"
                onClick={() => props.handleChangeHistory("11")}
                style={{
                  letterSpacing: "1.2px",
                  marginLeft: !isLeaseManager ? 0 : "48px",
                }}
              >
                <History
                  width="18px"
                  height="18px"
                  style={{ marginTop: "4px", marginRight: "4px" }}
                />

                <p className="history-label">Change History</p>
              </Space>
            </Row>

            <Row gutter={8} className="status-container">
              <p className="status-label">
                {
              
              formatString(statusLabel(props.renewal && !props.booking?.renewalStatus ? "to_review" : diffLeaseStatus(), props?.booking?.leaseStatusHistory))   
                  ||
                  formatString(statusLabel(diffLeaseStatus(), props?.booking?.leaseStatusHistory))
                }
              </p>
            </Row>
          </Row>
        </>
      )}
    </Col>
  );
};

export default index;
