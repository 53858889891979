import React, { useState, useEffect } from "react";
import DashboardLayout from "../../molecules/DashboardLayout";
import {
  Row,
  Col,
  Pagination,
  Card,
  Button,
  Select,
  message,
  Typography,
  Tag,
} from "antd";
import SectionHeader from "../../molecules/SectionHeader";
import Grid from "../../atoms/Grid";
import { useDispatch, useSelector } from "react-redux";
import { formatString } from "../../../utils/formatString";
import { getContactList, setFilters } from "./ducks/actions";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { Popup } from "../../atoms/Popup";
import {
  sendTenantSignature,
  draftEjariRenewal,
  cancelEjariRenewal,
  downloadEjariRenewal,
  ejariRenewal,
  downloadDraftEjari,
} from "./ducks/services";

import dayjs from "dayjs";
import { dateByformat } from "../../../utils/dateHelper";
import { currencyFormat, statusLabel } from "../../../utils/media";
import { useSearchHistory } from "../../../utils/filterHelper";
import { setFilterRenewal } from "../App/SearchFilters/ducks/actions";

import { getPendingRenewals } from "./ducks/services";
import { findLeaseStatus } from "../BookingForm/components/utils";
import { PendingRenewals } from "../BookingForm/components/modalContent";
import LoaderTsx from "../Loader.tsx";
const { Title } = Typography;

const gridProps = {
  sortable: true,
  filter: true,
  resizable: true,
  minWidth: 220,
};

const dropDown = [

  { id: 2, name: "Renewal Form", status: "" },
  { id: 3, name: "Send To Tenant", status: "" },
  { id: 4, name: "Cancel", status: "#FB7171" },
];
const dropDown1 = [

  { id: 2, name: "Renewal Form", status: "" },
];
const dropDown2 = [

  { id: 2, name: "View Confirmed Form", status: "" },
  
];
const dropDown3 = [
  { id: 4, name: "View Approved Form", status: "" },
];

const searchKeys = [
  { value: "name", label: "Name" },
  { value: "unitCode", label: "Unit Number", default: true },
  { value: "property", label: "Property" },
  { value: "propertyAdmin", label: "Property Admin" },
];

const Contracts = (props) => {

  const dispatch: any = useDispatch();
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(50);
  const [visible, setVisible] = useState(false);
  const [searchKey, setSearchKeys] = useState(searchKeys);
  const[isLoading,setIsLoading] = useState(false);
  const[exportData,setExportData] = useState(false);
  const renewalFilters = useSelector(
    (state: any) => state.contracts.renewalFilters
  );

  const { searchHistory } = useSearchHistory(setFilterRenewal);
  const [searchBy, setSearchBy] = useState(renewalFilters?.searchBy || "unitCode");
  const [search, setSearch] = useState(renewalFilters?.search ?? "");
  const contractApi = useSelector((state: any) => state.contracts.contractList);

  const isAgp = JSON.parse(window.localStorage.getItem("login-response"))?.isAgp;
  


  const nonAgpFilterKeys = [
    { label: "To Review", value: "to_review" }, // removed as per feedbacks from abbas
    { label: "Sent for Approval", value: "sent_for_approval" },
    { label: "Waiting to Send", value: "ready_to_send" },
  ]
  const optionsAgp = [
    //-------------------------------
    { label: "Sent to tenant", value: "sent" },
    { label: "Renewal Declined", value: "renewal_declined" },
    { label: "Move-out Confirmed", value: "moveout_confirmed" },
    { label: "Show All", value: "all" },
    
  ]
  const optionsForStatus = isAgp === "yes" ? optionsAgp : [...nonAgpFilterKeys, ...optionsAgp];

  const [filterOptions, setFilterOptions] = useState([
    {
      name: "filter",
      label: "Filter",
      initialValue: renewalFilters?.filter || 150,
      title: "1",
      options: [
        { label: "Next 150 days", value: 150 },
        { label: "Next 120 days", value: 120 },
        { label: "Next 90 days", value: 90 },
        { label: "Next 60 days", value: 60 },
        { label: "Next 30 days", value: 30 },
        { label: "Expired", value: "expired" },

      ],
    },
    {
      name: "status",
      label: "Status",
      initialValue: renewalFilters?.filterStatus || "sent",
      title: "2",
      options: optionsForStatus,
    },
  ]);

  const [filter, setFilter] = useState(filterOptions[0].initialValue);
  const [filterStatus, setStatusFilter] = useState(filterOptions[1].initialValue);

  const onAction = async (action, id) => {
    setLoading(true);
    if (action === "Download") {
      try {
        let res = await downloadEjariRenewal(id);
        if (
          res.data.result.imageUrl != "https://s3.ap-south-1.amazonaws.com/"
        ) {
          setLoading(false);
          const win = window.open(res.data.result.imageUrl, "_blank");
          win.focus();
        } else {
          setLoading(false);
          onAction("Download", id);
        }
      } catch (e) {
        setLoading(false);
        const { response } = e;
        message.error(response.data.message);
      }
    } else if (action == "Cancel") {
      const body = {
        lease_id: id,
      };
      cancelEjariRenewal(body)
        .then(() => {
          setLoading(false);
          message.success(`${action} successfully`);
        })
        .then(() => {
          setLoading(false);
          setIsLoading(true)

          dispatch(getContactList(limit, currentPage, search, searchBy,null,null,setIsLoading));
        })
        .catch((error) => {
          setLoading(false);
          message.error(error?.response?.data?.message);
        });
    } else if (action === "Send") {
      const body = {
        lease_id: id,
        type: "ejari_contract_renewed",
      };
      sendTenantSignature(body)
        .then(() => {
          message.success(`${action} successfully`);
          setLoading(false);
        })
        .then(() =>{
          setIsLoading(true)
          
          dispatch(getContactList(limit, currentPage, search, searchBy,null,null,setIsLoading))
        })
        .catch((error) => {
          setLoading(false);
          message.error(error?.response?.data?.message);
        });
    } else if (action === "Draft") {
      const body = {
        lease_id: id,
      };
      draftEjariRenewal(body)
        .then(() => {
          setLoading(false);
          message.success(`${action} successfully`);
        })
        .then(() =>{
          setIsLoading(true)
          dispatch(getContactList(limit, currentPage, search, searchBy,null,null,setIsLoading));
        })
        .catch((error) => {
          setLoading(false);
          message.error(error?.response?.data?.message);
        });
    } else if (action === "Renew") {
      const body = {
        lease_id: id,
      };
      ejariRenewal(body)
        .then(() => {
          setLoading(false);
          message.success(`${action} successfully`);
        })
        .then(() =>
         {
          setIsLoading(true)
          dispatch(getContactList(limit, currentPage, search, searchBy,null,null,setIsLoading))
         }
        )
        .catch((error) => {
          setLoading(false);
          message.error(error?.response?.data?.message);
        });
    } else if (action === "DraftDownload") {
      try {
        let res = await downloadDraftEjari(id);
        if (
          res.data.result.imageUrl != "https://s3.ap-south-1.amazonaws.com/"
        ) {
          setIsLoading(true)
          dispatch(getContactList(limit, currentPage, search, searchBy,null,null,setIsLoading)),
            setLoading(false);
          const win = window.open(res.data.result.imageUrl, "_blank");
          win.focus();
        } else {
          setLoading(false);
          onAction("DraftDownload", id);
        }
      } catch (e) {
        setLoading(false);
        const { response } = e;
        message.error(response.data.message);
      }
    } else if (action === "Create") {
      setLoading(false);
      setVisible(true);
    }
  };

  const MoreAction = (row) => {
    const navigate: any = useNavigate();
    const { Text } = Typography;
    const onChnageHanldler = async (e) => {
      if (e === "Cancel") {
      } else if (e === "Add Unit") {
      } else if (e === "Download booking form") {
      } else if (e === "View Confirmed Form") {
        navigate(`/renewals/form/${row.data.leaseId}`);
      } else if (e === "View Approved Form") {
        navigate(`/renewals/form/${row.data.leaseId}`);
      } else if (e === "Renewal Form") {
        navigate(`/viewbookingform/${row.data.leaseId}`, {
          state: { renewal: true },
        });
      }
    };

    return (
      <Select
        onChange={onChnageHanldler}
        className="Sentence"
        placeholder="Select an action"
        style={{ width: 150 }}
        allowClear
      >
        <>
          {row.data.leaseStatus === "created" &&
            dropDown.map((value, key) => (
              <React.Fragment key={key}>
                <Select.Option value={value.name}>
                  <Text style={{ color: `${value.status}` }}>{value.name}</Text>
                </Select.Option>
              </React.Fragment>
            ))}
          {row.data.leaseStatus !== "created" &&
            row.data.leaseStatus !== "booking_confirmed" &&
            row.data.leaseStatus !== "booking_approved" &&
            dropDown1.map((value, key) => (
              <React.Fragment key={key}>
                <Select.Option value={value.name}>
                  <Text style={{ color: `${value.status}` }}>{value.name}</Text>
                </Select.Option>
              </React.Fragment>
            ))}
          {row.data.leaseStatus === "booking_confirmed" &&
            dropDown2.map((value, key) => (
              <React.Fragment key={key}>
                <Select.Option value={value.name}>
                  <Text style={{ color: `${value.status}` }}>{value.name}</Text>
                </Select.Option>
              </React.Fragment>
            ))}
          {row.data.leaseStatus === "booking_approved" &&
            dropDown3.map((value, key) => (
              <React.Fragment key={key}>
                <Select.Option value={value.name}>
                  <Text style={{ color: `${value.status}` }}>{value.name}</Text>
                </Select.Option>
              </React.Fragment>
            ))}
        </>
      </Select>
    );
  };

  const contractsCol = [
    {
      width: 250,
      headerName: "Renewal Status",
      field: "renewalStatus || leaseStatus",
      cellRendererFramework: ({ data }) => findLeaseStatus[data?.renewalStatus ||(data.leaseStatus && data.leaseStatus === "active"? "to_review": data.leaseStatus !== "active" && data?.leaseStatus)],
    },

    {
      headerName: "Property",
      field: "propertyDetails.propertyName",
    },
    {
      headerName: "Unit Number",
      field: "propertyDetails.unitCode",
    },
    {
      headerName: "Tenant",
      field: "tenantDetails.tenantNameEN",
      cellStyle: { textTransform: "CAPITALIZE" },
    },
    {
      headerName: "Property Admin",
      field: "adminManager.name",
    },
    {
      headerName: "Current Rent",
      field: "leaseTerms.contractAmount",
      cellRendererFramework: ({ data }) => {
        return currencyFormat(data.bookingTerms?.annualBaseRent || data.leaseTerms.contractAmount) || ""
      },
    },
    {
      headerName: "Renewal Rent",
      field: "leaseTerms.renewalRent",
      cellRendererFramework: ({ data }) => {
        return currencyFormat(data.bookingTerms?.renewalAmount) || ""
      },
    },
    {
      headerName: "Change",
      field: "leaseTerms.change",
      cellRendererFramework: ({ data }) => {
        const { bookingTerms, leaseTerms } = data;

        const {
          renewalAmount,
          annualBaseRent,
          changeValue,
          amountType,
          amountChange: percentAmount,
        } = bookingTerms || {};
        
        const contractAmount = leaseTerms?.contractAmount;
        
        const calculateAmountToFormat = () => (
          changeValue !== 'no_change' && renewalAmount ? renewalAmount - annualBaseRent || contractAmount : 0
        );
        
        const formatPercentage = () => (
          changeValue === 'no_change' ? "0" : `${changeValue === 'decrease' ? '-' : ''}${percentAmount}%`
        );
        
        const amountToFormat = calculateAmountToFormat();
        const percentage = formatPercentage();
        
        return amountType === 'percentage' ? percentage : currencyFormat(amountToFormat) || "0";
    
      },
    },
    {
      headerName: "Payments",
      field: "leaseTerms.noOfCheques",
    },
    {
      headerName: "Start Date",
      field: "leaseTerms.leaseStartDate",
      minWidth: 150,
      cellRendererFramework: ({ data }) => {
        return dateByformat(data.leaseTerms.leaseStartDate, "DD-MM-YYYY");
      },
    },
    {
      sort: "asc",
      headerName: "End Date",
      field: "leaseTerms.leaseEndDate",
      minWidth: 150,
      cellRendererFramework: ({ data }) => {
        return dateByformat(data.leaseTerms.leaseEndDate, "DD-MM-YYYY");
      },
    },
    {
      headerName: "Actions",
      field: "m_action",
      filter: false,
      maxWidth: 200,
      cellRendererFramework: MoreAction,
      pinned:'right'
    },
    { 
      headerName:"Declined Reason",
      field:"leaseStatusHistory",
      hide: filterStatus === 'renewal_declined' || filterStatus === 'all' ? false :true,
      cellRendererFramework: ({ data }) => {
        let statusData = [...data?.leaseStatusHistory];
        statusData.reverse();
         const dataResult =  statusData?.find((item) => item?.status === 'renewal_declined');  
        return (
          <div>
            {dataResult?.reason ? formatString(dataResult?.reason) : ""}
          </div>
        );
      },
    }, 
  ];

  const handlePagination = (page, pageSize) => {
    setCurrentPage(page);
    setLimit(pageSize);
    const params = { renewalDate: filter, renewalStatus: filterStatus };
    setIsLoading(true)
    dispatch(getContactList(pageSize, page, search, searchBy, params,null,setIsLoading));
  };

  useEffect(() => {
    if (renewalFilters?.searchBy) {
      const setArray = searchKey.map((item) => {
        let val = null;
        if (item.value === renewalFilters?.searchBy) {
          val = { ...item, default: true };
        } else {
          val = { ...item, default: false };
        }
        return val;
      });
      setSearchKeys(setArray);
    }
  }, [renewalFilters, renewalFilters?.searchBy]);

  useEffect(() => {
    if (
      renewalFilters?.search != search ||
      renewalFilters?.searchBy != searchBy ||
      renewalFilters?.filter != filter ||
      renewalFilters?.filterStatus != filterStatus
    )

    dispatch(setFilters({ search, searchBy, filter, filterStatus, currentPage }));
    const params = { renewalDate: filter, renewalStatus: filterStatus };
    if(exportData){
      params['exportData'] = exportData;
      params['totalCount'] = contractApi?.totalCount;
    }
    setCurrentPage(1);
    setIsLoading(true)
    dispatch(getContactList(limit, 1, search, searchBy, params,setExportData,setIsLoading));
  
  }, [search, searchBy, filter, filterStatus,exportData]);

  useEffect(() => {
    let key = Object.keys(searchHistory)[0];
    if (searchKey.length > 0)
      searchKey.map((item) => {
        if (key == item.value) item["default"] = true;
        else item["default"] = false;
        return item;
      });
  }, [searchHistory]);

  function onTopSearch(e) {
    if (e?.length === 0) setSearch("");
    else if (e?.length < 2)
      message.error("Please enter atleast two characters");
    else setSearch(e);
  }

  function onTopSearchBy(e) {
    setSearchBy(e?.value);
  }

  function handleFilterChange(days) {
    if (typeof days.key === "number") {
      setFilter(days.value);
    } else if (days.value === "expired") setFilter(days.value);
    else {
      setStatusFilter(days.value);
    }
  }

  const [pendingRenewals, setPendingRenewals]: any = useState({});

  useEffect(() => {
    _getPendingRenewals();
  }, []);

  const _getPendingRenewals = async () => {
    try {
      const response = await getPendingRenewals();
      if (response.data?.result?.data.length > 0)
        setPendingRenewals(response.data?.result);
    } catch (e) {
      console.log({ e });
    }
  };

  const popupProps = {
    closable: true,
    visibility: visible,
    title: (
      <Title level={3} className="mb-0">
        Renewal Approval Request(s)
      </Title>
    ),
    content: <PendingRenewals renewals={pendingRenewals?.data} />,
    width: "80%",
    onCancel: () => setVisible(false),
  };

  const filterData = (data: Array<any> = []) => {
    const dataCopy = [...data];

    if (dataCopy.length > 0) {
      for (let key in dataCopy) {
        if (
          dataCopy[key].leaseType === "new" &&
          dataCopy[key].leaseStatus === "active"
        ) {
          const leaseTerms = {
            ...dataCopy[key].leaseTerms,
            ...{
              leaseStartDate: dayjs(dataCopy[key].leaseTerms.leaseEndDate)
                .add(1, "day")
                .toISOString(),
              leaseEndDate: dayjs(dataCopy[key].leaseTerms.leaseEndDate)
                .add(1, "year")
                .toISOString(),
            },
          };

          dataCopy[key]["leaseTerms"] = {
            ...dataCopy[key]["leaseTerms"],
            ...leaseTerms,
          };
        }
      }
    }

    return dataCopy;
  };

  //  for export data and send in email 

  const exportAction = ()=>{
    setExportData(true)
  }
  return (
    <DashboardLayout load={loading}>
      <Row gutter={[32, 32]}>
        <Col span={24}>
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <SectionHeader
                noBorder={true}
                heading="Upcoming Renewals"
                total={contractApi?.totalCount ?? 0}
                search={(e) => onTopSearch(e)}
                searchBy={(e) => onTopSearchBy(e)}
                searchOptions={searchKey}
                defaultSearch={search}
                viewOption={filterOptions}
                viewOptionLabel="Show leases renewing in"
                justifiedItems={true}
                justifyEnd={true}
                downloadAction={exportAction}
                handleView={handleFilterChange}
                headTopLeftText={
                  pendingRenewals?.totalCount ? (
                    <Button
                      danger
                      type="text"
                      onClick={() => setVisible(true)}
                    >
                      <InfoCircleOutlined /> {pendingRenewals?.totalCount}{" "}
                      pending requests
                    </Button>
                  ) : null
                }
              />
            </Col>
            <Col span={24}>
              <Pagination
                className="ag-property-text-pagination"
                total={contractApi?.totalCount ?? 0}
                showTotal={(total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} Renewals`
                }
                pageSize={contractApi?.perPage ?? 50}
                defaultCurrent={1}
                current={currentPage || renewalFilters?.currentPage}
                onChange={handlePagination}
              />
            </Col>
            <Col span={24}>
              <Row gutter={[24, 24]} justify="end">
                <Col span={24}>
                  <Card
                    bordered={false}
                    className="ag-nospace-body"
                 
                  >{
                    isLoading ?
                    <LoaderTsx/>
                    :
                    <Grid
                      columns={contractsCol}
                      pagination={false}
                      defaultSettings={gridProps}
                      noRowlabel="Contract"
                      fullPage={true}
                      data={filterData(contractApi?.data) ?? []}
                    />}
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Popup {...popupProps} />
    </DashboardLayout>
  );
};

export default Contracts;
