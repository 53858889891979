import React, { useState } from "react";
import { Select, Input, Button ,Checkbox} from "antd";
interface IProps {}

/**
* @author
* @function @
**/

 function BookingStatusFilter ({cancelledBookingCheck,bookingStatuses,bookingStatusFilter,bookingStatusValue,cancelledbookingCheckBox,setCancelledBookingCheckBox,bookingStatusTitle})

{
    const { Option } = Select;
    const [open, setOpen] = useState(false);

   const getCheckBoxValue = (checkBoxValue)=>{
    setCancelledBookingCheckBox(checkBoxValue);
    setOpen(false)
   }
       
  return ( 

   <div style={{display:'flex',flexDirection:'column'}}>
    <span style={{color:'gray'}}>{bookingStatusTitle}</span>
    <Select
      defaultValue="All"
      bordered={true}
      labelInValue
      suffixIcon={null} 
      notFoundContent={
        <div style={{ textAlign: 'center', color: '#888',position:'absolute',zIndex:'-1px' }}>
        No data found
      </div>
      } // Custom "No Data" placeholder with reduced height
      showSearch
      allowClear
      filterOption={(input:any, option:any) => {
      
          return (option?.children ?? '')?.toLowerCase().includes(input.toLowerCase())
      }}
      size="large"
      onChange={(e)=>bookingStatusFilter(e)}
      open={open}
      onDropdownVisibleChange={(visible) => setOpen(visible)}
      dropdownRender={(menu) => (
        <div>
          {menu}
         {
         cancelledBookingCheck && ( bookingStatusValue?.value === 'all' || !bookingStatusValue) &&
            <div style={{padding:'10px'}}>
           <Checkbox checked={cancelledbookingCheckBox} onChange={(e)=> getCheckBoxValue(e.target?.checked)}>Show Cancelled Bookings</Checkbox>
           </div>
         }
        </div>
      )}
    >
        {
        bookingStatuses?.map((item,index)=>  <Option key={item?.value+ index} value={item.value}>{item.label}</Option>)
     }
    </Select>
   </div>
   )
 }
export default React.memo(BookingStatusFilter);