import React, { useEffect, useState } from "react";
import { Typography, Form, Card, TabsProps, Tabs, message } from "antd";
import { useForm } from "react-hook-form";
import { payment_plans } from "../../../BookingForm/components/utils";
import InstallmentPlan from "./components/InstallmentPlan";
import { leaseService } from "../../../../../configs/constants";
import axios from "axios";

const { Title } = Typography;

export default ({ propertyDetails, updateApi }) => {

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm();

  const { paymentPlan } = propertyDetails;

  const [addPlans, setAddPlans] = useState([]);

  const [plansData, setPlansData] = useState([]);
  
  const [unitType,setUnitType] = useState('yes');

  useEffect(() => {

    const result = payment_plans.map(plan => {
      const matchingItem = plansData?.find(item => Number(item.installments) === plan);
      // console.log("MATCHED",matchingItem);
      return matchingItem
        ? matchingItem
        : {
          installments: plan,
          annualRent: 0,
          noOfYear: 0,
          securityDeposit: 0,
         
        };
    });
 
    
    setAddPlans(result);
  }, [plansData]);

  useEffect(() => {
    getPlansData();
  }, [paymentPlan]);


  const getPlansData = () => {
    const furnished = getValues("furnished");
    
    if (paymentPlan?.length > 0) {
      const plans = paymentPlan
        .filter(item => (item?.furnished)?.toLowerCase() == furnished)
        .sort((a, b) => a.installments - b.installments);
      
      setPlansData(plans);
    } else {
      setPlansData([]);
    }
  }


  const items: TabsProps['items'] = [
    {
      key: 'yes',
      label: <Title level={5} className="ag-primary mb-0">
        Furnished
      </Title>,
      // children: <InstallmentPlan addPlans={addPlans} />,
    },
    {
      key: 'no',
      label: <Title level={5} className="ag-primary mb-0">
        Unfurnished
      </Title>,
      // children: <InstallmentPlan addPlans={addPlans} />,
    },
  ];

  const onFurnishchange = (e) => {
    setUnitType(e)
    setValue("furnished", e)
    getPlansData();
  }


  const onSubmit = async (val) => {

    const unitId = propertyDetails?.unitId;
    const unitCode = propertyDetails?.unit?.unitCode;
    
   
    let body = val.plan?.map(item => ({
      "unitCode": unitCode || "",
      "furnished": val?.furnished,
      "installments": item?.installments,
      "annualRent": +item?.annualRent,
      "vatOnRent": "NO",
      "securityDeposit": +item?.securityDeposit,
      "noOfYear": item?.noOfYear,
      "vatOnSecurityDeposit": "NO",
      "unitId": unitId || ""
    })).filter(item => item?.annualRent && item?.securityDeposit)
    
    console.log(body,'body')
    if (body?.length>0) {
      message.loading("Saving");
      try {
        const url = `${leaseService}/unit/addPaymentPlan`;
        await axios.post(url, body);
        message.destroy();
        message.success("Updated payment plans");
        updateApi();
      } catch (e) {
        message.destroy();
        const { response } = e;
        console.log("reponse", response);
        message.error(response?.data?.error);
      }
    }else message.error("No plans data added");
  };


  return (
    <>
      <Form
        layout="vertical"
        name="paymentPlans"
        onFinish={handleSubmit(onSubmit)}
      >
        <Card
          bordered={false}
          bodyStyle={{ padding: "16px 32px 0" }}
          headStyle={{ padding: "16px 32px 0", borderBottom: 0 }}
         
          className="no-style-tab"
        >

          <Tabs defaultActiveKey="yes" items={items} onChange={onFurnishchange} />
          {/* onChange={onChange} */}
          <InstallmentPlan unitType={unitType} addPlans={addPlans} control={control} setValue={setValue} />
        </Card>
      </Form>
    </>
  );
};
