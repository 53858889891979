import React, { useEffect } from "react";
import { Row, Col, Card, Tabs } from "antd";
import { useDispatch, useSelector } from "react-redux";
import SideDetail from "../SideDetail";
import BreadCrumb from "../../../../atoms/BreadCrumb";
import DashboardLayout from "../../../../molecules/DashboardLayout";

import { getSingleUnit } from "../ducks/actions";
import { useLocation } from "react-router-dom";
// import EarningsChart from "../EarningsChart";
import UnitInfo from "../UnitInfo";
import NewPaymentPlan from "../NewPaymentPlan";

const { TabPane } = Tabs;

export default (props) => {
  const dispatch: any = useDispatch();
  const location = useLocation();
  const parentId = location.pathname?.split("/")[2];
  const unitId = location.pathname?.split("/")[4];
  let unitDetailsApi: any = {};

  unitDetailsApi = useSelector((state: any) => state.propertyDetails.unitData);

  useEffect(() => {
    dispatch(getSingleUnit(unitId));
  }, []);

  const onUpdate = () => {
    // dispatch(getSingleUnit(unitId));
  };

  const fullUnitName = unitDetailsApi?.unit ? location.state?.parentname + " " + unitDetailsApi?.unit.unitCode : "";

  const breadcrumbList: any = [
    {
      label: "Properties",
      route: "/properties",
    },
    {
      label: location.state?.parentname,
      route: `/property-detail/${parentId}`,
    }
    ,
    {
      label: fullUnitName ?? "",
      route: "",
    },
  ].filter(item => item);


  return (
    <DashboardLayout>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <BreadCrumb list={breadcrumbList} />
        </Col>
      </Row>

      <Row gutter={24} className="p_detail_tabs">
        <Col span={24}>
          {
            unitDetailsApi?._id &&
            <SideDetail
              propertyDetails={unitDetailsApi}
              updated={onUpdate}
              fullUnitName={fullUnitName}
              isUnit={true}
              parentName={location.state?.parentname}
            />}
        </Col>
        <Col span={24}>
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <Card
                bordered={false}
                className="ag-nospace-head ag-nospace-body h-auto"
              >
                <Tabs
                  defaultActiveKey="1"
                  animated={false}
                  tabBarGutter={16}
                  style={{ overflow: "unset" }}
                >
                  <TabPane key="1" tab="Unit Information">
                    {
                      unitDetailsApi?._id &&
                      <UnitInfo
                        propertyDetails={unitDetailsApi}
                        parentId={parentId}
                        updateApi={onUpdate}
                      />
                    }
                  </TabPane>
                  {
                    (location?.state?.usageType === 'Residential' || location?.state?.usageType === 'residential') &&
                    <TabPane key="2" tab="Payment Plan">
                      {/* <PaymentPlan propertyDetails={unitDetailsApi} updateApi={onUpdate} />, */}
                      <NewPaymentPlan propertyDetails={unitDetailsApi} updateApi={onUpdate} />
                    </TabPane>
                  }
                </Tabs>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </DashboardLayout>
  );
};
