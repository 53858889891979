import React, { useState, useEffect } from "react";
import { Row, Col, Card, Tabs } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  getSingleProperty,
  getSinglePropertyEmpty,
} from "../AddProperty/ducks/actions/addInfoAction";
import SideDetail from "./SideDetail";
import PropertyInfo from "./propertyInfoTab/index";
import BreadCrumb from "../../../atoms/BreadCrumb";
import DashboardLayout from "../../../molecules/DashboardLayout";
import PropertyUnits from "./PropertyUnits";
import { getParkingUnits, getPropertyUnits } from "./ducks/actions";
import ParkingUnits from "./ParkingUnits";
// import EarningsChart from "./EarningsChart";

const { TabPane } = Tabs;

export default () => {
  const dispatch: any = useDispatch();
  const propertyId = location.pathname?.split("/")[2];

  let propertyDetails:any = {};
  const unitsApi = useSelector((state: any) => state.propertyDetails?.unitsList);
 
  const parkingUnitsApi = useSelector((state: any) => state.propertyDetails.parkingUnitsList);
  const propertiesCardApi = useSelector((state: any) => state.dashboard.propertiesCard);
 
  propertyDetails = propertiesCardApi?.data?.find((property)=>property?._id == propertyId);

              
  // @ts-ignore
  useEffect(() => {
  
    return () => dispatch(getSinglePropertyEmpty());
  }, [propertyId]);





  const onUpdateProperty = () => {
    // dispatch(getSingleProperty(propertyId));
  };
  const onUpdateUnit = () => {
    // dispatch(getPropertyUnits(propertyId));
  };

  const breadcrumbList: any = [
    {
      label: "Properties",
      route: "/properties",
    },
    {
      label: propertyDetails?.buildingName,
      route: "",
    },
  ];

  const handleTabChange = (key) => {
    if(key == 1){
      propertyDetails = propertiesCardApi?.data?.find((property)=>property?._id == propertyId);
    }else if(key == 2){

      dispatch(getPropertyUnits(propertyDetails?.propertyId))
  
    }else{
      dispatch(getParkingUnits(propertyDetails?.propertyId))
    }
 

  };
  
  return (
    <DashboardLayout>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <BreadCrumb list={breadcrumbList} />
        </Col>
      </Row>

      <Row gutter={24} className="p_detail_tabs">
        <Col span={24}>
          {
            propertyDetails?._id &&  
            <SideDetail
            propertyDetails={propertyDetails}
            updated={onUpdateProperty}
            fullUnitName=""
            isUnit={false}
            parentName=""
          />
          }
        </Col>
        <Col xxl={24} xl={24} className="propertie_details_tabs">
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <Card
                bordered={false}
                className="ag-nospace-head ag-nospace-body h-auto p_detail_tab"
              >
                <Tabs
                   onChange={handleTabChange}
                  defaultActiveKey="1"
                  animated={false}
                  tabBarGutter={20}
                  style={{ overflow: "unset" }}
                >
                  <TabPane key="1" tab="Property Information">
                  {propertyDetails?._id &&  
                   <PropertyInfo
                      propertyDetails={propertyDetails}
                    
                    />}
                  </TabPane>
                  {propertyDetails?.propertyId  ? (
                    <TabPane key="2" tab="Property Units" className="property-units_tab">
                      <PropertyUnits
                        propertyDetails={propertyDetails}
                        propertyId={propertyId}
                        units={unitsApi}
                        updated={onUpdateUnit}
                      />
                    </TabPane>
                  ) : ""}

                  {propertyDetails?._id  ? (
                    <TabPane key="3" tab="Parking Units" className="property-units_tab">
                      <ParkingUnits
                        propertyDetails={propertyDetails}
                        propertyId={propertyId}
                        units={parkingUnitsApi}
                    
                      />
                    </TabPane>
                  ) : ""}


                </Tabs>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </DashboardLayout>
  );
};


