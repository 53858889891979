import * as action_types from "./constants";

const initialState = {
  getUnitData: {},
  getLeaseData: {},
  getBookingData: {},
  getTenantData: {},
  getPaymentData: {},
};

export default (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case action_types.GET_TENANT_DETAILS:
      return { ...state, getTenantData: data }
    case action_types.GET_UNIT_DETAILS:
      return { ...state, getUnitData: data }
    case action_types.GET_LEASE_TERMS:
      return { ...state, getLeaseData: data }
    case action_types.GET_BOOKING_TERMS:
      return { ...state, getBookingData: data }
    case action_types.GET_PAYMENT_SCHEDULE:
      return { ...state, getPaymentData: data }
    default:
      return state;
  }
};
