import React from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';
import dayjs from 'dayjs';
import { updateBooking } from '../../ducks/actions';
import { PopupConfirm } from "../../../../atoms/Popup";
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import {message} from 'antd'
import {useParams } from "react-router-dom";
import { getBookingDetail } from '../../ducks/actions';

 const index = ({bookingExpiryDate,requestExtension}) => {

  let defaultDate = dayjs(bookingExpiryDate);
  const dispatch = useDispatch();
  const { leaseId = "" } = useParams();
  const thunkDispatch: ThunkDispatch<true, void, AnyAction> = dispatch;


  const confirmBox =  (dateString)=>{
    PopupConfirm({
      title: `Your request to extend booking has been sent to a manager`,
      cancelText: "No",
      okText: "Yes",
      onOk: async () => {
      
        try{         
       // Use ThunkDispatch to type your dispatch
          const response = await thunkDispatch(updateBooking({leaseId,body: {"onlyBooking":true,"bookingExtensionDate": dateString}}));
            
            if(response?.data?.code === 200){
              message.success(response?.data?.message);
              await thunkDispatch(getBookingDetail(leaseId))
              requestExtension(false);
             
            }
        }catch(error){
    
          if(error?.response?.data?.success === false){
            message.error(error?.response?.data?.error)
          }
        }
      },
    });
  }
  const onChange =  (dateString) => {
    
    if(defaultDate === dateString || dateString === ""){
      return false
    }else{
       confirmBox(dateString);
   }

  };
  // Define the custom start date
  const startDate = moment(bookingExpiryDate);

  const disabledDate = (current) => {
    // Calculate seven days from the start date
    const sevenDaysLater = startDate.clone().add(6, 'days').endOf('day');
    
    // Disable dates before the start date or after seven days from the start date
    return current && (current < startDate || current > sevenDaysLater);
  };
  

  return (
    <div style={{display:'flex',justifyContent:'center',flexWrap:'wrap'}}>
     <p style={{margin:'4px'}}>Select New Booking End Date: </p> 
    <DatePicker 
     allowClear={false} // Disable the clear option
     defaultValue={defaultDate}
     placeholder='Select New Booking Date'
     onChange={onChange}
     disabledDate={disabledDate}
     style={{width:'250px'}}
      />
    </div>
   )
 }
export default index;