import * as action_types from "./constants";

const initialState = {
  ejariList: {
    count: 0,
    results: [],
  },
};
export default (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case action_types.GET_EJARI:
      return { ...state, ejariList: data };
    default:
      return state;
  }
};
