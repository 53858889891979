import React, { useEffect } from "react";
import { Form, Row, Col, Button ,message} from "antd";
import { InputField } from "../../../../atoms/FormElement";
import { useForm } from "react-hook-form";
import { updateBooking } from "../../ducks/actions";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getBookingDetail } from "../../ducks/actions";

const AddContractNumber = (props) => {
  const { onClose, handleUpdate } = props;
  const { control, formState: { errors }, setValue, handleSubmit } = useForm();
  const { leaseId  } = useParams();
  const dispatch = useDispatch<any>();

  const handleFormSubmit = async (data) => {

    if(props?.createEjariNumber){
      try {

        const response = await dispatch(
          updateBooking({
            leaseId,
            body: {
              "ejariContractNumber": data?.contractNumber
            }
          }));
        if (response?.data?.code === 200) {
          dispatch(getBookingDetail(leaseId));
          onClose();
          message.success(response?.data?.message);
  
        }
      } catch (error) {
      
        if (error?.response?.data?.success === false) {
          message.error(error?.response?.data?.error)
          onClose()
        }
      }
    }else{
   
      let payload = {
        "contractNumber": data?.contractNumber,
      };
      handleUpdate && await handleUpdate(payload);
      onClose()
    }
   
  };

  return (
    <Form layout="vertical" onFinish={handleSubmit(handleFormSubmit)}>
      <Row gutter={24}>
        <Col span={24}>
          <InputField
            label="Ejari Contract Number"
            fieldname="contractNumber"
            iProps={{ placeholder: "Enter number" }}
            initValue=""
            setValue={setValue}
            isRequired={true}
            control={control}
            rules={{
              pattern: {
                value: /^.{1,22}$/,
                message: "Max length 22",
              },
              required: "Required",
            }}
           
            validate={errors.contractNumber && "error"}
            validMessage={errors.contractNumber && errors.contractNumber.message}
          />
        </Col>
      </Row>
      <Row gutter={24} justify="space-between">
        <Col>
          <Button size="large" className="ef-discard-btn" onClick={onClose}>
            Back
          </Button>
        </Col>
        <Col>
          <Button size="large" className="ef-approve-btn" htmlType="submit">
            Save
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default AddContractNumber;
