import * as action_types from "../constants";

const initialState = {
  activateSpinner: false
};

export default (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    // Payment page data
    case action_types.ACTIVATE_SPINNER:
      return { ...state, activateSpinner: data };
    default:
      return state;
  }
};
