export const LOGOUT_INIT = "LOGOUT_INIT";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";
export const NOTIFICATION_TYPE = {
  ASSET_ID: "asset_id",
  LEAD_ITEM_ID: "lead_item_id",
  MAINTENANCE_ITEM_ID: "case_id",
  LEAD_ID: "lead_id",
  VISIT_ID: "visit_id",
  CONTRACT_ID: "contact_id",
  LEASE_ID: "lease_id",
};
// ejari_ready_for_creation
// ejari_ready_for_activation

export const TYPE_NOTIFY = {
  LEASE_FORM_SENT_FOR_APPROVAL: "lease_form_sent_for_approval",
  LEASE_FORM_APPROVED: "lease_form_approved",
  LEASE_FORM_REJECTED: "lease_form_rejected",
  SENT_TO_TENANT_FOR_CHEQUES: "sent_to_tenant_for_cheques",
  TENANT_CHEQUES_SCHEDULED_DROPOFF: "tenant_cheques_scheduled_dropoff",
  TENANT_CHEQUES_SCHEDULED_PICKUP: "tenant_cheques_scheduled_pickup",
  BOOKING_OPPORTUNITY: "opportunity_confirmed_approver",
  BOOKING_RECEIVED: "booking_received",
  BOOKING_APPROVED: "booking_form_approved",
  BOOKING_REJECTED: "booking_form_rejected",
  BOOKING_FORM_SIGNED: "booking_form_signed",
  DOCUMENT_SUBMITED: "booking_doc_resubmitted",
};

export const TYPE_CONTRACT = {
  CONTRACT_RENEWED1: "ejari_contract_renewed",
  CONTRACT_SIGNED: "ejari_contract_signed",
  CONTRACT_RENEWED: "contract_renewed",
  RENEWAL_FEEDBACK: "renewal_feedback",
};
