import * as action_types from "../constants";

const initialState = {
  // TC Page Data
  tcTemplates:[],
  tcPageData: {}
};

export default (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    // TC page data
    case action_types.TC_PAGE_DATA:
      return { ...state, tcPageData: data };
    case action_types.GET_TEMPLATES:
      return { ...state, tcTemplates: data };
    default:
      return state;
  }
};
