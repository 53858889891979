import React, { useEffect, useState } from "react";

// others
import { Item } from ".";
import dayjs from "dayjs";
import { Col, Row, Spin, Input } from "antd";

import { useDispatch } from "react-redux";
import { getLeaseTerms } from "../../ducks/actions";
import { Modal } from '../../components';

import {
  apiDateFormat,
  filterDropdownValue,
  rentFreePeriodValues,
} from "../utils";

interface IProps {
  booking: any;
  renewal: boolean;
  contractNumber?: any
}

const index = (props: IProps) => {


  const dispatch: any = useDispatch();
  const format = "DD-MMM-YYYY";
  const dateStyle = { textTransform: "uppercase" };
  const linkStyle = { textTransform: "uppercase", color: '#184461' };
  const isBooking = props?.booking && Object.keys(props.booking).length > 0;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const rentFreeDate =
    props.booking?.leaseTerms?.rentFreeStartDate && props.booking?.leaseTerms?.rentFreeEndDate && filterDropdownValue(
      String(dayjs(props.booking?.leaseTerms?.rentFreeEndDate).diff(dayjs(props.booking?.leaseTerms?.rentFreeStartDate), 'days') + 1),
      rentFreePeriodValues()
    )?.value
  const rentFreePeriod = rentFreeDate ? `${rentFreeDate} ${parseInt(rentFreeDate) === 1 ? 'Day' : 'Days'}` : `0 Days`

  const usagesType = props?.booking?.propertyDetails?.usagesType;
  const [termsCondition, setTermsConditionId] = useState(null);

  useEffect(() => {
    ; (async () => {
      if (props.booking.termsConditionId) {
        try {
          const response = await dispatch(
            getLeaseTerms(props.booking.propertyId, props.booking?.propertyDetails?.usagesType)
          );
          const term = response.find(term => term._id === props.booking.termsConditionId)
          setTermsConditionId(term)
        } catch (e) {

        }
      }
    })()


  }, [props])

  const toNormalCase = (str) => str?.charAt(0)?.toUpperCase() + str?.slice(1);
  const bookingExpireTime = dayjs(props.booking?.bookingExpiryDate?.split("Z")[0]).format("HH:mm a");

  let modalPayload = {
    iKey: "16",
    title: "Booking Date Extension",
    open: isModalOpen,
    extraText: "",
    width: '50%'

  };

  //  if lease History inlude these status then hide request extension button story 9783
  let statusList = [];
  const loginUserType = JSON.parse(
    window.localStorage.getItem("login-response")
  )?.usertype;


  const checkLeaseHistory = () => {
    props?.booking?.leaseStatusHistory?.forEach((history) => {
      statusList.push(history.status)
    });

    if (
      statusList?.includes('active') ||
      statusList?.includes('contract_signed') ||
      statusList?.includes('sent_for_lease_approval') ||
      statusList?.includes('booking_cancelled')
    ) {
      return false;
    } else if (
      statusList?.includes('booking_extension_requested') &&
      props?.booking?.bookingExtension?.submittedDate &&
      props?.booking?.bookingExtension?.status === 'pending'
    ) {

      return 'booking_extension_requested'
    }
    else if (
      props?.booking?.bookingExtensionApproval === 'enable' &&
      (
        loginUserType.includes('leaseExecutive') ||
        loginUserType.includes('institutionalLandlord')
      )
    ) {
      return 'ok';
    } else {
      return false;
    }

  }


  return (
    <Col span={24} className="view-booking">
      {!isBooking && (
        <Row gutter={24} className="loading">
          <Spin size="large" />
        </Row>
      )}

      {isBooking && (
        <>
          <Row gutter={24} className="bd-item">
            <Item
              label={`Lease Executive`}
              value={props.booking?.leaseManager?.name ? toNormalCase(props.booking?.leaseManager?.name) : ""}
            />
            <Item label="External Agent" value={props.booking?.externalAgent ? toNormalCase(props.booking?.externalAgent) : ""} />
            <Item
              label="Lease Nature"
              value={props.booking?.leaseTerms?.leaseNature ? toNormalCase(props.booking?.leaseTerms?.leaseNature) : ""}
            />

            {usagesType != "Commercial" && (
              <Item
                label="Number of Occupants"
                value={props.booking?.tenantDetails?.numberOfOcupants}
              />
            )}

            {!props.renewal && props.booking?.bookingTerms?.bookingStartDate && props.booking?.bookingTerms?.bookingEndDate && (
              <Item
                valueStyle={dateStyle}
                label="Booking Period"
                value={`${apiDateFormat(
                  props.booking?.bookingTerms?.bookingStartDate,
                  format
                )} - ${apiDateFormat(
                  props.booking?.bookingTerms?.bookingEndDate,
                  format
                )}`}
              />
            )}

            {!props.renewal && usagesType === "Commercial" && (
              <Item
                valueStyle={dateStyle}
                label="Fit-Out Period"
                value={`${apiDateFormat(
                  props.booking?.leaseTerms?.fitOutStartDate,
                  format
                )} - ${apiDateFormat(
                  props.booking?.leaseTerms?.fitOutEndDate,
                  format
                )}`}
              />
            )}

            {!props.renewal && (
              <Item
                value={rentFreePeriod && rentFreePeriod}
                label="Rent Free Period"
              />
            )}

            <Item
              label="Lease Term"
              valueStyle={dateStyle}
              value={`${apiDateFormat(
                props.booking?.leaseTerms?.leaseStartDate,
                format
              )} - ${apiDateFormat(
                props.booking?.leaseTerms?.leaseEndDate,
                format
              )}`}
            />

            <Item
              label="Move-in Date"
              valueStyle={dateStyle}
              value={apiDateFormat(
                props.booking?.leaseTerms?.moveInDate,
                format
              )}
            />
            {props?.contractNumber && (



              <Item
                label="Ejari Contract Number"
                value={props?.contractNumber}
              />


            )}
            {props.booking?.bookingExpiryDate && (


              <Item
                showModal={setIsModalOpen}
                label="Booking Expiry"
                bookingExpiry={true}
                valueStyle={dateStyle}
                requestExtenstionShow={checkLeaseHistory()}
                value={`${apiDateFormat(props.booking?.bookingExpiryDate, "DD-MM-YYYY")} ${bookingExpireTime?.startsWith("00:00") ? "" : bookingExpireTime}`}
              />


            )}



            {termsCondition && (
              <Item
                label="Terms & Conditions"
                valueStyle={linkStyle}
                valueLink={termsCondition}
              />
            )}



          </Row>
        </>
      )}

      <Modal
        className="bd-modal"
        bookingExpiryDate={props.booking?.bookingExpiryDate}
        modalPayload={modalPayload}
        requestExtension={setIsModalOpen}

      />


    </Col>
  );
};

export default index;
