import * as action_types from "./constants";

const initialState: any = {
  areasData: [],
  zonesData: [],
  statusData: [],
  buildingData: []
};

export default (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case action_types.GET_ALL_AREAS:
      return { ...state, areasData: data };
    case action_types.GET_ALL_ZONES:
      return { ...state, zonesData: data };
    case action_types.GET_AREA_STATUS:
      return { ...state, statusData: data };
    case action_types.GET_ALL_BUILDING:
      return { ...state, buildingData: data };
    default:
      return state;
  }
};
