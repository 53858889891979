import React from 'react';
import Icon from '@ant-design/icons';

const LocationSvg = () => {
  return (
    <svg width="1em" height="1em" fill="currentColor" viewBox="0 0 64 64">
      <path d="M32,0C19.6,0,9.6,10,9.6,22.4C9.6,39.2,32,64,32,64s22.4-24.8,22.4-41.6C54.4,10,44.4,0,32,0z M32,30.4
	c-4.4,0-8-3.6-8-8s3.6-8,8-8s8,3.6,8,8S36.4,30.4,32,30.4z"/>
    </svg>
  );
};

const LocationIcon = props => <Icon component={LocationSvg} {...props} />

export default LocationIcon;


