export const setCookie = (cname, cvalue, exdays) => {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    const expires = "expires="+d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }
  
  export const getCookie = (cname) => {
    const name = cname + "=";
    const ca = document.cookie.split(';');
    for(let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
  
  export const checkCookie = (cname) => {
    const user = getCookie(cname);
    if (user != "") {
      return true;
    } else {
      return false;
    }
  }

  export const deleteCookie = (cname) => {
    document.cookie = cname + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }

  export const validateEmail = (email: string) => {
    const expression = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,3}$/;
    return expression.test(email?.toLowerCase());
  };


 export const bookingStatuses =[
    {
      "label": "All",
      "value": "all"
    },
      {
        "label": "Draft",
        "value": "draft"
      },
      {
        "label": "Draft-Submitted",
        "value": "booking_created"
      },
      {
        "label": "Tenant Invitation Sent",
        "value": "tenant_invitation_sent"
      },
      {
        "label": "Documents Approved",
        "value": "documents_approved"
      },
      {
        "label": "Kyc Approved",
        "value": "kyc_approved"
      },
      {
        "label": "Sent For Booking Approval",
        "value": "sent_for_booking_approval"
      },
      {
        "label": "Booking Form Sent",
        "value": "booking_form_sent"
      },
      {
        "label": "Awaiting Deposit",
        "value": "awaiting_deposit"
      },
      {
        "label": "Deposit Paid",
        "value": "deposit_paid"
      },
      
      {
        "label": "Rent Payments Received",
        "value": "rent_payments_received"
      },
      {
        "label": "Sent For Lease Approval",
        "value": "sent_for_lease_approval"
      },
      {
        "label": "Contract Created",
        "value": "contract_created"
      },
      {
        "label": "Awaiting Tenant Signature",
        "value": "awaiting_tenant_signature"
      },
      {
        "label": "Awaiting Landlord Signature",
        "value": "awaiting_landlord_signature"
      },
      {
        "label": "Contract Signed",
        "value": "contract_signed"
      },
      
    ]; 