import { GET_OPPORTUNITY_DETAILS, GET_OPPORTUNITY_STATUS } from "./constants";
import axios from "../../../../../utils/axiosInceptor";
import { leaseService } from "../../../../../configs/constants";

export const getOpportunityDetails = (opportunityId) => {
  return async (dispatch) => {
    const url = `${leaseService}/contract/get-opportunity-details/${opportunityId}`;
    const response = await axios.get(url);
    dispatch({
      type: GET_OPPORTUNITY_DETAILS,
      data: response?.data?.result,
    });
  };
};

export const getOpportunityStatus = (opportunityId) => {
  return async (dispatch) => {
    const url = `${leaseService}/contract/get-tasks/${opportunityId}`;
    const response = await axios.get(url);
    dispatch({
      type: GET_OPPORTUNITY_STATUS,
      data: response?.data?.result,
    });
  };
};
