import React from "react";
import { Button, Card, Typography, Space } from 'antd';
import { CloseOutlined } from "@ant-design/icons";
const { Title, Text, Paragraph } = Typography;

const TermsAndCondition = (props) => {

    return (
        <>
            <Button className="modal_button" type="link" size="large" onClick={props.onCancel} icon={<CloseOutlined />} />
            <Card
                bordered={false}
                className="defaultFont"
                bodyStyle={{ padding: '1rem 5vw' }}
            >
                <Space direction="vertical" size={40} className="w-100 text-center">
                    <Title level={1} className="mb-0">Terms and Conditions</Title>
                    <Title level={5} className="ag-primary mb-0">
                        Imporant: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
            </Title>
                    <Card
                        bordered={false}
                        className="ag-nospace-body defaultFont text-left"
                        bodyStyle={{ height: 400, overflowX: 'auto', paddingRight: '1.5rem' }}
                    >
                        <Title level={5}>A. Sed ut perspiciatis</Title>
                        <Paragraph>unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. </Paragraph>
                        <Title level={5}>B. Nemo enim ipsam</Title>
                        <Paragraph>voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</Paragraph>
                        <Title level={5}>C. Ut enim ad</Title>
                        <Paragraph>minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?</Paragraph>
                        <Title level={5}>D. Sed ut perspiciatis</Title>
                        <Paragraph>unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.</Paragraph>
                        <Title level={5}>E. Nemo enim ipsam</Title>
                        <Paragraph>voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</Paragraph>
                        <Title level={5}></Title>
                        <Paragraph></Paragraph>
                        <Title level={5}></Title>
                        <Paragraph></Paragraph>
                    </Card>
                    <Button type="primary" onClick={props.onAccept} size="large">Accept and Continue</Button>
                </Space>
            </Card>
        </>
    )
}

export default TermsAndCondition;