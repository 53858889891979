import { GET_OPERTUNITIES } from "./constants";

const initialState = {
  getOpertunities: {
    rows: [],
    count: 0,
  },
};
export default (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case GET_OPERTUNITIES:
      return { ...state, getOpertunities: data };
    default:
      return state;
  }
};
