import React from "react";
import Icon from "@ant-design/icons";

const RightArrowSvg = () => {
  return (
    <svg width="1em" height="1em" fill="currentColor" viewBox="0 0 64 64">
      <path
        d="M3.7,28.3h47.6c0,0,0,0,0,0L33.4,11.1c-1.5-1.4-1.5-3.7-0.1-5.2c1.4-1.5,3.7-1.5,5.2-0.1l24.4,23.5
	C63.6,30,64,31,64,31.9c0,1-0.4,1.9-1.1,2.6L38.3,58.2c-0.7,0.7-1.6,1-2.5,1c-1,0-1.9-0.4-2.6-1.1c-1.4-1.5-1.4-3.8,0.1-5.2
	l17.9-17.3c0,0,0,0,0,0H3.6c-1.9,0-3.5-1.5-3.6-3.4C-0.1,30.1,1.6,28.3,3.7,28.3z"
      />
    </svg>
  );
};

const RightArrow = (props) => <Icon component={RightArrowSvg} {...props} />;

export default RightArrow;
