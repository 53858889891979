import * as action_types from "./constants";

const initialState = {
  reviewProperties: {},
  reviewList: [],
};

export default (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case action_types.GET_REVIEW_PROPERTIES:
      return { ...state, reviewProperties: data };
    case action_types.GET_REVIEWS_FOR_PROPERTY:
      return { ...state, reviewList: data };
    default:
      return state;
  }
};
