import { RESET_STATE } from "../../../Properties/ducks/constants";
import { GET_OPPORTUNITY_DETAILS, GET_OPPORTUNITY_STATUS } from "./constants";

const initialState = {
  details: [],
  status: {},
};
export default (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case GET_OPPORTUNITY_DETAILS:
      return { ...state, details: data };
    case GET_OPPORTUNITY_STATUS:
      return { ...state, status: data };
    case RESET_STATE:
      return { state: initialState };

    default:
      return state;
  }
};
