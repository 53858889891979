import * as action_types from "./constants";

const initialState = {
  tenantData: {},
  leaseData: {},
  dependants: {},
};

export default (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case action_types.GET_TENANT_DETAILS:
      return { ...state, tenantData: data };
    case action_types.GET_TENANT_LEASE:
      return { ...state, leaseData: data };
    case action_types.GET_DEPENDENTS:
      return { ...state, dependants: data };
    case action_types.GET_EMPTY:
      return { initialState };

    default:
      return state;
  }
};
