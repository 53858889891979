import { GET_LEADS } from "./constants";
import axios from "../../../../../utils/axiosInceptor";
import { baseContractService } from "../../../../../configs/constants";

export const getLeads = (limit = 5, page = 0, value = "", field) => {
  return async (dispatch) => {
    const url = `${baseContractService}/get-all-leads?type=Lead&limit=${limit}&page=${page}${
      field && `&${field}=${value}`
    }`;
    const response = await axios.get(url);
    dispatch({
      type: GET_LEADS,
      data: response?.data?.result,
    });
  };
};
