/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-key */
import React, { Fragment, useEffect, useState } from "react";
import { Card, Typography, Popover, Button, Space } from "antd";
import moment from "moment";
import VisitScheduledConfirmationPopup from "../../../../../atoms/VisitScheduledConfirmationPopup";

const getListData = (value, scheduledVisits, callBack) => {
  let listData = [];

  if (scheduledVisits.length) {
    scheduledVisits.map((item) => {
      const selectedMonth = moment(value).format("MMMM");
      const selectedDay = moment(value).format("DD");
      const currentMonth = moment(item.start_date).format("MMMM");
      const currentDay = moment(item.start_date).format("DD");
      const currentStartTime = moment(
        item.start_date + " " + item.start_time,
      ).format("hh:mm A");
      const currentEndTime = moment(item.end_date + " " + item.end_time).format(
        "hh:mm A",
      );

      if (selectedMonth === currentMonth && selectedDay === currentDay) {
        let dynamicContent = "";
        if (item.type === "visit_created_by") {
          dynamicContent = `Visit by ${item.username}`;
        } else if (item.type === "prospect") {
          dynamicContent = `Request by ${item.username}`;
        }

        listData.push({
          id: item.id,
          status: item.status,
          time: `${currentStartTime} to ${currentEndTime}`,
          content: dynamicContent,
          data: item,
          callBack: callBack,
        });
      }
    });
  }
  return listData;
};

const DateCellRender = (props) => {
  const { Title, Paragraph, Text } = Typography;
  const listData = getListData(
    props.value,
    props.scheduledVisits,
    props.scheduledVisitRequestCallBack,
  );

  // useEffect(()=>{
  //   if(listData.length>0)
  //   console.log(listData)
  // },[listData])

  // const PopUpContent = (props) => {
  //   const { value, callBack } = props;
  //   const currentStartTime = moment(
  //     value.start_date + " " + value.start_time,
  //   ).format("hh:mm A");
  //   const currentStartDayInDigits = moment(
  //     value.start_date + " " + value.start_time,
  //   ).format("Do");
  //   const currentStartDay = moment(
  //     value.start_date + " " + value.start_time,
  //   ).format("dddd");
  //   const currentStartMonth = moment(
  //     value.start_date + " " + value.start_time,
  //   ).format("MMMM");
  //   const currentEndTime = moment(value.end_date + " " + value.end_time).format(
  //     "hh:mm A",
  //   );
  //   return (
  //     <Card bordered={false} className='pop-up-notification'>
  //       <Title level={4}>{`Request for viewing ${value.property.name}`}</Title>
  //       <Paragraph>{`${currentStartDay}, ${currentStartMonth} ${currentStartDayInDigits}, ${currentStartTime} to ${currentEndTime}`}</Paragraph>
  //       <Title level={5}>
  //         {`Would you like to accept ${value.username}’s request to view ${value.property.name}?`}
  //       </Title>
  //       {/* <Paragraph>
  //         This property will not be viewed by anyone else for x hours.
  //       </Paragraph> */}
  //       <Space className="pop-up">
  //         <Button
  //           onClick={() => {
  //             callBack(value.id, "rejected");
  //             setPopup(!popup);
  //           }}
  //         >
  //           Reject
  //         </Button>
  //         <Button
  //           className="ag-green-button"
  //           onClick={() => {
  //             alert(value.id)
  //             callBack(value.id, "approved");
  //             setPopup(!popup);
  //           }}
  //         >
  //           Accept
  //         </Button>
  //       </Space>
  //     </Card>
  //   );
  // };

  return (
    <Fragment>
      {listData.map((item, key) => (
        <VisitScheduledCellCard key={key} item={item} />
        // return (
        //   item.data.status !== "rejected" && (
        //   )
        // );
      ))}
    </Fragment>
  );
};

export default DateCellRender;

const VisitScheduledCellCard = (props) => {
  const { key, item } = props;
  const [popup, setPopup] = useState(false);

  const PopUpHandler = (status) => {
    if (status && status === "scheduled") {
      setPopup(!popup);
    }
  };

  return (
    <Popover
      key={key}
      content={
        <VisitScheduledConfirmationPopup
          popup={popup}
          setPopup={setPopup}
          value={item.data}
          callBack={item.callBack}
          style={{ padding: "0px" }}
        />
      }
      trigger="click"
      visible={popup}
      onVisibleChange={() => PopUpHandler(item.status)}
    >
      <Card
        className={`custom-uper-card ${item.data.status}`}
        size="small"
        key={item.content}
        onClick={() => PopUpHandler(item.id)}
      >
        <Card
          className={`custom-card `}
          style={
            item.data &&
            item.data.status !== "scheduled" &&
            item.data.status !== "prospect"
              ? { backgroundColor: "initial", cursor: "not-allowed" }
              : { backgroundColor: "#a2d69e", cursor: "pointer" }
          }
          size="small"
          key={item.content}
        >
          <Typography style={{ fontSize: "9px" }}>{item.time}</Typography>
          <Typography
            style={
              item.type !== "scheduled"
                ? { color: "#447093", fontSize: "9px" }
                : { color: "#000", fontSize: "9px" }
            }
          >
            {item.content}
          </Typography>
        </Card>
      </Card>
    </Popover>
  );
};
