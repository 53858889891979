import React, { Fragment, useEffect, useState } from "react";
import dayjs from "dayjs";
import {
  Col,
  Row,
  Button,
  Typography,
  Radio,
  Form,
  Table,
  message,
  Switch,
  Space,
} from "antd";
import {
  InputField,
  DateField,
  ReactSelectField,
  InputRadio,
  InputSwitch,
} from "../../../atoms/FormElement";
import { useFieldArray, useForm, useWatch } from "react-hook-form";
import { CheckCircleFilled, InfoCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
// import { getLandlordUsers, getTermsConditions } from "../ducks/actions";
// import Billings from "./Billings";
import { installmentsList } from "../../Properties/ducks/constants";
import { getBillingCharges } from "../../LeasingForm/ducks/actions";
import { leaseService } from "../../../../configs/constants";
import axios from "axios";

var _ = require("lodash");
const { Text, Paragraph, Title } = Typography;

import { CommonCard } from "../components";
import AddPaymentPlan from "./AddPaymentPlan";
import AdditionalPayments from "./AdditionalPayments";
import {
  createBooking,
  deleteBooking,
  getBookingDetail,
  getPaymentPlans,
  setBookingForm,
  setFormState,
  updateBooking,
} from "../ducks/actions";
import {
  ApprovalHeader,
  OldAmount,
  PendingHeader,
  amount_type,
  apiDateFormat,
  billing_charges,
  dateFormat,
  filterDropdownValue,
  payment_modes,
  payment_plans,
} from "./utils";
import { useNavigate, useParams } from "react-router";
import { currencyFormat } from "../../../../utils/media";
import { Popup, PopupConfirm } from "../../../atoms/Popup";
import RejectPopup from "../../LeasingFormNew/components/RejectionPopup";

const initcharge = {
  itemId: null,
  type: "",
  intallments: { label: 1, value: 1 },
  mode: {
    label: "Cheque",
    value: "cheque",
  },
  amount: 0,
};

export default (props) => {
  const { data, parking, unitCode } = props;

  const [noIstallment, setNoInstallment] = useState([]);
  const [billingType, setBillingType] = useState([]);
  const [deleteIndex, setDeleteIndex] = useState(2); //startIndex for delete in billings

  // const navigate = useNavigate();
  const [modalvisible, setModalvisible] = useState(false);
  const [oldPlans, setOldPlans] = useState([]);

  const dispatch: any = useDispatch();

  // redux states
  const { bookingForm, bookingDetails, paymentPlans } = useSelector(
    (state: any) => state.newBooking
  );

  const isBooking = Object.keys(bookingDetails).length > 0;

  const { leaseId = bookingDetails.leaseId } = useParams();

  const [modalTrigger, setModalTrigger] = useState(0);


  const {
    control,
    trigger,
    formState: { errors },
    handleSubmit,
    setValue,
    getValues,
  } = useForm({
    defaultValues: {
      plans: [],
      othercharges: [],
      furnished: bookingForm?.furnished || "NO"
    }
  });

  const {
    fields: otherChargesFields,
    append: otherChargesAppend,
    remove: otherChargesRemove,
  } = useFieldArray({
    control,
    name: "othercharges",
  });

  const {
    fields: plansFields,
    append: plansAppend,
    remove: plansRemove,
  } = useFieldArray({
    control,
    name: "plans",
  });


  const plansWatch = useWatch({ control, name: "plans" });

  const AddAdditionalRow = () => {
    otherChargesAppend(initcharge);
    // disableClone("new");
  };

  useEffect(() => {
    dispatch(getPaymentPlans(unitCode, getValues("furnished")));
  }, []);

  useEffect(() => {
    if (bookingForm?.bookingTerms?.billingCharges?.length > 0) {
      const billingLines = [];
      bookingForm?.bookingTerms?.billingCharges?.map((item) => {
        let type = filterDropdownValue(item["billingChargesType"], billingType);
        const obj = {
          billingChargesType: { ...type, isDisabled: true },
          intallments: {
            label: item?.numberOfInstallments || 1,
            value: item?.numberOfInstallments || 1,
          },
          dueDate: dateFormat(item?.dueDate),
          modeOfPayment: filterDropdownValue(
            item?.modeOfPayment || "cheque",
            payment_modes
          ),
          actualAmount: item?.actualAmount,
          vat: item?.vat,
          amountType: filterDropdownValue(item?.amountType, amount_type),
          total: item?.total,
        };
        type && billingLines.push(obj);
      });

      billingLines.length > 0 && setValue("othercharges", billingLines);
    }
  }, [bookingForm, billingType]);

  //TODO: need to take this in utils
  useEffect(() => {
    //take to reducer
    let plans = [];
    const bookingPlans = bookingForm?.paymentPlan || bookingDetails?.paymentPlan;
    const furnished = getValues("furnished");
    const addedPlans = bookingPlans?.filter((item) => (item?.newPlan == true && item?.furnished == furnished)) ?? [];
    if (paymentPlans?.length > 0) {
      // const payIds = 
      const payPlans = bookingPlans?.filter((item) => (item?.furnished == furnished)); //?.map((item) => item?.unitPaymentPlanId);
      // console.log("payIds", payIds);
      plans = paymentPlans.map((item) => {
        if (payPlans?.length > 0) {
          const selectedPlan = payPlans.filter(i => i.unitPaymentPlanId == item?.unitPaymentPlanId);
          // console.log("payPlans", payPlans, selectedPlan)
          if (selectedPlan?.length > 0) {
            //  item.checked = true;
            return selectedPlan[0];
          } else item.checked = false;
        } else item.checked = true;
        return item;
      });
    }
    const finalPlans = [...plans, ...addedPlans]?.sort(
      (a, b) => a.installments - b.installments
    );
    // console.log("finalPlans", finalPlans);
    setValue("plans", finalPlans);
    setOldPlans(getFinalPlans(finalPlans));
  }, [paymentPlans, bookingDetails]);


  useEffect(() => {
    let billingTypeTemp = [];
    if (billing_charges.length > 0) {
      billing_charges?.map((item, index) => {
        let isDisabledTemp = false;
        if (
          item.key == "rent_installment" ||
          //   item.key == "ejari_fee" ||
          item.key == "security_deposit"
        ) {
          isDisabledTemp = true;
        }
        // if (item.key != "base_rent_ic") {
        billingTypeTemp.push({
          label: item.name,
          value: item.key,
          isDisabled: isDisabledTemp,
        });
        // }
      });
      setBillingType(billingTypeTemp);
    }
  }, []);

  const onPaymentPlans = async (value) => {
    props.parentState({ load: true });
    setTimeout(async () => {
      //TODO : UNITCODE ADD HERE
      if (unitCode) {
        await dispatch(getPaymentPlans(unitCode, value));
      }
      props.parentState({ load: false });
    }, 500);
  };

  const getFinalPlans = (plansData) => {
    const plans = [...plansData?.map(a => { return { ...a } })] || [];
    const result = payment_plans.map(plan => {
      const matchingItem = plans?.find(item => Number(item.installments) === plan);
      return matchingItem
        ? matchingItem
        : {
          installments: plan,
          annualRent: 0,
          noOfYear: 1,
          securityDeposit: 0,
          newPlan: true,
          checked: true,
          furnished: getValues("furnished")
        };
    })
    return result?.length > 0 ? result : [];
  }

  const columns = [
    {
      title: "NO OF PAYMENT",
      dataIndex: "installments",
      render: (text, record, index) => (
        <Row>
          <Col span={4}>
            <Text
              className="ag-dark fontWeight600"
              style={{ fontSize: "14px" }}
            >
              {text} Cheques
            </Text>
          </Col>
          <Col span={6}>
            <InputField
              classes="fm-input-hidden"
              fieldname={`plans[${index}]`}
              control={control}
              initValue={record}
              iProps={{ readOnly: true, type: "hidden" }}
            />
            <InputSwitch
              isRequired={true}
              fieldname={`plans[${index}].checked`}
              control={control}
              valueGot={!!record?.checked}
              setValue={setValue}
              initValue={record?.checked == false ? record?.checked : true}
            />
          </Col>
        </Row>
      ),
    },
    {
      title: "ANNUAL RENT",
      dataIndex: "annualRent",
      width: 180,
      align: "right" as const,
      render: (text, item) => (
        <div className="ag-primary"><OldAmount amount={item?.oldAnnualRent} /> {currencyFormat(+text)}</div>
      ),
    },
    {
      title: "NO OF YEARS",
      dataIndex: "noOfYear",
      width: 150,
      align: "right" as const,
      render: (text, item) => <div className="ag-primary"><OldAmount amount={item?.oldNoOfYear} /> {text}</div>,
    },
    {
      title: "SECURITY DEPOSIT",
      dataIndex: "securityDeposit",
      width: 180,
      align: "right" as const,
      render: (text, item) => (
        <div className="ag-primary"><OldAmount amount={item?.oldSecurityDeposit} /> {currencyFormat(+text)}</div>
      ),
    },
  ];

  const onSubmit = async (data?: any, type?, saveOnly?) => {
    let dataCopy = { ...(data || getValues()) };

    const selectedPlans =
      dataCopy?.plans?.length > 0
        ? dataCopy?.plans?.filter((d) => d?.checked === true)
        : [];
    const billingChargesArray = [];
    if (dataCopy?.othercharges?.length > 0) {
      dataCopy?.othercharges?.map((item, index) => {
        const total: any = item?.total;
        billingChargesArray.push({
          billingChargesType: item?.billingChargesType?.value,
          modeOfPayment: item?.modeOfPayment?.value,
          dueDate: apiDateFormat(item?.dueDate),
          numberOfInstallments: item?.intallments?.value || 1,
          amountType: item?.amountType?.value,
          actualAmount: parseFloat(item?.actualAmount),
          vat: parseFloat(item?.vat),
          total: parseFloat(total),
        });
      });
    }

    if (selectedPlans.length == 0 && type != "sendPlan") {
      message.info("Please select at least one payment plan to proceed");
      return;
    }   

    let body = {
      paymentPlan: selectedPlans,
      bookingTerms: {
        ...bookingForm?.bookingTerms,
        billingCharges: billingChargesArray,
      },
      furnished: dataCopy?.furnished
    };

    if (type === "next") {
      let valid = true;

      //   for (let key in dataCopy) {
      //     if (!excludedLeaseTermsKeys.includes(key) && !dataCopy[key]) {
      //       valid = false;
      valid = await trigger("othercharges", { shouldFocus: true });
      //       break;
      //     }
      //   }

      if (valid) {
        saveOnly != true && dispatch(setFormState());
        dispatch(setBookingForm(body));
      }
    } else {
      body = { ...bookingForm, ...body };

      props.parentState({ load: true });

      try {
        const response = await dispatch(
          !isBooking ? createBooking(body) : updateBooking({ leaseId, body })
        );

        // !isBooking && (
        type != "sendPlan" && await dispatch(getBookingDetail(leaseId));
        // );

        props.parentState({ load: false });
        message.success(response.data.message);
      } catch (error) {
        props.parentState({ load: false });
        message.error(
          error.response?.data?.error || error.response?.data?.message
        );
      }
    }
  };



  const sendPlanApi = async (newPlans) => {
    await onSubmit(null, "sendPlan"); //added fix for bug: 8194
    props.parentState({ load: true });
    const finalData = newPlans?.map(item => {
      let old = oldPlans.find(plan => plan.installments == item.installments);
      item = {
        ...item,
        ...{
          "oldAnnualRent": old?.annualRent,
          "oldSecurityDeposit": old?.securityDeposit,
          "oldNoOfYear": old?.noOfYear,
        }
      }
      // // }
      return item;

    });
    let body = {
      "paymentPlanSendForApproval": "yes",
      "paymentPlan": finalData
    }

    try {
      const config: any = {
        data: body,
        method: "PATCH",
        url: `${leaseService}/booking/${leaseId}`,
      };
      setModalvisible(false);
      const response = await axios(config);
      await dispatch(getBookingDetail(leaseId));
      message.success(response.data.message);
      props.parentState({ load: false });
    } catch (error) {
      props.parentState({ load: false });
      message.error(
        error.response?.data?.error || error.response?.data?.message
      );
    }

  }

  const approvePlan = async () => {
    let body = { "paymentPlanSendForApproval": "approved" };
    PopupConfirm({
      title: `Are you sure you want to approve payment plans?`,
      content: "",
      onOk: () => approvalPlanApi(body),
      okText: "Confirm",
    });
  }


  const rejectPlan = async (data) => {
    let body = {
      "paymentPlanSendForApproval": "rejected",
      "rejectionReason": data?.rejectReason ?? "",
    };
    setModalvisible(false);
    await approvalPlanApi(body);
  }


  const approvalPlanApi = async (body) => {
    props.parentState({ load: true });
    try {
      const config: any = {
        data: body,
        method: "PATCH",
        url: `${leaseService}/booking/${leaseId}`,
      };
      const response = await axios(config);
      props.parentState({ load: false });
      message.success(response.data.message);
      await dispatch(getBookingDetail(leaseId));
    } catch (error) {
      props.parentState({ load: false });
      message.error(
        error.response?.data?.error || error.response?.data?.message
      );
    }
  }

  const popup = [
    {
      title: "",
      content: (
        <AddPaymentPlan
          plansData={getFinalPlans(getValues("plans"))} //nested copy
          setPlansValue={(value) => setValue("plans", value)}
          onClose={() => setModalvisible(false)}
          sendPlanApi={sendPlanApi}
        />
      ),
      closable: false,
      width: "80%",
    },
    {
      title: (
        <Title level={3} className="mb-0">
          Reject Reason
        </Title>
      ),
      width: 650,
      content: (
        <RejectPopup
          onSubmit={rejectPlan}
          onClose={() => setModalvisible(false)}
        />
      ),
    },
  ];

  const popupProps = {
    closable: true,
    visibility: modalvisible,
    title: popup[modalTrigger]?.title,
    content: popup[modalTrigger].content,
    width: popup[modalTrigger].width,
    onCancel: () => setModalvisible(false),
  };
  
 
  const statusHeader = () => {
    // managersManager leaseManager landlord
    if (bookingDetails?.paymentPlanApproval == "sent" && bookingDetails?.loginUserType == "leaseManager") {
      return <PendingHeader />
    } else if (bookingDetails?.paymentPlanApproval == "sent" &&
      (bookingDetails?.loginUserType == "managersManager" ||
        bookingDetails?.loginUserType == "landlord")) {
      return <ApprovalHeader approve={approvePlan} reject={() => {  setModalTrigger(1); setModalvisible(true) }} />
    }
    else return null;
  };


  return (
    <>
      <Form
        layout="vertical"
        name="bookingform"
        className="bf-main"
        scrollToFirstError
        onFinish={handleSubmit(onSubmit)}
      >
        <CommonCard
          label="Payment Plans"
          leftBtnLabel="Back"
          rightBtnLabel="Next"
          handleNext={(bookingDetails?.paymentPlanApproval != "sent") && onSubmit}
          handleBack={() => dispatch(setFormState("back"))}
          statusHeader={statusHeader()}
          parentState={props.parentState}
        >
          <Col span={24}>
            <Row gutter={24}>
              <Col span={6}>
                <InputRadio
                  isRequired={true}
                  label={
                    <Text className="fontWeight600 ag-default">
                      Is the Property Furnished?
                    </Text>
                  }
                  fieldname="furnished"
                  // noStyle={true}
                  onChange={(e) => onPaymentPlans(e.target.value)}
                  control={control}
                  // initValue="YES"
                  style={{ margin: 0 }}
                  options={
                    <Row gutter={[16, 16]}>
                      <Col span={6}>
                        <Radio value="YES">Yes</Radio>
                      </Col>
                      <Col span={6}>
                        <Radio value="NO">No</Radio>
                      </Col>
                    </Row>
                  }
                />
              </Col>
              {/* <hr> */}
              <Col span={24}>
                <Paragraph
                  className="smallFont13 fp-para-line"
                  style={{ margin: "0 0 10px 0" }}
                >
                  Select payment plans to include with this booking (uncheck to
                  remove).
                </Paragraph>
                <Table
                  columns={columns}
                  dataSource={plansWatch?.map(a => { return { ...a } }) || []} //nested copy
                  pagination={false}
                  bordered={false}
                  // showHeader={false}
                  className="sideTable"
                  size="small"
                />
              </Col>

              <Col span={24}>
                <Button
                  type="dashed"
                  // className="p-0"
                  onClick={() => { setModalTrigger(0); setModalvisible(true) }}
                  htmlType="button"
                  icon={<PlusOutlined />}
                  block={true}
                >
                  <Text
                    className="ag-primary fontWeight600"
                    style={{ fontSize: "14px" }}
                  >
                    Add or Edit Payment Plan
                  </Text>
                </Button>
              </Col>
              <Col span={24} style={{ marginTop: "20px" }}>
                <p className="ft-main-label">Additional Payments</p>

                <AdditionalPayments
                  fields={otherChargesFields}
                  billingType={billingType}
                  control={control}
                  errors={errors}
                  remove={otherChargesRemove}
                  setValue={setValue}
                  disableAll={props.disableAll}
                  getValues={getValues}
                  deleteIndex={deleteIndex}
                  noIstallment={noIstallment}
                  noIData={installmentsList}
                  parking={parking}
                  setNoInstallment={setNoInstallment}
                  depositPayments={bookingDetails?.depositPayments}
                // disableClone={disableClone}
                // enableClone={enableClone}
                />

                {!props.disableAll && (
                  <Row gutter={24}>
                    <Col span={24}>
                      <Button
                        type="dashed"
                        // className="p-0"
                        htmlType="button"
                        icon={<PlusOutlined />}
                        block={true}
                        onClick={AddAdditionalRow}
                      >
                        <Text
                          className="ag-primary fontWeight600"
                          style={{ fontSize: "14px" }}
                        >
                          Add Another Payment
                        </Text>
                      </Button>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          </Col>
        </CommonCard>
      </Form>
      <Popup destroyOnClose={true} {...popupProps} />
    </>
  );
};
