import axios from "../../../../../utils/axiosInceptor";
import * as action_types from "./constants";
import { propertyService } from "../../../../../configs/constants";
import { maintenanceService } from "../../../../../configs/constants";
const url = `${propertyService}/unit/`;

export const getPropertyUnits = (propertyId, buID?, masterList?,unitLoading=null) => {


 
  const usertype = JSON.parse(window.localStorage.getItem("login-response"))?.usertype;

  return async (dispatch) => {
    try{
      unitLoading && unitLoading(true);
      let finalUrl;
      if(usertype.includes("serviceProvider")){
        let isUnitWant = true;
       
        if(propertyId){
          unitLoading && unitLoading(true);
         finalUrl = `${maintenanceService}/unit/unitDropDown/${propertyId}`
        }else{
          unitLoading && unitLoading(true);
          finalUrl = `${maintenanceService}/property/serviceProviderPropertyList?isUnitWant=${isUnitWant}`; //changed
        }
       
      }else{
        
      if(propertyId && buID) {
        finalUrl = `${url}unitList?businessUnitId=${buID}&propertyId=${propertyId}&perPage=20000`
      } else if (!propertyId && buID) {
        finalUrl = `${url}unitList?businessUnitId=${buID}&perPage=20000`
      } else if (propertyId && !buID) {
        finalUrl = `${url}unitList?&propertyId=${propertyId}&perPage=20000`
      } else {
        finalUrl = `${url}unitList?perPage=20000`
      }
  
      if(masterList == true && !propertyId && !buID) { 
        finalUrl = finalUrl + `&listType=master`
      }
      }
       
        if(usertype.includes("serviceProvider")){
          const data = await axios.get(finalUrl);
          unitLoading && unitLoading(false);
   
           dispatch({
        type: action_types.PROPERTY_UNITS,
        data:data?.data?.result,
      });
        }else{
        const {data: { result }} = await axios.get(finalUrl); 
        unitLoading && unitLoading(false);
        dispatch({
          type: action_types.PROPERTY_UNITS,
          data: result?.data,
        });
        }
  
    
    }catch(error){
      unitLoading(false);
      dispatch({
        type: action_types.PROPERTY_UNITS,
        data: [],
      });
      console.log(error)
    }
  };
};


export const getParkingUnits = (propertyId) => {
  return async (dispatch) => {
    const {
      data: { result },
    } = await axios.get(`${url}parkingList/${propertyId}`);
    dispatch({
      type: action_types.PARKING_UNITS,
      data: result,
    });
  };
};

export const emptyPropertyUnits = () => {
  return async (dispatch) => {
      dispatch({
      type: action_types.PROPERTY_UNITS,
      data: [],
    });
  };
};



export const getBookingUnitList = (propertyId) => {
  return async (dispatch) => {
    const {
      data: { result },
    } = await axios.get(`${url}bookingUnitList/${propertyId}`); //MONGO ID _id (can be done by)
    // getById?propertyId=${propertyId}`); //Property ID
    // console.log("bookingUnitList",result)
    dispatch({
      type: action_types.BOOKING_PROPERTY_UNITS,
      data: result,
    });
  };
};

export const getSingleUnit = (unitId) => {
  return async (dispatch) => {
    const {
      data: { result },
    } = await axios.get(`${url}getById?_id=${unitId}`);
    dispatch({
      type: action_types.GET_SINGLE_UNIT,  
      data: result,
    });
  };
};

export const getPaymentPlan = (propertyId) => {
  return async (dispatch) => {
    const {
      data: { result },
    } = await axios.get(`${url}/${propertyId}/payment-plans`);
    dispatch({
      type: action_types.GET_PAYMENT_PLAN,
      data: result,
    });
  };
};
