import * as action_types from "../constants";

const initialState = {
  // Add general Info
  getFeaturesAmenities: {},
  getGeoData: {},
  generalInfoData: {},
  propertyDetailsData: {},
  propertyFeaturesData: {},
  getNotifications: [],
  getNewNotifications: [],
  getPropertyById: [],
  getSingleProperty: [],
  getUsageType: [],
  getUnitType: [],
  getUnitSubType: [],
  getLeadsList: [],
  getSingleStoredImageKey: [],
  getMultiStoredImageKey: [],
  getPresignedImage: "",
};

export default (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    // Add General Info
    case action_types.ADD_PROPERTY_GENERALINFO_DATA:
      return { ...state, generalInfoData: data };
    case action_types.ADD_PROPERTY_PROPERTY_DETAILS_DATA:
      return { ...state, propertyDetailsData: data };
    case action_types.ADD_PROPERTY_PROPERTY_FEATURES_DATA:
      return { ...state, propertyFeaturesData: data };
    case action_types.GET_FEATURES_AMENITIES:
      return { ...state, getFeaturesAmenities: data };
    case action_types.GET_GEO_DATA:
      return { ...state, getGeoData: data };
    case action_types.GET_USAGE_DETAIL:
      return { ...state, getUsageType: data };
    case action_types.GET_UNIT_DETAIL:
      return { ...state, getUnitType: data };
    case action_types.GET_SUBUNIT_TYPE:
      return { ...state, getUnitSubType: data };

    case action_types.GET_NOTIFICATIONS:
      return { ...state, getNotifications: data };
    case action_types.GET_NEW_NOTIFICATIONS:
      return { ...state, getNewNotifications: data };
    case action_types.GET_PROPERTY_BY_ID:
      return { ...state, getPropertyById: data };
    case action_types.GET_LEADS_LISTS:
      return { ...state, getLeadsList: data };
    case action_types.STORE_SINGLE_IMAGE_KEY:
      return { ...state, getSingleStoredImageKey: data };
    case action_types.STORE_MULTI_IMAGE_KEY:
      return { ...state, getMultiStoredImageKey: data };
    case action_types.GET_SINGLE_PROPERTY:
      return { ...state, getSingleProperty: data };
    case action_types.PRESIGNED_IMAGE:
      return { ...state, getPresignedImage: data };
    case action_types.GET_SINGLE_PROPERTY_EMPTY:
      return { ...state, getSingleProperty: {} };
    default:
      return state;
  }
};
