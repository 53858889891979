import { LOGOUT_INIT, LOGOUT_SUCCESS, LOGOUT_FAILURE } from "./constants";
import { LogoutActions } from "./types";
import { AuthModel } from "./model";

import { TOKEN_KEY } from "../../../../configs";
import { removeFromLocal } from "../../../../utils/cache";

const initialState: AuthModel = {
  id: "",
  isLoading: false,
};

const Header = (
  state: AuthModel = initialState,
  action: LogoutActions,
): AuthModel => {
  switch (action.type) {
    case LOGOUT_INIT: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case LOGOUT_SUCCESS: {
      removeFromLocal(TOKEN_KEY);
      return {
        ...state,
        isLoading: false,
      };
    }

    case LOGOUT_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    default:
      return state;
  }
};
export default Header;
