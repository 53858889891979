import axios from "../../../../utils/axiosInceptor";
import * as action_types from "./constants";
import {
  propertyService,
  leaseService,
  authService,
} from "../../../../configs/constants";


export const getTenants = (id, limit, page, search, searchBy,filter = {},setTenantLoading = null) => {
  
  try{
    setTenantLoading(true)
    return async (dispatch) => {
      let params = new URLSearchParams({
          ...filter,
        });
      let tenants = "";
      let url = "";
      if (id) {
        tenants = `${leaseService}/tenant/user`; 
        url = `${tenants}&limit=${limit}&page=${page}`;
      } else {
        tenants = `${leaseService}/tenant/user`;
        url = `${tenants}?limit=${limit}&page=${page}&searchby=${`${searchBy}&text=${search}&`+params.toString()} `;
      }
      const {
        data: { result },
      } = await axios.get(url);

    
     (result || result === undefined)  && setTenantLoading(false)
      dispatch({
        type: action_types.GET_TENANTS,
        data: result,
      });
    };
  }catch(error){
    setTenantLoading(false)
    console.log(error)
  }
  
};

export const emptyTenants = () => {
  return (dispatch) => {
    dispatch({
      type: action_types.EMPTY_TENANTS,
      data: {
        count: 0,
        results: [],
      },
    });
  };
};

export const getPropects = (id, limit, page, search, searchBy) => {
  return async (dispatch) => {
    let prospects = "";
    if (id) {
      prospects = `${propertyService}/property/${id}/tenants`;
    } else {
      prospects = `${leaseService}/leads/get-tenant-prospects`;
    }

    const url = `${prospects}?limit=${limit}&page=${page}${search && "&" + searchBy + "=" + search
      }`;
    const {
      data: { result },
    } = await axios.get(url);
    dispatch({
      type: action_types.GET_PROSPECTS,
      data: result,
    });
  };
};


export const sendInvite = async (payload, message) => {
  try {
    const url = `${authService}/user/sendInvite`;
    const { status } = await axios.post(url, payload, {
      headers: { application: 4 },
    });

    return status;
  } catch (error) { }
};

export const activeDeActiveUser = async (payload,userID) => {
  try {
    const url = `${authService}/user/profile/update/${userID}`;
    const { status } = await axios.patch(url, payload, {
      headers: { application: 4 },
    });

    return status;
  } catch (error) { }
};
