import React, { useState, useEffect, Fragment } from "react";
import { Popup } from "../../../atoms/Popup";
import {
  Card,
  Row,
  Col,
  Typography,
  Button,
  Image,
  Upload,
  message,
  Space,
  Form,
  Input,
} from "antd";
import {
  FileTextOutlined,
  DeleteOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import PdfViewer from "../../../molecules/PDFViewer/pdfView";

import { useFieldArray, Controller, useWatch } from "react-hook-form";
import { dummyRequest } from "../../Properties/utils/utility";
import { InputField, ReactSelectField } from "../../../atoms/FormElement";
import { getPresignedImage } from "../../../../utils/media";
import { DeleteIcon } from "../../../../assets/svg";

var _ = require("lodash");

export default (props) => {
  const [visible, setVisible] = useState(false);
  const {
    control,
    disableAll,
    requiredBookingDocumentsList,
    isAdd,
    disableClone,
    errors
  } = props;
  const { Title, Text } = Typography;
  const [showPdf, setShowPdf] = useState(false);
  const [pdfFile, setPdfFile] = useState(null);
  const [viewImage, setViewImage] = useState(null);
  const [isAddMore, setIsAddMore] = useState(true);

  const [isOtherDoc, setIsOtherDoc] = useState(null);

  const additionalDocs = useWatch({ control, name: "additionalDocs" });


  const showRequireDocs = ()=>{
    return requiredBookingDocumentsList?.filter(item => {
      return !props?.requiredDocArray?.map(i => i.type)?.includes(item?.value)
        && !additionalDocs?.map(i => i?.type?.value)?.includes(item?.value);
    })
  }
  useEffect(() => {
    setIsAddMore(isAdd);
  }, [isAdd]);

  const onClosePopUp = () => {
    setViewImage(null);
    setVisible(false);
  };

  const initDocBar = {
    id: "",
    file: "",
  };

  const { fields, append, remove } = useFieldArray({
    control,
    name: "additionalDocs",
  });

  const popupContent = {
    closable: true,
    visibility: visible,
    content: (
      <Image
        preview={false}
        width="100%"
        style={{ padding: "20px" }}
        src={viewImage}
      />
    ),
    width: 650,
    onCancel: onClosePopUp,
  };


  const onChange = (e, index) => {
    if (e?.value === "Other")
      setIsOtherDoc(index);
    else if (e?.value != "Other" && index === isOtherDoc) {
      setIsOtherDoc(null);
    }
    disableClone(e.value);
  };



  return (
    <>
      <PdfViewer
        pdf={pdfFile}
        onCancel={() => setShowPdf(false)}
        visible={showPdf}
      />
      {fields.map((item, index) => {
        return (
          <Card bordered={false} key={item.id} className="h-auto document-card" style={{ marginBottom: '0.6rem' }}>
            <Row gutter={24} justify="space-between" align="middle">
              <Col flex="500px" style={{ display: "flex", height: 35 }}>
                <FileTextOutlined className="require-doc" />
                <Col span={12}>
                  {/* <Title level={5}>{`Other Document ${index + 1}`}</Title>
                   */}
                  <ReactSelectField
                    // style={{ height: '12px' }}
                    isRequired={true}
                    label=""
                    classes="mb-0 sm-input"
                    fieldname={`additionalDocs[${index}].type`}
                    control={props.control}
                    iProps={{ placeholder: "Document Type" }}
                    // initValue={}
                    onChange={(e) => onChange(e, index)}
                    selectOption={requiredBookingDocumentsList?.filter(item => {
                      return !props?.requiredDocArray?.map(i => i.type)?.includes(item?.value)
                        && !additionalDocs?.map(i => i?.type?.value)?.includes(item?.value);
                    })}
                    rules={{ required: "Please select document type" }}
                    validate={
                      Object.entries(errors).length > 0 &&
                      errors?.additionalDocs?.length > 0 &&
                      errors?.additionalDocs[index]?.type &&
                      "error"
                    }
                    validMessage={
                      Object.entries(errors).length > 0 &&
                      errors?.additionalDocs?.length > 0 &&
                      errors?.additionalDocs[index]?.type &&
                      errors?.additionalDocs[index]?.type?.message
                    }
                  />

                </Col>
                <Col span={12} style={{ display: "flex" }}>
                  {(isOtherDoc != null && isOtherDoc == index) && (
                    <Form.Item>
                      <InputField
                        isRequired={true}
                        classes="mb-0 sm-input"
                        fieldname={`additionalDocs[${index}].documentLabel`}
                        iProps={{ placeholder: "Document Name" }}
                        control={control}
                        rules={{ required: "Please enter document name" }}
                        validate={
                          Object.entries(errors).length > 0 &&
                          errors?.additionalDocs?.length > 0 &&
                          errors?.additionalDocs[index]?.documentLabel &&
                          "error"
                        }
                        validMessage={
                          Object.entries(errors).length > 0 &&
                          errors?.additionalDocs?.length > 0 &&
                          errors?.additionalDocs[index]?.documentLabel &&
                          errors?.additionalDocs[index]?.documentLabel?.message
                        }
                      />
                    </Form.Item>
                  )}
                  <Button
                    type="link"
                    icon={<DeleteIcon />}
                    onClick={() => {
                      remove(index);
                    }}

                  />
                </Col>
              </Col>
            </Row>
          </Card>
        );
      })}

      {isAddMore && !disableAll && (
        <Row gutter={[24, 24]} justify="end" style={showRequireDocs()?.length === 0 && {display:'none'} }>
          <Col span={24}>
            <Button
              type="dashed"
              // className="p-0"
              htmlType="button"
              icon={<PlusOutlined />}
              block={true}
              onClick={() => {
                append(initDocBar);
              }}
            >
              <Text
                className="ag-primary fontWeight600"
                style={{ fontSize: "14px" }}
              >
                Add More Documents
              </Text>
            </Button>
          </Col>
        </Row>
      )}
      <Popup {...popupContent} />
    </>
  );
};
