/* eslint-disable react/display-name */
import React, { useRef, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Row,
  Col,
  Typography,
  Form,
  Popover,
  Button,
  Progress,
  message,
} from "antd";
import { useDispatch } from "react-redux";
import LoginTemplate from "../Login/LoginTemplate";
import axios from "axios";
import { authService } from "../../../configs/constants";
import { useForm } from "react-hook-form";
import { InputField, InputPassword } from "../../atoms/FormElement";
import { setCookie } from "../../../utils/helper";
import { ReCaptcha, loadReCaptcha } from "react-recaptcha-v3";
import { SITEKEY } from "../../../configs/constants";
import Permissions from "../../../utils/permissions";
import { MODULES } from "../../../configs/modules";
import { READ } from "../../../configs/operations";
import { getUserScreenAccess } from "../Login/ducks/actions";
const { Text } = Typography;

const CreatePassword = (props) => {
  const [loading, setLoading] = useState(false);
  const [tokenCapcha, setReCapcha] = useState();
  const navigate = useNavigate();
  const location = useLocation();
  const refReCaptcha = useRef(null);
  const dispatcher = useDispatch();

  const { pathname, search } = location.state || {pathname:null,search:null};
  const {
    control,
    handleSubmit,
    formState:{errors},
    watch,
    formState,
    getValues,
  } = useForm({ mode: "onChange" });
  const { isValid } = formState;
  let checkPassword = watch("password");

  const content = (
    <div>
    
      <ul className="ag-unordered-list">
        <li>One Upper Case letter</li>
        <li>One Lower Case letter</li>
        <li>One Number</li>
        <li>One Special Character</li>
      </ul>
    </div>
  );





  const onFinish = async (values) => {
    const { password, confirm } = values;
    const headers = {
     appName: "institutionalLandlordPortal"
    };





    const postJson = {
      userid: location["state"]?.userid,
      password: password,
      confirmpassword: confirm,  
      usertype: location["state"]?.usertype,
      
    };


    
    const url = `${authService}/user/generatepassword`;
    try {
      const response = await axios.post(url, postJson, { headers: headers });

        //  check the permissions of screen for every login user
        if(response?.data?.result?.accessibleScreens && response?.data?.result?.accessibleScreens?.length > 0){
          dispatcher(getUserScreenAccess(response?.data?.result?.accessibleScreens));
        }else{
          dispatcher(getUserScreenAccess([]));
        }
      
      await window.localStorage.setItem(
        "login-response",
        JSON.stringify(response?.data?.result),
      );

      setLoading(false);
      setCookie(
        "landlordUser",
        true,
        new Date(response?.data?.result?.exp).getDate(),
      );

      if (Permissions.isAllowed(MODULES.PROPERTIES, [READ])) {
        if (pathname && search)
          setTimeout(() => { navigate(pathname + search); }, 1400);
        else
          setTimeout(() => { navigate("/dashboard"); }, 1400);
      }
      else if(Permissions.isAllowed(MODULES.CONFIGURATIONS, [READ]))
      setTimeout(() => {navigate("/categories");}, 1400);
      else 
      setTimeout(() => {navigate("/properties");}, 1400);


    } catch (e) {
      // refReCaptcha.current.execute();
      setLoading(false);
      const { response } = e;
      message.error(response?.data?.result?.error);
    }
  };

  return (
    <LoginTemplate load={loading}>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Text className="defaultFont fontWeight600 green-color">
            Create a password
          </Text>
        </Col>
        <Col span={24}>
          <Form name="normal_login" onFinish={handleSubmit(onFinish)}>
            <Popover
              placement="rightTop"
              title="Password must contain: 8 characters"
              content={content}
              trigger="click"
              visible={errors.password ? true : false}
            >
              <InputPassword
                isRequired={true}
                fieldname="password"
                control={control}
                initValue=""
                iProps={{ placeholder: "Create Password", size: "large" }}
                rules={{
                  pattern: {
                    value: /^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^!-_&*+=]).*$/,
                    // message: "Passwords must contain at least 8 characters, 1 capital letter, 1 number and a special character",
                  },
                  required: "Please enter password",
                }}
                validate={!isValid && errors.password && "error"}
                validMessage={!isValid && errors.password && errors.password.message}
              />
            </Popover>
            <InputPassword
              isRequired={true}
              fieldname="confirm"
              control={control}
              initValue=""
              iProps={{ placeholder: "Confirm Password", size: "large" }}
              rules={{
                required: "Please confirm your password!",
                validate: (value) =>
                  value === checkPassword || "The passwords do not match",
              }}
              validate={!isValid && errors.confirm && "error"}
              validMessage={!isValid && errors.confirm && errors.confirm.message}
            />
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                size="large"
                disabled={!isValid}  //dynamic
              >
                Sign Up
              </Button>
            </Form.Item>
            {/* <ReCaptcha
              ref={refReCaptcha}
              action="main"
              sitekey={SITEKEY}
              verifyCallback={verifyCallback}
            /> */}
          </Form>
        </Col>
      </Row>
    </LoginTemplate>
  );
};

export default CreatePassword;
